import React from 'react'
import { Banner, Layout } from '@shopify/polaris'
import { useStore } from 'utils/store'
import { showDisabledSessionBanner } from './utils'

const DisabledSessionRecordingBanner = () => {
  const {
    appStore: {
      currentUser: { site },
    },
  } = useStore()

  let title = 'Recording of traffic is deactivated'

  if (!site.adminRecordingEnabled && site.onboardingStatus === 'not-finished')
    title = 'Recording of casual and/or admin traffic is deactivated'

  return (
    showDisabledSessionBanner(site) && (
      <Layout.Section>
        <Banner
          title={title}
          status="critical"
          action={{
            content: 'Replays settings',
            url: '/settings/replays-settings',
          }}
        >
          <p>Activate recording of traffic in options by clicking the button below.</p>
        </Banner>
      </Layout.Section>
    )
  )
}

export default DisabledSessionRecordingBanner
