import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'
import StyledButton from 'components/StyledButton'
import { sendEmailIcon, closeIcon } from 'utils/global-icon-names'
import { Spacer } from './styles'

const CloseButton = observer(() => {
  const {
    detailsReplayStore: { liveSessionRunning, updateMessageModalActive, destroySession },
  } = useStore()

  if (!liveSessionRunning) return null

  return (
    <>
      <StyledButton
        onClick={() => {
          updateMessageModalActive(true)
        }}
        iconName={sendEmailIcon}
        actionName="Send message"
      />
      <Spacer />
      <StyledButton
        onClick={() => {
          destroySession()
        }}
        destructive
        iconName={closeIcon}
        actionName="Close"
      />
    </>
  )
})

export default CloseButton
