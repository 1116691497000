import React from 'react'
import { Toast } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'

const Notifications = observer(() => {
  const {
    notificationsStore: { hide, toasts },
  } = useStore()

  return (
    <>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          content={toast.message}
          error={toast.error}
          onDismiss={() => hide(toast.id)}
        />
      ))}
    </>
  )
})

export default Notifications
