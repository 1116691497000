import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'
import { convertHighlightFiltersData } from './utils'

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  updateTrafficRecording = (state) => {
    agent.Site.update({ recording_enabled: state }).then(
      this.updateRecordingSettingsSuccess,
      this.updateRecordingSettingsError,
    )
  }

  updateAdminTrafficRecording = (state) => {
    agent.Site.update({ admin_recording_enabled: state }).then(
      this.updateRecordingSettingsSuccess,
      this.updateRecordingSettingsError,
    )
  }

  updateHighlightFilters = (data) => {
    agent.Site.updateHighlightFilters(convertHighlightFiltersData(data)).then(
      this.updateRecordingSettingsSuccess,
      this.updateRecordingSettingsError,
    )
  }

  updateTrackErrors = (state) => {
    agent.Site.update({ track_errors: state }).then(
      this.updateRecordingSettingsSuccess,
      this.updateRecordingSettingsError,
    )
  }

  updateTagOrders = (state) => {
    agent.Site.update({ tag_orders: state }).then(
      this.updateRecordingSettingsSuccess,
      this.updateRecordingSettingsError,
    )
  }

  updateRecordingSettingsSuccess = () => {
    this.reloadCurrentUser()
    this.showNotification('Settings updated.')
  }

  updateRecordingSettingsError = (error) => {
    this.handleError(error)
  }
}

export default Store
