import styled from 'styled-components'
import { primaryBlack } from 'utils/colors'

export const Title = styled.h1`
  font-size: 4rem;
  line-height: 1;
  text-align: center;
  color: ${primaryBlack};
  padding: 4rem;
`
