import history from 'utils/history'

export const setupAnalytics = () => {
  if (!process.env.GOOGLE_TAG_ID) {
    return
  }

  history.listen((location) => {
    window.gtag('config', process.env.GOOGLE_TAG_ID, {
      page_location: window.location.href,
      page_path: location.pathname,
    })
  })
}

export const googleAnalyticsIdentify = (user) => {
  if (!process.env.GOOGLE_TAG_ID) {
    return
  }

  window.gtag('set', { user_id: user.id })
}

export const track = (event, properties = {}) => {
  if (!process.env.GOOGLE_TAG_ID) {
    return
  }

  window.gtag('event', event, properties)
}
