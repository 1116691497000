import { Card } from '@shopify/polaris'
import styled from 'styled-components'
import { shopifyBorderColor } from 'utils/colors'

export const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: [first] 1fr [two] 1fr [third] 1fr [fourth] 1fr [fifth] 1fr [sixth] 1fr [seventh];
  grid-auto-rows: 1fr;
  gap: 1.6rem;
  justify-items: stretch;
  align-items: stretch;
`

export const LineChartWrapper = styled.div`
  height: 50rem;
`

export const ChartWrapperA = styled.div`
  grid-column-start: 1;
  grid-column-end: span fifth;
  grid-row-start: 1;
  grid-row-end: span 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
`

export const StyledCard = styled(Card)`
  &&& {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
`

export const ChartWrapperB = styled.div`
  grid-column-start: fifth;
  grid-column-end: span seventh;
`

export const ChartWrapperC = styled.div`
  grid-column-start: fifth;
  grid-column-end: span seventh;
  grid-row-start: 2;
  grid-row-end: span 1;
`

export const ChartWrapper = styled.div`
  border: thin solid ${shopifyBorderColor};
  border-radius: 0.4rem;
`
