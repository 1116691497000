import React from 'react'
import { Modal, TextField } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'

const InputSegmentModal = observer(
  ({
    titleName,
    modalActive,
    setModalActive,
    textFieldValue,
    onChangeTextFieldValue,
    onSaveAction,
    textFieldHelpText = '',
  }) => {
    const {
      replaysStore: { errorsSegmentForm, setErrorsSegmentFormEmpty },
    } = useStore()

    return (
      <Modal
        open={modalActive}
        onClose={() => setModalActive(false)}
        title={titleName}
        primaryAction={{
          content: 'Save',
          onAction: () => onSaveAction(),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setErrorsSegmentFormEmpty()
              setModalActive(false)
            },
          },
        ]}
      >
        <Modal.Section>
          <TextField
            label="Name"
            error={errorsSegmentForm.name}
            value={textFieldValue}
            onChange={onChangeTextFieldValue}
            helpText={textFieldHelpText}
          />
        </Modal.Section>
      </Modal>
    )
  },
)

export default InputSegmentModal
