export const getActionName = (currentUser, plan) => {
  if (currentUser.site.planName) {
    if (currentUser.site.planName === plan.name) return 'Selected'
    if (currentUser.site.planPrice > plan.price) return 'Downgrade'
    if (currentUser.site.planPrice < plan.price) return 'Upgrade'
  }
  if (currentUser.site.trialDaysRemaining > 0 && currentUser.site.planPrice < plan.price)
    return 'Start free trial'
  return 'Start plan'
}
