import React from 'react'
import { Button, Page } from '@shopify/polaris'
import { Layout, Text, SvgImage } from './styles'

const NotFound = ({ notFoundSVG, text, url, buttonText }) => (
  <Page>
    <Layout>
      <SvgImage src={notFoundSVG} alt="not found" />
      <Text>{text}</Text>
      <Button url={url}>{buttonText}</Button>
    </Layout>
  </Page>
)

export default NotFound
