import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card, FormLayout, Layout, Select, Stack, TextStyle } from '@shopify/polaris'
import { Formik, Form } from 'formik'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-css'
import 'prismjs/themes/prism.css'
import { useStore } from 'utils/store'
import StyledButton from 'components/StyledButton'
import { saveIcon, toastIcon } from 'utils/global-icon-names'
import {
  activatedDeactivatedOptions,
  removeCustomToastStyle,
  setCustomToastStyle,
} from '../ReplaysSettingsPage/config'
import { showToast } from './utils'

const CustomCSSToastConfig = observer(() => {
  const {
    appStore: {
      currentUser: {
        site: { conversationType },
      },
    },
    chatSettingsPageStore: {
      updateToastConfig,
      toastConfig: {
        theme,
        position,
        titleColor,
        titleSize,
        messageColor,
        messageSize,
        zIndex,
        timeout,
        transitionIn,
        transitionOut,
        transitionInMobile,
        transitionOutMobile,
        customStyle,
        customStyleActive,
        titleContent,
      },
      toastConfigErorrs,
    },
  } = useStore()

  if (conversationType !== 'toast') return null

  if (customStyleActive) setCustomToastStyle(customStyle)

  return (
    <Layout.AnnotatedSection
      id="customCssToastConfig"
      title="Advanced toast settings"
      description="Personalize toast on your shop site using CSS."
    >
      <Card sectioned>
        <Formik
          enableReinitialize
          initialValues={{
            customStyle: customStyle || '',
            customStyleActive: customStyleActive || false,
          }}
          onSubmit={(v) => updateToastConfig(v)}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <FormLayout>
                <FormLayout.Group>
                  <Select
                    label="Custom style active"
                    onChange={() => {
                      if (!values.customStyleActive) setCustomToastStyle(values.customStyle)
                      else removeCustomToastStyle()
                      setFieldValue('customStyleActive', !values.customStyleActive)
                    }}
                    helpText="Active or disable custom styles for the toast."
                    options={activatedDeactivatedOptions}
                    value={values.customStyleActive}
                    error={toastConfigErorrs.customStyleActive}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Stack vertical spacing="extraTight">
                    <TextStyle>CSS code editor</TextStyle>
                    <Editor
                      disabled={!values.customStyleActive}
                      className="css_code_editor"
                      aria-label="Label"
                      value={values.customStyle}
                      onValueChange={(v) => {
                        setFieldValue('customStyle', v)
                        setCustomToastStyle(v)
                      }}
                      highlight={(v) => highlight(v, languages.css)}
                      padding={10}
                      style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                      }}
                    />
                    <TextStyle variation="subdued">
                      Use CSS to style the toast by yourself. Remember to use !important keyword to
                      overwrite existing properties.
                    </TextStyle>
                  </Stack>
                </FormLayout.Group>
                <FormLayout.Group>
                  <Stack spacing="baseTight">
                    <StyledButton
                      primary
                      submit
                      loading={isSubmitting}
                      actionName="Save"
                      iconName={saveIcon}
                    />
                    <StyledButton
                      actionName="Preview"
                      iconName={toastIcon}
                      onClick={() =>
                        showToast({
                          title: titleContent,
                          titleColor,
                          titleSize,
                          messageColor,
                          messageSize,
                          position,
                          theme,
                          zindex: zIndex,
                          timeout,
                          transitionIn,
                          transitionOut,
                          transitionInMobile,
                          transitionOutMobile,
                        })
                      }
                    />
                  </Stack>
                </FormLayout.Group>
              </FormLayout>
            </Form>
          )}
        </Formik>
      </Card>
    </Layout.AnnotatedSection>
  )
})

export default CustomCSSToastConfig
