import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { Stack, TextStyle } from '@shopify/polaris'

const CreatableOptionsInput = ({ value, onChange, placeholder, helpText }) => {
  const [inputValue, setInputValue] = useState('')

  const createOption = (label) => ({
    label,
    value: label,
  })

  value = value.map((v) => createOption(v))

  const handleChange = () => {
    onChange([...value, createOption(inputValue)])
    setInputValue('')
  }

  const handleKeyDown = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        handleChange()
        event.preventDefault()
        break
      default:
        break
    }
  }

  return (
    <Stack vertical spacing="extraTight">
      <TextStyle variation="default">{placeholder}</TextStyle>
      <CreatableSelect
        components={{ DropdownIndicator: null }}
        placeholder="Press enter to add an option"
        menuIsOpen={false}
        isMulti
        inputValue={inputValue}
        onInputChange={(v) => setInputValue(v)}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        isClearable
      />
      <TextStyle variation="subdued">{helpText}</TextStyle>
    </Stack>
  )
}

export default CreatableOptionsInput
