import React from 'react'
import { Layout, Page } from '@shopify/polaris'
import buildRoute from 'utils/build-route'
import ReplaysSettingsStore from './store'
import TrafficConfig from './TrafficConfig'
import HighlightsConfig from './HighlightsConfig'

const ReplaysSettingsPage = () => (
  <Page title="Replays settings" breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
    <Layout>
      <TrafficConfig />
      <HighlightsConfig />
    </Layout>
  </Page>
)

export default buildRoute({
  stores: () => ({
    replaysSettingsStore: new ReplaysSettingsStore(),
  }),
})(ReplaysSettingsPage)
