import styled from 'styled-components'

export const CountersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 0.4rem;
  gap: 1.6rem;

  @media screen and (max-width: 905px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 490px) {
    grid-template-columns: 1fr;
  }
`

export const IconWrapper = styled.div`
  font-size: 2.5rem;
`

export const Text = styled.span`
  font-size: 3.2rem;
  line-height: 100%;
`

export const TopInterior = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
`

export const BottomInterior = styled.div`
  display: flex;
  font-size: 1.7rem;
  gap: 1rem;
`
