import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 0.4rem;
  display: flex;
  justify-content: space-between;
`

export const TextButton = styled.div`
  display: inline-block;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const Label = styled.span``

export const Image = styled.img`
  max-height: 200px;
  max-width: 100%;
`
