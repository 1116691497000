import React from 'react'
import { ActionList } from '@shopify/polaris'
import { SearchResultsWrapper } from './styles'

const RESOURCE_NAMES = {
  users: 'Users',
}

const RESOURCE_MAPPERS = {
  users: (r) => ({ content: r.name, url: `/settings/users/${r.id}/details` }),
}

const SearchResults = ({ searchNoResults, results }) => {
  if (searchNoResults) {
    return <SearchResultsWrapper>No results were found</SearchResultsWrapper>
  }

  return (
    <ActionList
      sections={Object.keys(results).map((key) => ({
        title: RESOURCE_NAMES[key],
        items: results[key].map((r) => RESOURCE_MAPPERS[key](r)),
      }))}
    />
  )
}

export default SearchResults
