import { makeAutoObservable } from 'mobx'
import { initializeResourceStore } from 'utils/resource-store'

class ItemStore {
  resourceName = 'User'
  agentName = 'Users'
  frontendUrl = '/settings/users'

  constructor() {
    initializeResourceStore(this)
    makeAutoObservable(this)
  }
}

export default ItemStore
