import React from 'react'
import { useLocation } from 'react-router-dom'
import { ContextualSaveBar } from '@shopify/polaris'
import { isOnboardingFinished } from 'utils/global-functions'
import { useStore } from 'utils/store'
import history from 'utils/history'
import { showBackButton, showFinishOnboarding } from './utils'
import { showTopbar } from '../utils'

const OnboardingBar = () => {
  const {
    appStore: { currentUser, setOnboardingFinished, loading },
  } = useStore()

  const location = useLocation()

  if (!showTopbar(location)) return null
  if (isOnboardingFinished(currentUser.site.onboardingStatus)) return null

  return (
    <ContextualSaveBar
      fullWidth
      message="Onboarding"
      discardAction={showBackButton(history.location.pathname)}
      saveAction={showFinishOnboarding(history.location.pathname, loading, () =>
        setOnboardingFinished(),
      )}
    />
  )
}

export default OnboardingBar
