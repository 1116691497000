import React from 'react'
import Icon from 'components/Icon'
import { getOSIcon } from 'react-os-icons'
import { systemIcon } from 'utils/global-icon-names'
import { transformSystemName } from './utils'
import { IconWrapper } from './styles'

const SystemIcon = ({ name, width = '2rem', height = '2rem' }) => {
  const icon = getOSIcon({
    os: transformSystemName(name) || 'unknow',
    className: 'SystemIcon',
    style: { width, height },
  })

  if (!icon || icon.type === undefined) {
    return (
      <IconWrapper size={height}>
        <Icon name={systemIcon} />
      </IconWrapper>
    )
  }

  return icon
}

export default SystemIcon
