import { createConsumer } from '@rails/actioncable'

const websocket = createConsumer(process.env.WEBSOCKET_URL)

export const subscribe = (channel, params, onReceived) => {
  websocket.subscriptions.create(
    { channel, ...params },
    {
      received: (data) => {
        onReceived(data)
      },
    },
  )
}

export const disconnect = () => websocket.disconnect()

export default websocket

/*
  useEffect(() => {
    subscribe('UserChannel', { user_id: '1' }, (user) => {
      getCurrentUserSuccess(user)
    })

    return () => {
      disconnect()
    }
  }, [])
*/
