import React from 'react'
import { Header, Layout, Body, Content, Button } from './styles'

const ErrorPage = () => (
  <Layout>
    <Content>
      <Header>Something is wrong here...</Header>
      <Body>
        The application has crashed, we are really sorry about it. Our development team got the
        notification about the issue. We will take care of that, you could try to reload the
        application. Please contact the support team with any questions.
      </Body>
      <Button onClick={() => window.location.replace(process.env.ROUTING_BASENAME)}>Reload</Button>
    </Content>
  </Layout>
)

export default ErrorPage
