import React from 'react'
import { Layout, SettingToggle, TextStyle } from '@shopify/polaris'
import { useStore } from 'utils/store'

const TrafficConfig = () => {
  const {
    appStore: {
      currentUser: {
        site: { recordingEnabled, adminRecordingEnabled, trackErrors, tagOrders },
      },
    },
    replaysSettingsStore: {
      updateAdminTrafficRecording,
      updateTrafficRecording,
      updateTrackErrors,
      updateTagOrders,
    },
  } = useStore()

  return (
    <>
      <Layout.AnnotatedSection
        id="traffic"
        title="Recording"
        description="Deactivate or activate recording traffic on the store."
      >
        <SettingToggle
          action={{
            content: recordingEnabled ? 'Deactivate' : 'Activate',
            onAction: () => updateTrafficRecording(!recordingEnabled),
          }}
          enabled={recordingEnabled}
        >
          Recording of traffic is{' '}
          <TextStyle variation="strong">{recordingEnabled ? 'activated' : 'deactivated'}</TextStyle>
          .
        </SettingToggle>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        id="ownTraffic"
        title="Admin traffic"
        description="Deactivate or activate recording admin traffic on the store."
      >
        <SettingToggle
          action={{
            content: adminRecordingEnabled ? 'Deactivate' : 'Activate',
            onAction: () => updateAdminTrafficRecording(!adminRecordingEnabled),
          }}
          enabled={adminRecordingEnabled}
        >
          Recording of admin traffic is{' '}
          <TextStyle variation="strong">
            {adminRecordingEnabled ? 'activated' : 'deactivated'}
          </TextStyle>
          .
        </SettingToggle>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        id="orders"
        title="Tag orders"
        description="Deactivate or activate tagging orders with replay id."
      >
        <SettingToggle
          action={{
            content: tagOrders ? 'Deactivate' : 'Activate',
            onAction: () => updateTagOrders(!tagOrders),
          }}
          enabled={tagOrders}
        >
          Tagging orders is{' '}
          <TextStyle variation="strong">{tagOrders ? 'activated' : 'deactivated'}</TextStyle>.
        </SettingToggle>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        id="errors"
        title="Track errors"
        description="Deactivate or activate tracking javascript errros."
      >
        <SettingToggle
          action={{
            content: trackErrors ? 'Deactivate' : 'Activate',
            onAction: () => updateTrackErrors(!trackErrors),
          }}
          enabled={trackErrors}
        >
          Tracking javascript errors is{' '}
          <TextStyle variation="strong">{trackErrors ? 'activated' : 'deactivated'}</TextStyle>.
        </SettingToggle>
      </Layout.AnnotatedSection>
    </>
  )
}

export default TrafficConfig
