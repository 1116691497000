import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card, Page } from '@shopify/polaris'
import {
  billIcon,
  chatIcon,
  replaysSettings,
  sendEmailIcon,
  userSettingsIcon,
  usersIcon,
} from 'utils/global-icon-names'
import { useStore } from 'utils/store'
import { Layout } from './styles'
import Option from './Option'

const SettingsPage = observer(() => {
  const {
    appStore: { currentUser },
  } = useStore()

  return (
    <Page title="Settings">
      <Card>
        <Layout>
          <Option
            title="Replays"
            description="Manage replays settings"
            url="/settings/replays-settings"
            icon={replaysSettings}
          />
          <Option
            title="Chat"
            description="Customize the way of conversation"
            url="/settings/chat-settings"
            icon={chatIcon}
          />
          <Option
            title="Notifications"
            description="Setup notification about crucial sessions"
            url="/settings/notifications-settings"
            icon={sendEmailIcon}
          />
          <Option
            title="Billing"
            description="Change your billing plan"
            url="/settings/billing"
            icon={billIcon}
          />
          {currentUser.role === 'owner' && (
            <Option
              title="Users"
              description="Manage your users"
              url="/settings/users"
              icon={usersIcon}
            />
          )}
          <Option
            title="Account"
            description="Update your account information"
            url="/settings/account"
            icon={userSettingsIcon}
          />
        </Layout>
      </Card>
    </Page>
  )
})

export default SettingsPage
