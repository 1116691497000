import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from '@shopify/polaris'
import { primaryGreen } from 'utils/colors'
import Icon from 'components/Icon'
import { plans } from 'utils/plans-config.json'
import { checkIcon } from 'utils/global-icon-names'
import { useStore } from 'utils/store'
import {
  Layout,
  Card,
  CardTitle,
  CardList,
  CardListItem,
  Price,
  PricePeriod,
  PriceValue,
  IconWrapper,
  Divider,
  Segment,
  ListIconWrapper,
  CardRibbon,
  Feature,
} from './styles'
import { getActionName } from './utils'

const Plans = observer(() => {
  const {
    plansPageStore: { loading, selectedPlanName, startPlan },
    appStore: { currentUser },
  } = useStore()

  return (
    <Layout numberOfItems={plans.length}>
      {plans.map((plan) => (
        <Card key={plan.name} backgroundColor={plan.cardColor} numberOfItems={plans.length}>
          {plan.name === 'Pro' && <CardRibbon>TOP PICK</CardRibbon>}
          <Segment>
            <IconWrapper>
              <Icon name={plan.iconName} />
            </IconWrapper>
            <CardTitle>{plan.name}</CardTitle>
            <CardList>
              {plan.features.map((feature) => (
                <CardListItem key={feature}>
                  <ListIconWrapper color={primaryGreen}>
                    <Icon name={checkIcon} />
                  </ListIconWrapper>
                  <Feature>{feature}</Feature>
                </CardListItem>
              ))}
            </CardList>
          </Segment>
          <Segment>
            <Divider />
            <Price>
              <PriceValue>
                ${(plan.price - plan.price * (currentUser.site.discountCodeValue / 100)).toFixed(2)}
              </PriceValue>
              <PricePeriod>/mo</PricePeriod>
            </Price>
            <Button
              loading={loading && plan.name === selectedPlanName}
              disabled={
                plan.name === currentUser.site.planName ||
                (loading && plan.name !== selectedPlanName)
              }
              onClick={() => startPlan(plan)}
            >
              {getActionName(currentUser, plan)}
            </Button>
          </Segment>
        </Card>
      ))}
    </Layout>
  )
})

export default Plans
