import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useStore } from 'utils/store'

const AuthorizedRoute = (props) => {
  const location = useLocation()
  const {
    appStore: { currentUser },
  } = useStore()

  if (location.pathname !== '/admin/overlimit' && currentUser.overlimit)
    return <Redirect to={{ pathname: '/admin/overlimit', state: { from: location } }} />

  if (location.pathname !== '/plans' && currentUser.site.planRequired)
    return <Redirect to={{ pathname: '/plans', state: { from: location } }} />

  if (
    location.pathname !== '/install' &&
    currentUser.site.installationStatus === 'ready' &&
    !currentUser.site.planRequired
  )
    return <Redirect to={{ pathname: '/install', state: { from: location } }} />

  return <Route {...props} />
}

export default AuthorizedRoute
