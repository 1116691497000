import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'

class Store {
  errors = {}

  constructor() {
    makeAutoObservable(this)
  }

  updateSite = (site) => {
    const { logo, ...params } = site

    if (!(logo && logo.url)) {
      params.logo = logo
    }

    return agent.Site.update(params).then(this.updateSucces, this.updateError)
  }

  updateSucces = () => {
    this.errors = {}
    this.reloadCurrentUser()
    this.showNotification('Store updated.')
  }

  updateError = (error) => {
    this.handleError(error)
  }
}

export default Store
