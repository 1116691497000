import React from 'react'
import { ChoiceList, RangeSlider, TextField } from '@shopify/polaris'
import DebouncedField from 'components/DebouncedField'

export const filterComponent = (filter, updateFilter) => {
  const transformedFilterTypeName = filter.type
    .replace('jsonb_', '')
    .replace('_array', '')
    .replace('_nested', '')

  if (transformedFilterTypeName === 'range') {
    return (
      <DebouncedField onChange={(v) => updateFilter(filter, v)} value={filter.value}>
        {(val, onChange) => (
          <RangeSlider
            output
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            value={val || [0, 100]}
          />
        )}
      </DebouncedField>
    )
  }
  if (
    transformedFilterTypeName === 'include' ||
    transformedFilterTypeName === 'not_exists' ||
    transformedFilterTypeName === 'exists'
  ) {
    return (
      <ChoiceList
        allowMultiple
        choices={filter.choices}
        onChange={(v) => updateFilter(filter, v)}
        selected={filter.value || []}
      />
    )
  }
  return (
    <DebouncedField onChange={(v) => updateFilter(filter, v)} value={filter.value}>
      {(val, onChange) => (
        <TextField label={transformedFilterTypeName} onChange={onChange} value={val || ''} />
      )}
    </DebouncedField>
  )
}

export const filterValueLabel = (filter) => {
  if (filter.type === 'range') {
    return `${filter.value[0]} between ${filter.value[1]}`
  }

  return filter.value
}
