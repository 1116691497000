import React from 'react'
import Icon from 'components/Icon'
import { primaryYellow, shopifySubduedColor } from 'utils/colors'
import { fillStarIcon, lineStarIcon } from 'utils/global-icon-names'
import { useStore } from 'utils/store'
import { IconWrapper } from './styles'

const IsFavouriteRowItem = ({ id, isFavourite, overlimit }) => {
  const {
    replaysStore: { updateFavourite },
  } = useStore()

  return (
    <IconWrapper
      role="button"
      cursor="pointer"
      onClick={() => !overlimit && updateFavourite(id, isFavourite)}
      size="2.3rem"
      color={overlimit ? shopifySubduedColor : primaryYellow}
    >
      <Icon name={isFavourite ? fillStarIcon : lineStarIcon} />
    </IconWrapper>
  )
}

export default IsFavouriteRowItem
