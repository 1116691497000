import React from 'react'
import { Page } from '@shopify/polaris'
import PlansComponents from 'components/Plans'

const BillingPage = () => (
  <Page title="Billing" breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
    <PlansComponents />
  </Page>
)

export default BillingPage
