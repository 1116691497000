import styled from 'styled-components'
import { secondaryWhite, shopifyBorderColor } from 'utils/colors'

export const DetailsBox = styled.div`
  padding: ${(props) => (props.padding ? props.padding : '0')};
`

export const DetailsWrapper = styled.div`
  max-height: 55rem;
`

export const DetailsList = styled.div`
  display: grid;
  grid-template-columns: max-content;
  align-items: stretch;
  column-gap: 0.7rem;
  row-gap: 1.4rem;

  @media screen and (max-width: 1160px) {
    grid-template-columns: repeat(2, max-content);
    grid-template-rows: repeat(4, 1fr);
    row-gap: 2.5rem;
    justify-content: space-between;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: repeat(1, max-content);
    grid-template-rows: repeat(4, 1fr);
    row-gap: 2.5rem;
    justify-content: space-between;
  }
`

export const DetailsListItem = styled.div`
  display: flex;
  gap: 0.7rem;
  align-items: center;
`

export const ItemListWrapper = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 22rem;

  @media screen and (max-width: 1360px) {
    max-width: 19rem;
  }

  @media screen and (max-width: 1360px) {
    max-width: 16rem;
  }

  @media screen and (max-width: 1160px) {
    max-width: 30rem;
  }

  @media screen and (min-width: 1500px) {
    max-width: 25rem;
  }

  @media screen and (min-width: 1600px) {
    max-width: 27rem;
  }

  @media screen and (min-width: 1700px) {
    max-width: 30rem;
  }
`

export const TitleIconWrapper = styled.div`
  font-size: 2.3rem;
`

export const DetailsListHeading = styled.div`
  font-weight: 500;
`

export const CartContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 1rem;
`

export const ErrorsContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  gap: 1rem;

  @media screen and (max-width: 1190px) {
    grid-template-columns: 7fr 1fr 7fr;
  }
`

export const ErrorsWrapper = styled.div``

export const ListItem = styled.div`
  max-width: 11rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width: 1390px) {
    max-width: 9rem;
  }

  @media screen and (max-width: 1190px) {
    max-width: 7rem;
  }

  @media screen and (max-width: 1190px) {
    max-width: 47rem;
  }

  @media screen and (max-width: 1000px) {
    max-width: 42rem;
  }

  @media screen and (max-width: 960px) {
    max-width: 38rem;
  }

  @media screen and (max-width: 920px) {
    max-width: 34rem;
  }

  @media screen and (max-width: 840px) {
    max-width: 30rem;
  }

  @media screen and (max-width: 755px) {
    max-width: 26rem;
  }

  @media screen and (max-width: 655px) {
    max-width: 22rem;
  }

  @media screen and (max-width: 575px) {
    max-width: 18rem;
  }

  @media screen and (max-width: 455px) {
    max-width: 14rem;
  }

  @media screen and (min-width: 1550px) {
    max-width: 13rem;
  }

  @media screen and (min-width: 1700px) {
    max-width: 15rem;
  }

  @media screen and (min-width: 1900px) {
    max-width: 17rem;
  }

  @media screen and (min-width: 2100px) {
    max-width: 20rem;
  }

  @media screen and (min-width: 2400px) {
    max-width: 23rem;
  }

  @media screen and (min-width: 2560px) {
    max-width: 25rem;
  }
`

export const NotesListContainer = styled.div`
  max-height: 45.6rem;
  overflow-y: auto;

  @media screen and (max-width: 1390px) {
    max-height: 43.6rem;
  }

  @media screen and (max-width: 1340px) {
    max-height: 43rem;
  }

  @media screen and (max-width: 1300px) {
    max-height: 41rem;
  }

  @media screen and (max-width: 1260px) {
    max-height: 39rem;
  }

  @media screen and (max-width: 1160px) {
    max-height: 39rem;
  }

  @media screen and (min-width: 1480px) {
    max-height: 52rem;
  }

  @media screen and (min-width: 1600px) {
    max-height: 55rem;
  }

  @media screen and (min-width: 1700px) {
    max-height: 59rem;
  }

  @media screen and (min-width: 1800px) {
    max-height: 64rem;
  }

  @media screen and (min-width: 1900px) {
    max-height: 66rem;
  }

  @media screen and (min-width: 2000px) {
    max-height: 70rem;
  }

  @media screen and (min-width: 2100px) {
    max-height: 78rem;
  }

  @media screen and (min-width: 2200px) {
    max-height: 85rem;
  }

  @media screen and (min-width: 2300px) {
    max-height: 90rem;
  }

  @media screen and (min-width: 2400px) {
    max-height: 93rem;
  }

  @media screen and (min-width: 2500px) {
    max-height: 96rem;
  }

  @media screen and (min-width: 2560px) {
    max-height: 100rem;
  }
`

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justy-content: center;
  align-content: center;
  gap: 1rem;
  margin-top: 2rem;
  height: 15rem;
`

export const EmptyStateIconWrapper = styled.div`
  font-size: 8rem;
  color: ${secondaryWhite};
`

export const EmptyStateText = styled.div`
  font-size: 3rem;
  font-weight: 700;
  color: ${secondaryWhite};
`

export const DetailsContainer = styled.div`
  padding: 2rem;
  overflow-y: auto;
  max-height: 61rem;

  @media screen and (max-width: 1390px) {
    max-height: 58rem;
  }

  @media screen and (max-width: 1340px) {
    max-height: 57rem;
  }

  @media screen and (max-width: 1300px) {
    max-height: 55rem;
  }

  @media screen and (max-width: 1160px) {
    max-height: 30rem;
  }

  @media screen and (min-width: 1480px) {
    max-height: 70rem;
  }

  @media screen and (min-width: 1650px) {
    max-height: 75rem;
  }

  @media screen and (min-width: 1750px) {
    max-height: 80rem;
  }

  @media screen and (min-width: 1900px) {
    max-height: 85rem;
  }

  @media screen and (min-width: 1900px) {
    max-height: 90rem;
  }

  @media screen and (min-width: 2100px) {
    max-height: 96rem;
  }

  @media screen and (min-width: 2200px) {
    max-height: 103rem;
  }

  @media screen and (min-width: 2300px) {
    max-height: 108rem;
  }

  @media screen and (min-width: 2400px) {
    max-height: 112rem;
  }

  @media screen and (min-width: 2500px) {
    max-height: 115rem;
  }

  @media screen and (min-width: 2560px) {
    max-height: 118rem;
  }
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  padding-bottom: 0.8rem;
  border-bottom: thin solid ${shopifyBorderColor};
  margin-bottom: 1rem;
`

export const ActionHref = styled.div`
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ActionTime = styled.div`
  white-space: nowrap;
`

export const TagsWrapper = styled.div`
  overflow-y: auto;
  max-height: 22rem;

  @media screen and (max-width: 1390px) {
    max-height: 20rem;
  }

  @media screen and (max-width: 1340px) {
    max-height: 18rem;
  }

  @media screen and (max-width: 1300px) {
    max-height: 17rem;
  }

  @media screen and (max-width: 1160px) {
    max-height: 25rem;
  }

  @media screen and (min-width: 1480px) {
    max-height: 29rem;
  }

  @media screen and (min-width: 1550px) {
    max-height: 30rem;
  }

  @media screen and (min-width: 1600px) {
    max-height: 31rem;
  }

  @media screen and (min-width: 1650px) {
    max-height: 34rem;
  }

  @media screen and (min-width: 1700px) {
    max-height: 36rem;
  }

  @media screen and (min-width: 1750px) {
    max-height: 38rem;
  }

  @media screen and (min-width: 1800px) {
    max-height: 40rem;
  }

  @media screen and (min-width: 1850px) {
    max-height: 42rem;
  }

  @media screen and (min-width: 1900px) {
    max-height: 44rem;
  }

  @media screen and (min-width: 1950px) {
    max-height: 46rem;
  }

  @media screen and (min-width: 2000px) {
    max-height: 49rem;
  }

  @media screen and (min-width: 2050px) {
    max-height: 53rem;
  }

  @media screen and (min-width: 2100px) {
    max-height: 57rem;
  }

  @media screen and (min-width: 2150px) {
    max-height: 61rem;
  }

  @media screen and (min-width: 2200px) {
    max-height: 63rem;
  }

  @media screen and (min-width: 2250px) {
    max-height: 66rem;
  }

  @media screen and (min-width: 2300px) {
    max-height: 69rem;
  }

  @media screen and (min-width: 2350px) {
    max-height: 70rem;
  }

  @media screen and (min-width: 2400px) {
    max-height: 72rem;
  }

  @media screen and (min-width: 2450px) {
    max-height: 74rem;
  }

  @media screen and (min-width: 2500px) {
    max-height: 76rem;
  }

  @media screen and (min-width: 2550px) {
    max-height: 78rem;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

export const DeviceTypeWrapper = styled.div``

export const LocalizationWrapper = styled.div``
