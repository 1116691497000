import React from 'react'
import { Layout, SettingToggle, TextStyle } from '@shopify/polaris'
import { useStore } from 'utils/store'

const EmailNotificationsActivator = () => {
  const {
    appStore: {
      currentUser: {
        site: { emailNotificationsEnabled },
      },
    },
    notifcationSettingsPageStore: { updateEmailNotifications },
  } = useStore()

  return (
    <Layout.AnnotatedSection
      id="emailNotification"
      title="Email notifications"
      description="Active email notification about live sessions."
    >
      <SettingToggle
        action={{
          content: emailNotificationsEnabled ? 'Deactivate' : 'Activate',
          onAction: () => updateEmailNotifications(!emailNotificationsEnabled),
        }}
        enabled={emailNotificationsEnabled}
      >
        Email notifications are{' '}
        <TextStyle variation="strong">
          {emailNotificationsEnabled ? 'activated' : 'deactivated'}
        </TextStyle>
        .
      </SettingToggle>
    </Layout.AnnotatedSection>
  )
}

export default EmailNotificationsActivator
