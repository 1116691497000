import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'

class Store {
  errors = {}
  message = ''

  constructor() {
    makeAutoObservable(this)
  }

  sendEmail = (attributes) =>
    agent.Contact.create(attributes).then(this.sendEmailSuccess, this.sendEmailError)

  sendEmailSuccess = () => {
    this.message = ''
    this.errors = {}
    this.showNotification('Message sent.')
  }

  sendEmailError = (error) => {
    this.handleError(error)
  }
}

export default Store
