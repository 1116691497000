import styled from 'styled-components'

export const ListFiltersContainer = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: row;
  gap: 0.4rem;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`

export const Box = styled.div`
  display: flex;
  gap: 0.4rem;
  justify-content: flex-end;
`

export const SelectContainer = styled.div``

export const FiltersContainer = styled.div`
  flex: 1;
`
