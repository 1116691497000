import React from 'react'
import { Form, Formik } from 'formik'
import { FormLayout, TextField, Modal } from '@shopify/polaris'
import StyledButton from 'components/StyledButton'
import { sendEmailIcon } from 'utils/global-icon-names'
import { useStore } from 'utils/store'

const MessageModal = ({ activeModal, updateActiveModal, sendMessage }) => {
  const {
    appStore: {
      currentUser: {
        site: { conversationType },
      },
    },
  } = useStore()

  return (
    <Modal
      open={activeModal}
      onClose={() => updateActiveModal(false)}
      title="Send message to the customer"
    >
      <Modal.Section>
        <Formik
          initialValues={{
            content: '',
            title: '',
          }}
          enableReinitialize
          onSubmit={(v, actions) =>
            sendMessage(v).then(() => actions.resetForm({ values: { content: '', title: '' } }))
          }
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <FormLayout>
                <TextField
                  name="content"
                  label="Message"
                  multiline={4}
                  onChange={(v) => setFieldValue('content', v)}
                  value={values.content}
                />
                <StyledButton
                  primary
                  submit
                  loading={isSubmitting}
                  actionName="Send"
                  iconName={sendEmailIcon}
                />
              </FormLayout>
            </Form>
          )}
        </Formik>
      </Modal.Section>
    </Modal>
  )
}

export default MessageModal
