import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { Card, Layout, Page } from '@shopify/polaris'
import PageSpinner from 'components/PageSpinner'
import buildRoute from 'utils/build-route'
import { useStore } from 'utils/store'
import EditUserStore from './item-store'
import UserForm from './Form'

const EditUserPage = observer(() => {
  const {
    userStore: { fetch, item: user, errors, update, loading },
  } = useStore()

  const { userId } = useParams()

  useEffect(() => {
    fetch(userId)
  }, [])

  if (loading) return <PageSpinner />

  return (
    <Page title="Edit user" breadcrumbs={[{ content: 'Users', url: '/settings/users' }]}>
      <Layout>
        <Layout.AnnotatedSection
          title="User information"
          description="Update a collaborator account information."
        >
          <Card sectioned>
            <UserForm user={user} errors={errors} onSubmit={update} />
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    userStore: new EditUserStore(),
  }),
})(EditUserPage)
