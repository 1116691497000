/* eslint-disable no-param-reassign */

const extendStores = ({ stores, globalStores }) => {
  Object.values(stores).forEach((store) => {
    store.stores = globalStores

    store.showNotification = (...args) => {
      store.stores.notificationsStore.display(...args)
    }

    store.reloadCurrentUser = () => store.stores.appStore.getCurrentUser()

    store.getCurrentLoggedUser = () => store.stores.appStore.currentUser

    store.handleError = (error) => {
      let errorMessage =
        'We are sorry but there was an error while processing the request, please try again later and contact support if the issue remains.'
      
      if(!error.response) {
        console.error(error);
        store.stores.notificationsStore.display(errorMessage, {
          error: true,
        })
        throw(error);
        return;
      }
      
      store.errors = error.response.data

      if (error.response.status === 422) {
        errorMessage =
          (error.response.data && error.response.data.base) ||
          'Invalid data, please check the fields.'
      }

      store.stores.notificationsStore.display(errorMessage, {
        error: true,
      })
    }

    if (!store.currentUser) {
      Object.defineProperty(store, 'currentUser', {
        get: () => store.stores.appStore.currentUser,
      })
    }
  })
}

export default extendStores
