import React, { useState, useEffect } from 'react'
import { Frame } from '@shopify/polaris'
import { useLocation } from 'react-router-dom'
import Routes from 'routes'
import Notifications from 'components/Notifications'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import { showSidebar, showTopbar } from './utils'
import OnboardingBar from './Topbar/OnboardingBar'

const AppFrame = () => {
  const location = useLocation()
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false)

  useEffect(() => {
    if (mobileNavigationActive) {
      setMobileNavigationActive(false)
    }
  }, [location])

  return (
    <Frame
      topBar={
        showTopbar(location) && (
          <Topbar
            mobileNavigationActive={mobileNavigationActive}
            setMobileNavigationActive={setMobileNavigationActive}
          />
        )
      }
      navigation={showSidebar(location) && <Sidebar />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={() => setMobileNavigationActive(false)}
    >
      <OnboardingBar />
      <Routes />
      <Notifications />
    </Frame>
  )
}

export default AppFrame
