export const editIcon = 'FaEdit'
export const deleteIcon = 'FaTrashAlt'
export const sendEmailIcon = 'RiMailSendLine'
export const closeIcon = 'IoClose'
export const liveIcon = 'IoRadio'
export const saveIcon = 'RiSave3Line'
export const addUserIcon = 'RiUserAddLine'
export const notificationIcon = 'FaBell'
export const checkIcon = 'FaCheck'
export const copyIcon = 'FaRegCopy'
export const fillPlayIcon = 'IoPlay'
export const linePlayIcon = 'IoPlayOutline'
export const lineStarIcon = 'FaRegStar'
export const fillStarIcon = 'FaStar'
export const blankLinkIcon = 'FaShare'
export const fillWatchedIcon = 'RiEyeFill'
export const lineWatchedIcon = 'RiEyeLine'
export const cartIcon = 'RiShoppingCart2Line'
export const bugIcon = 'RiBugLine'
export const userTagIcon = 'RiUserStarLine'
export const calendarIcon = 'RiCalendarTodoLine'
export const desktopIcon = 'RiComputerLine'
export const stackIcon = 'RiStackLine'
export const settingsIcon = 'RiSettings5Line'
export const userSettingsIcon = 'RiUserSettingsLine'
export const billIcon = 'RiBillLine'
export const usersIcon = 'RiGroupLine'
export const integrationIcon = 'RiFunctionLine'
export const deviceIcon = 'RiDeviceLine'
export const browserIcon = 'RiGlobalLine'
export const systemIcon = 'RiTerminalWindowLine'
export const emoteHappyIcon = 'RiEmotionLine'
export const emoteSadIcon = 'RiEmotionUnhappyLine'
export const flagIcon = 'RiFlagLine'
export const coloredErrorIcon = 'FcHighPriority'
export const coloredAddToCartIcon = 'FcPaid'
export const coloredListIcon = 'FcList'
export const coloredPaidIcon = 'FcApproval'
export const tagIcon = 'RiBookmarkLine'
export const userIcon = 'RiUserLine'
export const toastIcon = 'RiMessage2Line'
export const chatIcon = 'RiQuestionAnswerLine'
export const recordingIcon = 'RiMovieLine'
export const analyticsIcon = 'RiBarChart2Line'
export const replaysSettings = 'RiListSettingsLine'
