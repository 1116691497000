import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card, Link, Tooltip } from '@shopify/polaris'
import { useStore } from 'utils/store'
import { CartContainer, DetailsListHeading, ListItem, DetailsContainer } from './styles'

const Cart = observer(({ replay: { attrs } }) => {
  const {
    appStore: { currentUser },
  } = useStore()

  return (
    <DetailsContainer>
      <CartContainer>
        <DetailsListHeading>Name</DetailsListHeading>
        <DetailsListHeading>Quantity</DetailsListHeading>
        <DetailsListHeading>Price</DetailsListHeading>
        {attrs.cart.items.map((item) => (
          <React.Fragment key={item.productId}>
            <Tooltip content={item.title}>
              <ListItem>
                <Link monochrome removeUnderline url={`${currentUser.siteBaseUrl}${item.url}`}>
                  {item.title}
                </Link>
              </ListItem>
            </Tooltip>
            <ListItem>{item.quantity}</ListItem>
            <ListItem>{`${item.finalLinePrice / 100} ${attrs.cart.currency}`}</ListItem>
          </React.Fragment>
        ))}
        <Card.Section />
      </CartContainer>
    </DetailsContainer>
  )
})

export default Cart
