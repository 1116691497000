import styled from 'styled-components'

export const Layout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;
`

export const Text = styled.span`
  font-size: 2.5rem;

  @media screen and (max-width: 600px) {
    font-size: 2rem;
  }
`

export const SvgImage = styled.img`
  height: 40rem;

  @media screen and (max-width: 600px) {
    height: 30rem;
  }

  @media screen and (max-width: 450px) {
    height: 25rem;
  }
`
