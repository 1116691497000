import React from 'react'
import { Card } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import { DetailsBox, NotesListContainer } from './styles'
import SendMessageForm from './SendMessageForm'
import ChatList from './ChatList'

const ChatLog = observer(({ replay, chatLogDisabled = true, sendMessage }) => (
  <DetailsBox overflow="initial">
    <SendMessageForm sendMessage={sendMessage} chatLogDisabled={chatLogDisabled} />
    <NotesListContainer>
      <ChatList replay={replay} />
    </NotesListContainer>
    <Card.Section />
  </DetailsBox>
))

export default ChatLog
