import { primaryColor, primaryGreen, primaryGrey } from 'utils/colors'

const NOTIFICATION_COLORS = {
  GeneralNotification: primaryColor,
  ChangelogNotification: primaryGrey,
  PromoNotification: primaryGreen,
}

export const tagColor = (tagType) => NOTIFICATION_COLORS[tagType] || primaryColor

export const tagName = (tagType) => tagType.replace('Notification', '')
