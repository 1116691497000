import React from 'react'
import { Tooltip } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import Icon from 'components/Icon'
import {
  blankLinkIcon,
  copyIcon,
  fillStarIcon,
  fillWatchedIcon,
  lineStarIcon,
  lineWatchedIcon,
} from 'utils/global-icon-names'
import { primaryColor, primaryColorLight } from 'utils/colors'
import { openInNewTab } from 'utils/global-functions'
import { CurrentUrlInput, IconButton, StyledStack, UrlActionButton, UrlText } from './styles'

const CurrentUrl = observer(
  ({
    replay,
    currentUrlFromReplayer,
    updateFavourite,
    updateWatched,
    copyToClipboard,
    actionsActive = true,
  }) => (
    <StyledStack>
      <CurrentUrlInput>
        <UrlText>{currentUrlFromReplayer}</UrlText>
        <StyledStack>
          <Tooltip content="Open in new tab">
            <UrlActionButton
              role="button"
              tabIndex={0}
              onClick={() => openInNewTab(currentUrlFromReplayer)}
              onKeyDown={() => openInNewTab(currentUrlFromReplayer)}
            >
              <Icon name={blankLinkIcon} />
            </UrlActionButton>
          </Tooltip>
          <Tooltip content="Copy to clipboard">
            <UrlActionButton
              role="button"
              tabIndex={0}
              onClick={() => copyToClipboard(currentUrlFromReplayer)}
              onKeyDown={() => copyToClipboard(currentUrlFromReplayer)}
            >
              <Icon name={copyIcon} />
            </UrlActionButton>
          </Tooltip>
        </StyledStack>
      </CurrentUrlInput>
      {actionsActive && (
        <>
          <Tooltip content={replay.isFavourite ? 'Remove from favourites' : 'Add to favourites'}>
            <IconButton onClick={() => updateFavourite()}>
              <Icon name={replay.isFavourite ? fillStarIcon : lineStarIcon} />
            </IconButton>
          </Tooltip>
          <Tooltip content={replay.watchedAt ? 'Mark as unwatched' : 'Mark as watched'}>
            <IconButton
              color={primaryColorLight}
              hoverColor={primaryColor}
              onClick={() => updateWatched(replay.id)}
            >
              <Icon name={replay.watchedAt ? lineWatchedIcon : fillWatchedIcon} />
            </IconButton>
          </Tooltip>
        </>
      )}
    </StyledStack>
  ),
)

export default CurrentUrl
