import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import lodash from 'lodash'
import { TopBar, Spinner } from '@shopify/polaris'
import { showSidebar } from '../utils'
import SearchField from './SearchField'
import UserMenu from './UserMenu'
import SearchResults from './SearchResults'
import { SearchResultsWrapper } from './styles'

const Topbar = ({ mobileNavigationActive, setMobileNavigationActive }) => {
  const [searchResults, setSearchResults] = useState({})
  const [searchActive, setSearchActive] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)

  const location = useLocation()

  const searchNoResults = lodash.flatten(Object.values(searchResults)).length === 0

  const fetchSearch = (search) => {
    setSearchLoading(true)
    setSearchActive(true)

    axios.get(encodeURI(`/api/search?query=${search}`)).then((response) => {
      setSearchLoading(false)
      setSearchResults(response.data)
    })
  }

  return (
    <TopBar
      showNavigationToggle={showSidebar(location)}
      userMenu={<UserMenu />}
      searchResultsVisible={searchActive}
      searchField={
        <SearchField
          onChange={fetchSearch}
          onFocus={() => !searchNoResults && setSearchActive(true)}
        />
      }
      searchResults={
        searchLoading ? (
          <SearchResultsWrapper>
            <Spinner size="small" />
          </SearchResultsWrapper>
        ) : (
          <SearchResults results={searchResults} searchNoResults={searchNoResults} />
        )
      }
      onSearchResultsDismiss={setSearchActive}
      onNavigationToggle={() => setMobileNavigationActive(!mobileNavigationActive)}
    />
  )
}

export default Topbar
