import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'
import { plans } from 'utils/plans-config.json'

export const FREE_PLAN = plans.find((p) => p.price === 0)
export const PAID_PLAN = plans.find((p) => p.price > 0)

class Store {
  loading = false
  changeModalPlanLoading = false
  freeTrialModalActive = false
  downgradeConfirmationModalActive = false
  selectedPlan = {}
  selectedPlanName = ''
  discountCodeInputActive = false
  discountCode = ''

  constructor() {
    makeAutoObservable(this)
  }

  startPlan = (plan) => {
    this.loading = true
    this.selectedPlan = plan
    this.selectedPlanName = plan.name

    if (PAID_PLAN && this.currentUser.site.trialDaysRemaining > 0 && plan.price === 0)
      this.showFreeTrialModal()
    else if (PAID_PLAN && this.currentUser.site.planPrice > plan.price)
      this.showDownagradeConfirmationModal()
    else this.createPlan(plan)
  }

  createPlan = (plan) => {
    this.changeModalPlanLoading = true
    this.selectedPlanName = plan.name

    agent.Plan.create({ planName: plan.name.toLowerCase() }).then((response) => {
      if (plan.price === 0) {
        this.reloadCurrentUser()
        this.hideFreeTrialModal()
        this.changeModalPlanLoading = false
        this.showNotification('Free plan activated.')
      } else {
        window.location = response.confirmation_url
      }
    })
  }

  showDownagradeConfirmationModal = () => {
    this.downgradeConfirmationModalActive = true
  }

  hideDownagradeConfirmationModal = () => {
    this.downgradeConfirmationModalActive = false
    this.loading = false
  }

  showFreeTrialModal = () => {
    this.freeTrialModalActive = true
  }

  hideFreeTrialModal = () => {
    this.freeTrialModalActive = false
    this.loading = false
  }

  showDiscountCodeInput = () => {
    this.discountCodeInputActive = true
  }

  applyDiscount = (code) => {
    agent.Plan.applyDiscount(code).then(this.applyDiscountSuccess, this.applyDiscountError)
  }

  applyDiscountSuccess = () => {
    this.reloadCurrentUser()
    this.showNotification('Discount code applied.')
    this.discountCodeInputActive = false
  }

  applyDiscountError = () => {
    this.showNotification('Invalid discount code.', { error: true })
  }
}

export default Store
