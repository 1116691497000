import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/

const PolarisLink = ({ children, url = '', external, ref, ...rest }) => {
  const restAttributes = rest

  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    restAttributes.target = '_blank'
    restAttributes.rel = 'noopener noreferrer'
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <ReactRouterLink to={url} {...restAttributes}>
      {children}
    </ReactRouterLink>
  )
}

export default PolarisLink
