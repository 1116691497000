import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import ErrorPage from 'routes/ErrorPage'
import '@shopify/polaris/dist/styles.css'
import { setupAnalytics } from 'utils/gtag'
import { setupMixpanel } from 'utils/mixpanel'
import { isErrorPage, setupErrorHandler } from 'utils/error-handler'

if (!isErrorPage()) {
  setupErrorHandler()
  setupAnalytics()
  setupMixpanel()

  ReactDOM.render(<App />, document.getElementById('root'))
} else {
  ReactDOM.render(<ErrorPage />, document.getElementById('root'))
}
