import React from 'react'
import { IndexTable, TextStyle, Tooltip } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import Icon from 'components/Icon'
import { blankLinkIcon, copyIcon } from 'utils/global-icon-names'
import { useStore } from 'utils/store'
import {
  convertSecondsToHHMMSS,
  openInNewTab,
  transformDateToDefaultFormat,
} from 'utils/global-functions'
import {
  LinksContainer,
  LinksItem,
  LinkItemStack,
  LinksTitle,
  LinksUrl,
  ListActionButton,
  ListActionButtonsContainer,
} from './styles'
import DisabledLink from './DisabledLink'
import ActiveLink from './ActiveLink'
import IsFavouriteRowItem from './IsFavouriteRowItem'

const RowMarkup = observer(({ items }) => {
  const {
    appStore: { currentUser },
    replaysStore: { copyToClipboard },
  } = useStore()

  return items.map(
    (
      {
        id,
        isFavourite,
        createdAt,
        watchedAt,
        sessionLength,
        landingPage,
        exitPage,
        actions,
        overlimit,
      },
      index,
    ) => (
      <IndexTable.Row id={id} key={id} position={index} status={overlimit && 'subdued'}>
        <IndexTable.Cell>
          {overlimit || currentUser.site.isLiveOnly ? (
            <DisabledLink watchedAt={watchedAt} />
          ) : (
            <ActiveLink id={id} watchedAt={watchedAt} />
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <IsFavouriteRowItem id={id} isFavourite={isFavourite} overlimit={overlimit} />
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextStyle variation={overlimit && 'subdued'}>{actions?.length ?? 'No data'}</TextStyle>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextStyle variation={overlimit && 'subdued'}>
            {sessionLength ? convertSecondsToHHMMSS(sessionLength) : 'No data'}
          </TextStyle>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <LinksContainer>
            <LinksTitle>landed</LinksTitle>
            <LinksItem>
              <LinkItemStack>
                <Tooltip content={landingPage}>
                  <TextStyle variation={overlimit && 'subdued'}>
                    <LinksUrl>
                      {landingPage?.replace(currentUser.siteBaseUrl, '') ?? 'No data'}
                    </LinksUrl>
                  </TextStyle>
                </Tooltip>
                {landingPage && (
                  <ListActionButtonsContainer>
                    <ListActionButton
                      role="button"
                      tabIndex={0}
                      onClick={() => openInNewTab(landingPage)}
                      onKeyDown={() => openInNewTab(landingPage)}
                    >
                      <Icon name={blankLinkIcon} />
                    </ListActionButton>
                    <ListActionButton
                      role="button"
                      tabIndex={0}
                      onClick={() => copyToClipboard(landingPage)}
                      onKeyDown={() => copyToClipboard(landingPage)}
                    >
                      <Icon name={copyIcon} />
                    </ListActionButton>
                  </ListActionButtonsContainer>
                )}
              </LinkItemStack>
            </LinksItem>
            <LinksTitle>exited</LinksTitle>
            <LinksItem>
              <LinkItemStack>
                <Tooltip content={exitPage}>
                  <TextStyle variation={overlimit && 'subdued'}>
                    <LinksUrl>
                      {exitPage?.replace(currentUser.siteBaseUrl, '') ?? 'No data'}
                    </LinksUrl>
                  </TextStyle>
                </Tooltip>
                {exitPage && (
                  <ListActionButtonsContainer>
                    <ListActionButton
                      role="button"
                      tabIndex={0}
                      onClick={() => openInNewTab(exitPage)}
                      onKeyDown={() => openInNewTab(exitPage)}
                    >
                      <Icon name={blankLinkIcon} />
                    </ListActionButton>
                    <ListActionButton
                      role="button"
                      tabIndex={0}
                      onClick={() => copyToClipboard(exitPage)}
                      onKeyDown={() => copyToClipboard(exitPage)}
                    >
                      <Icon name={copyIcon} />
                    </ListActionButton>
                  </ListActionButtonsContainer>
                )}
              </LinkItemStack>
            </LinksItem>
          </LinksContainer>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <TextStyle variation={overlimit && 'subdued'}>
            {createdAt ? transformDateToDefaultFormat(createdAt) : 'No data'}
          </TextStyle>
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  )
})

export default RowMarkup
