import React from 'react'
import { FormLayout, TextField } from '@shopify/polaris'
import { Form as PolarisForm, Formik } from 'formik'
import InlineError from 'components/InlineError'
import StyledButton from 'components/StyledButton'
import { saveIcon } from 'utils/global-icon-names'

const Form = ({ user = {}, errors, onSubmit }) => (
  <Formik
    enableReinitialize
    initialValues={{
      ...user,
    }}
    onSubmit={onSubmit}
  >
    {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
      <PolarisForm onSubmit={handleSubmit} autoComplete="off">
        <InlineError message={errors.base} />
        <FormLayout>
          <FormLayout.Group>
            <TextField
              name="firstName"
              label="First name"
              helpText="Provide the user's first name."
              onChange={(v) => setFieldValue('firstName', v)}
              value={values.firstName}
              error={errors.firstName}
            />
            <TextField
              name="lastName"
              label="Last name"
              helpText="Provide the user's last name."
              onChange={(v) => setFieldValue('lastName', v)}
              value={values.lastName}
              error={errors.lastName}
            />
          </FormLayout.Group>
          <TextField
            name="email"
            label="Email"
            type="email"
            helpText="Provide the user's email address."
            onChange={(v) => setFieldValue('email', v)}
            value={values.email}
            error={errors.email}
          />
          {user.provider !== 'shopify' && (
            <TextField
              name="password"
              label="Password"
              type="password"
              helpText={
                user.id
                  ? "Leave the field clean, if you don't want to change the password."
                  : 'Provide a login password for the user.'
              }
              onChange={(v) => setFieldValue('password', v)}
              value={values.password}
              error={errors.password}
            />
          )}
          <StyledButton
            primary
            submit
            loading={isSubmitting}
            iconName={saveIcon}
            actionName="Save"
          />
        </FormLayout>
      </PolarisForm>
    )}
  </Formik>
)

export default Form
