import React from 'react'
import { Layout, Card, FormLayout, TextField } from '@shopify/polaris'
import { Formik, Form } from 'formik'
import { useStore } from 'utils/store'
import { observer } from 'mobx-react-lite'
import InlineError from 'components/InlineError'
import StyledButton from 'components/StyledButton'
import { saveIcon } from 'utils/global-icon-names'

const EditAccountForm = observer(() => {
  const {
    appStore: { currentUser },
    accountStore: { updateAccount, errors },
  } = useStore()

  return (
    <Layout.AnnotatedSection title="Account settings" description="Update your account settings.">
      <Card sectioned>
        <Formik
          enableReinitialize
          initialValues={{
            ...currentUser,
          }}
          onSubmit={(v) => updateAccount(v)}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <InlineError message={errors.base} />
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    name="firstName"
                    label="First name"
                    helpText="Provide your first name."
                    onChange={(v) => setFieldValue('firstName', v)}
                    value={values.firstName}
                    error={errors.firstName}
                  />
                  <TextField
                    name="lastName"
                    label="Last name"
                    helpText="Provide your last name."
                    onChange={(v) => setFieldValue('lastName', v)}
                    value={values.lastName}
                    error={errors.lastName}
                  />
                </FormLayout.Group>
                <TextField
                  name="email"
                  label="Email address"
                  helpText="Provide your email address."
                  type="email"
                  onChange={(v) => setFieldValue('email', v)}
                  value={values.email}
                  error={errors.email}
                />
                <StyledButton
                  primary
                  submit
                  loading={isSubmitting}
                  iconName={saveIcon}
                  actionName="Save"
                />
              </FormLayout>
            </Form>
          )}
        </Formik>
      </Card>
    </Layout.AnnotatedSection>
  )
})

export default EditAccountForm
