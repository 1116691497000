import React from 'react'
import { Layout, Page } from '@shopify/polaris'
import buildRoute from 'utils/build-route'
import EditAccountForm from './EditAccountForm'
import AccountStore from './store'

const AccountPage = () => (
  <Page title="Account" breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
    <Layout>
      <EditAccountForm />
    </Layout>
  </Page>
)

export default buildRoute({
  stores: () => ({
    accountStore: new AccountStore(),
  }),
})(AccountPage)
