import { Layout, Page } from '@shopify/polaris'
import PageSpinner from 'components/PageSpinner'
import React, { useEffect } from 'react'
import buildRoute from 'utils/build-route'
import { useStore } from 'utils/store'
import ConversationConfig from './ConversationConfig'
import CustomCSSToastConfig from './CustomCSSToastConfig'
import ChatSettingsPageStore from './store'
import ToastConfig from './ToastConfig'

const ChatSettingsPage = () => {
  const {
    chatSettingsPageStore: { getToastConfig, loadingSettings },
  } = useStore()

  useEffect(() => {
    getToastConfig()
  }, [])

  if (loadingSettings) return <PageSpinner />

  return (
    <Page title="Chat settings" breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
      <Layout>
        <ConversationConfig />
        <ToastConfig />
        <CustomCSSToastConfig />
      </Layout>
    </Page>
  )
}

export default buildRoute({
  stores: () => ({
    chatSettingsPageStore: new ChatSettingsPageStore(),
  }),
})(ChatSettingsPage)
