import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'
import { CardHorizontal, CardTitleSmall, Group, Badge } from './styles'

const FreeTrial = observer(() => {
  const {
    appStore: { currentUser },
  } = useStore()

  if (currentUser.site.planPrice > 0 || currentUser.site.trialDaysRemaining === 0) return null

  return (
    <CardHorizontal>
      <Group>
        <Badge>FREE</Badge>
        <CardTitleSmall>Trial available</CardTitleSmall>
      </Group>
      {currentUser.site.trialDaysRemaining} days trial for any plan
    </CardHorizontal>
  )
})

export default FreeTrial
