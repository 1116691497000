import React, { useEffect } from 'react'
import { Popover, ResourceList, TextStyle } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'
import NotificationModal from './NotificationModal'
import NotificationsIcon from './NotificationsIcon'
import Notification from './Notification'

const Notifications = observer(() => {
  const {
    topbarNotificationsStore: {
      notifications,
      getNotifications,
      notificationsPopoverActive,
      toggleNotificationsPopover,
    },
  } = useStore()

  useEffect(() => {
    getNotifications()
  }, [])

  const unreadNotifications = notifications.filter((n) => !n.readAt)
  const unreadImportantNotifications = unreadNotifications.filter((n) => n.params.important)

  return (
    <>
      <Popover
        sectioned
        active={notificationsPopoverActive}
        activator={
          <NotificationsIcon
            onClick={toggleNotificationsPopover}
            count={unreadNotifications.length}
          />
        }
        onClose={toggleNotificationsPopover}
        ariaHaspopup={false}
        fullHeight
      >
        <Popover.Pane fixed>
          <Popover.Section>
            <TextStyle variation="strong">Notifications</TextStyle>
          </Popover.Section>
        </Popover.Pane>
        <Popover.Pane>
          <ResourceList items={notifications} renderItem={Notification} />
        </Popover.Pane>
      </Popover>
      {unreadImportantNotifications.map((notification) => (
        <NotificationModal
          key={notification.id}
          id={notification.id}
          title={notification.params.title}
          body={notification.params.body}
          createdAt={notification.createdAt}
          type={notification.type}
        />
      ))}
    </>
  )
})

export default Notifications
