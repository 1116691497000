import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'
import history from 'utils/history'

class Store {
  errors = {}
  slug = ''

  constructor() {
    makeAutoObservable(this)
  }

  loginUser = (attributes) => {
    const modifiedAttributes = {
      ...attributes,
      slug: this.slug,
    }
    return agent.Users.login(modifiedAttributes).then(this.loginUserSuccess, this.loginUserError)
  }

  loginUserSuccess = () => {
    this.errors = {}
    this.reloadCurrentUser().then(() => {
      history.push('/')
    })
  }

  loginUserError = (error) => {
    this.handleError(error)
  }

  setSlug = (slug) => {
    this.slug = slug
  }
}

export default Store
