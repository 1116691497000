/* eslint-disable arrow-body-style */
import React from 'react'
import { rebuildStore } from 'utils/store'

const buildRoute = (options = {}) => {
  return (WrappedComponent) =>
    class extends React.Component {
      constructor() {
        super()

        if (options.stores) {
          rebuildStore(options.stores())
        }
      }

      render() {
        return <WrappedComponent />
      }
    }
}

export default buildRoute
