import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'

class Store {
  errors = {}

  constructor() {
    makeAutoObservable(this)
  }

  updateAccount = (attributes) =>
    agent.Account.update(attributes).then(this.updateAccountSucces, this.updateAccountError)

  updateAccountSucces = () => {
    this.errors = {}
    this.reloadCurrentUser()
    this.showNotification('Account updated.')
  }

  updateAccountError = (error) => {
    this.handleError(error)
  }
}

export default Store
