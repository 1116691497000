import styled from 'styled-components'
import {
  primaryWhite,
  secondaryWhite,
  primaryGrey,
  primaryBlack,
  primaryGreen,
  primaryColorLight,
  primaryColor,
} from 'utils/colors'

export const Divider = styled.div`
  margin: 1.5rem 0;
  border-bottom: thin solid ${secondaryWhite};
  width: 70%;
`

export const Title = styled.h1`
  font-size: 4rem;
  line-height: 1;
  text-align: center;
  color: ${primaryBlack};
  padding: 4rem;
`

export const Layout = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: ${(props) =>
    `repeat(${props.numberOfItems}, calc(calc(100% - ${props.numberOfItems - 1}rem) / ${
      props.numberOfItems
    }))`};
  gap: 1rem;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: 900px) {
    margin: 0 15rem;
  }

  @media screen and (max-width: 800px) {
    margin: 0 10rem;
  }

  @media screen and (max-width: 700px) {
    margin: 0 5rem;
  }

  @media screen and (max-width: 700px) {
    margin: 0;
  }
`

export const LayoutCenter = styled(Layout)`
  justify-items: center;
`

export const Card = styled.div`
  position: relative;
  border-radius: 0.5rem;
  padding: 1.5rem 0;
  background-color: ${(props) => props.backgroundColor || 'white'};
  border: thin solid ${primaryWhite};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: 0;
  box-shadow: var(
    --p-card-shadow,
    0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15)
  );
`

export const CardHorizontal = styled(Card)`
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 5rem;
`

export const CardTitle = styled.h2`
  background-color: ${primaryColorLight};
  border-radius: 3px;
  margin-top: 1rem;
  width: fit-content;
  font-size: 1.8rem;
  font-weight: 600;
  color: ${primaryWhite};
  padding: 0.5rem 2rem;
  text-align: center;
`

export const CardTitleSmall = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${primaryBlack};
`

export const CardList = styled.ul`
  max-width: 18rem;
  list-style-type: none;
  margin-top: 2.5rem;
  padding: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const CardListItem = styled.li`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export const Price = styled.div`
  color: ${primaryGrey};
  font-size: 1.5rem;
  line-height: 0.7;
  margin-bottom: 3rem;
  margin-top: 1rem;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, fit-content(33%));
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
`

export const PriceTag = styled.div`
  font-weight: 500;
  align-self: start;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
`

export const PriceValue = styled.div`
  color: ${primaryBlack};
  font-size: 3rem;
  grid-row: 1 / -1;
  grid-column: 2 / 3;
`

export const PricePeriod = styled.div`
  font-weight: 500;
  align-self: end;
  grid-row: 2 / 3;
  grid-column: 3 / 4;
`

export const Group = styled.div`
  display: flex;
  gap: 1rem;
`

export const Badge = styled.div`
  padding: 0 1rem;
  border-radius: 100rem;
  background-color: ${primaryGreen};
  color: ${primaryWhite};
`

export const IconWrapper = styled.div`
  font-size: 10rem;
`

export const TextButton = styled.button`
  all: unset;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const Segment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
`

export const ListIconWrapper = styled.div`
  display: flex;
  color: ${(props) => (props.color ? props.color : 'green')};
`

export const CardRibbon = styled.div`
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 3rem;
  width: 12rem;
  top: 1.6rem;
  right: -2.9rem;
  transform: rotate(45deg);
  clip-path: polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%);
  background: ${primaryColor};
`

export const Feature = styled.span``
