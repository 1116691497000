import { ResourceList } from '@shopify/polaris'
import styled from 'styled-components'

export const ListFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
`

export const StyledResourceList = styled(ResourceList)`
  overflow-x: auto !important;
`
