import React from 'react'
import { Card } from '@shopify/polaris'
import Icon from 'components/Icon'
import { CountersWrapper, IconWrapper, BottomInterior, TopInterior, Text } from './styles'

const Counters = ({ resources }) => (
  <CountersWrapper>
    {resources.map((resource) => (
      <div key={`counter-${resource.name}`}>
        <Card sectioned>
          <TopInterior size="extraLarge">
            <Text>{resource.amount}</Text>
            <IconWrapper>
              <Icon name={resource.icon} />
            </IconWrapper>
          </TopInterior>
          <BottomInterior>{resource.name}</BottomInterior>
        </Card>
      </div>
    ))}
  </CountersWrapper>
)

export default Counters
