import React from 'react'
import { observer } from 'mobx-react-lite'
import { TextField } from '@shopify/polaris'
import { Formik, Form } from 'formik'
import { useStore } from 'utils/store'
import { LayoutCenter, TextButton } from './styles'

const DiscountCodeInput = observer(() => {
  const {
    plansPageStore: { applyDiscount, discountCodeInputActive, showDiscountCodeInput },
    appStore: { currentUser },
  } = useStore()

  if (discountCodeInputActive)
    return (
      <LayoutCenter>
        <br />
        <Formik enableReinitialize initialValues={{ code: '' }} onSubmit={(v) => applyDiscount(v)}>
          {({ values, setFieldValue, handleSubmit }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <TextField
                name="code"
                placeholder="Discount code"
                onChange={(v) => setFieldValue('code', v)}
                value={values.code}
              />
            </Form>
          )}
        </Formik>
      </LayoutCenter>
    )

  if (currentUser.site.discountCodeEnabled)
    return (
      <LayoutCenter>
        <br />
        <span>
          Do you have a discount code?{' '}
          <TextButton onClick={() => showDiscountCodeInput()}>Click here</TextButton>
        </span>
      </LayoutCenter>
    )

  return null
})

export default DiscountCodeInput
