import agent from 'api/agent'
import { initializeStore } from 'utils/initialize-store'
import history from 'utils/history'

function setLoading(state) {
  this.loading = state
}

function fetch(id) {
  this.loading = true
  agent[this.agentName].show(id).then(this.fetchSuccess, this.fetchError)
}

function fetchSuccess(item) {
  this.item = item
  this.loading = false
}

function fetchError(error) {
  history.push(this.frontendUrl)
  this.handleError(error)
  this.loading = false
}

function create(item) {
  return agent[this.agentName].create(item).then(this.createSuccess, this.createError)
}

function createSuccess(item) {
  this.errors = {}
  this.showNotification(`${this.resourceName} created.`)
  history.push(`${this.frontendUrl}/${item.id}/details`)
}

function createError(error) {
  this.handleError(error)
}

function update(item) {
  return agent[this.agentName].update(item.id, item).then(this.updateSuccess, this.updateError)
}

function updateSuccess() {
  this.errors = {}
  this.showNotification(`${this.resourceName} updated.`)
}

function updateError(error) {
  this.handleError(error)
}

function destroy() {
  this.destroyModalActive = false
  this.loading = true
  agent[this.agentName].delete(this.item.id).then(this.destroySuccess, this.destroyError)
}

function destroySuccess() {
  this.loading = false
  this.showNotification(`${this.resourceName} deleted.`)
  history.push(this.frontendUrl)
}

function destroyError(error) {
  this.loading = false
  this.handleError(error)
}

function updateDestroyModalActive(state) {
  this.destroyModalActive = state
}

const resourceAttributes = {
  loading: true,
  item: null,
  errors: {},
  destroyModalActive: false,
  setLoading,
  fetch,
  fetchSuccess,
  fetchError,
  create,
  createSuccess,
  createError,
  update,
  updateSuccess,
  updateError,
  destroy,
  destroySuccess,
  destroyError,
  updateDestroyModalActive,
}

export const initializeResourceStore = (store) => initializeStore(store, resourceAttributes)
