import {
  criticalColor,
  primaryGreen,
  primaryColor,
  warningColor,
  secondaryYellow,
  primaryBlack,
  primaryColorLight,
} from 'utils/colors'
import Logo from 'images/logo.png'

const theme = {
  logo: {
    width: 150,
    topBarSource: Logo,
  },
  colors: {
    surface: primaryBlack,
    onSurface: primaryBlack,
    interactive: primaryColorLight,
    secondary: primaryBlack,
    primary: primaryColor,
    critical: criticalColor,
    warning: warningColor,
    highlight: primaryColorLight,
    success: primaryGreen,
    decorative: secondaryYellow,
  },
}

export default theme
