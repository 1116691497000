import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'

class Store {
  notifications = []
  notificationsPopoverActive = false
  loading = false

  constructor() {
    makeAutoObservable(this)
  }

  getNotifications = () => {
    agent.Notifications.index().then(this.getNotificationsSuccess)
  }

  getNotificationsSuccess = (notifications) => {
    this.notifications = notifications
  }

  toggleNotificationsPopover = () => {
    this.notificationsPopoverActive = !this.notificationsPopoverActive
  }

  setNotificationAsRead = (id) => {
    this.notifications.find((n) => n.id === id).readAt = new Date().toUTCString()
    agent.Notifications.markAsRead({ ids: [id] })
  }
}

export default Store
