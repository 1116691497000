import React from 'react'
import { Button, FormLayout, TextField } from '@shopify/polaris'
import { Form, Formik } from 'formik'
import InlineError from 'components/InlineError'

const LoginForm = ({ errors, onSubmit }) => (
  <Formik
    enableReinitialize
    initialValues={{
      email: '',
      password: '',
    }}
    onSubmit={(v) => onSubmit(v)}
  >
    {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
      <Form onSubmit={handleSubmit} autoComplete="off">
        <InlineError message={errors.base} />
        <FormLayout>
          <TextField
            name="email"
            label="Email"
            type="email"
            onChange={(v) => setFieldValue('email', v)}
            value={values.email}
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            onChange={(v) => setFieldValue('password', v)}
            value={values.password}
          />
          <Button primary submit loading={isSubmitting}>
            Login
          </Button>
        </FormLayout>
      </Form>
    )}
  </Formik>
)

export default LoginForm
