import React from 'react'
import { Spinner } from '@shopify/polaris'
import { SpinnerWrapper } from './styles'

const PageSpinner = () => (
  <SpinnerWrapper>
    <Spinner />
  </SpinnerWrapper>
)

export default PageSpinner
