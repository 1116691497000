import React, { useEffect, useState, useCallback } from 'react'
import lodash from 'lodash'
import axios from 'axios'
import { Icon, Autocomplete, InlineError } from '@shopify/polaris'
import { SearchMinor } from '@shopify/polaris-icons'
import { ErrorField } from 'utils/global-styles'
import EmptyState from './EmptyState'
import SelectedOptions from './SelectedOptions'

const BaseSelector = ({
  label,
  placeholder,
  apiUrl,
  selected,
  onChange,
  error,
  multiple = false,
}) => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [inputText, setInputText] = useState(selected || '')

  const fetchAutocomplete = useCallback(
    lodash.debounce((value) => {
      if (!loading) {
        setLoading(true)
      }

      fetchResource(value)
    }, 500),
    [selected],
  )

  const fetchResource = (search) => {
    axios.get(encodeURI(`${apiUrl}${search || ''}`)).then((response) => {
      setLoading(false)

      const listItems = response.data.map((resource) => ({
        label: resource.name,
        value: resource.id.toString(),
      }))

      if (multiple) {
        const selectedOptions = options.filter((o) => selected.includes(o.value))

        selectedOptions.forEach((option) => {
          if (!listItems.find((o) => o.value === option.value)) {
            listItems.push(option)
          }
        })
      }

      setOptions(listItems)
    })
  }

  useEffect(() => {
    fetchResource(inputText)
  }, [])

  return (
    <>
      {multiple && <SelectedOptions selected={selected} options={options} />}
      <Autocomplete
        options={options}
        allowMultiple={multiple}
        selected={selected || ''}
        onSelect={(v) => {
          setInputText(multiple ? '' : v[0])
          onChange(multiple ? v : v[0])
        }}
        emptyState={<EmptyState />}
        loading={loading}
        textField={
          <Autocomplete.TextField
            id={lodash.uniqueId('selector_')}
            autoComplete={false}
            onChange={(newValue) => {
              setInputText(newValue)
              fetchAutocomplete(newValue)
            }}
            value={inputText}
            label={label}
            prefix={<Icon source={SearchMinor} />}
            placeholder={placeholder}
          />
        }
      />
      <ErrorField>
        <InlineError message={error} />
      </ErrorField>
    </>
  )
}

export default BaseSelector
