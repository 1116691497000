import agent from 'api/agent'
import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { primaryColor } from 'utils/colors'
import {
  coloredAddToCartIcon,
  coloredErrorIcon,
  coloredListIcon,
  coloredPaidIcon,
} from 'utils/global-icon-names'
import { analyzedDataTypes } from './config'
import { getDateLabelFormat } from './utils'

class Store {
  timePeriod = 'last7days'
  dates = []
  focus = null
  dataTypePopoverActive = false
  selectedDataType = analyzedDataTypes[0]
  resources = [
    { name: analyzedDataTypes.at(0).name, amount: 0, icon: coloredListIcon },
    { name: analyzedDataTypes.at(1).name, amount: 0, icon: coloredAddToCartIcon },
    { name: analyzedDataTypes.at(2).name, amount: 0, icon: coloredPaidIcon },
    { name: analyzedDataTypes.at(3).name, amount: 0, icon: coloredErrorIcon },
  ]
  state = {
    labels: [],
    datasets: [
      {
        label: this.selectedDataType.name,
        backgroundColor: primaryColor,
        borderColor: primaryColor,
        borderWidth: 4,
        data: [],
      },
    ],
  }
  loading = true

  constructor() {
    makeAutoObservable(this)
  }

  setTimePeriod = (timePeriod) => {
    this.timePeriod = timePeriod
  }

  setFirstDate = (date) => {
    this.dates[0] = date
  }

  setSecondDate = (date) => {
    this.dates[1] = date
  }

  setFocus = (focus) => {
    this.focus = focus
  }

  setDataTypePopoverActive = (state) => {
    this.dataTypePopoverActive = state
  }

  setSelectedDataType = (dataType) => {
    this.selectedDataType = dataType
  }

  handleDatesRange = () => {
    let startDate = this.dates[0] ? moment({ ...this.dates[0] }) : null
    let endDate = this.dates[1] ? moment({ ...this.dates[1] }) : null

    if (this.timePeriod === 'last7days') {
      startDate = moment().subtract(6, 'days')
      endDate = moment()
    }
    if (this.timePeriod === 'last30days') {
      startDate = moment().subtract(29, 'days')
      endDate = moment()
    }
    if (this.timePeriod === 'last6months') {
      startDate = moment().set({ date: 1 }).subtract(5, 'months')
      endDate = moment().set({ date: 1 })
    }

    return [
      startDate.utcOffset(0).set({ h: 0, m: 0, s: 0, ms: 0 }).toISOString(),
      endDate
        .utcOffset(0)
        .add(1, this.timePeriod === 'last6months' ? 'months' : 'days')
        .set({ h: 0, m: 0, s: 0, ms: 0 })
        .toISOString(),
    ]
  }

  getAnalytics = () => {
    if ((this.dates[0] && this.dates[1]) || this.timePeriod !== 'custom') {
      this.loading = true
      const formatedDates = this.handleDatesRange()
      agent.Replays.analytics(formatedDates, this.selectedDataType.field).then(
        (response) => this.getAnalyticsSuccess(response, formatedDates),
        this.getAnalyticsError,
      )
    }
  }

  getAnalyticsSuccess = (response, datesISO) => {
    this.resources.forEach((resource, index) => {
      resource.amount = response.resources[index]
    })

    this.handleStateLabels(response.labels, datesISO)
    this.state.datasets.at(0).data = response.data
    this.state.datasets.at(0).label = this.selectedDataType.name
    this.loading = false
  }

  getAnalyticsError = (error) => {
    this.handleError(error)
  }

  handleStateLabels = (labels, formatedDates) => {
    this.state.labels = []

    labels.forEach((label) => {
      this.state.labels.push(moment(label).format(getDateLabelFormat(formatedDates)))
    })
  }
}

export default Store
