import React from 'react'
import BaseSelector from './BaseSelector'

const TimezoneSelector = ({ selected, onChange, multiple }) => (
  <BaseSelector
    label="Timezone"
    placeholder="Search for timezone..."
    apiUrl="/api/timezones?search="
    multiple={multiple}
    selected={selected}
    onChange={onChange}
  />
)

export default TimezoneSelector
