import React from 'react'
import { Pagination, ResourceList } from '@shopify/polaris'
import DelationConfirmationModal from 'components/DelationConfirmationModal'
import Filters from 'components/Filters'
import { ListFooter } from './styles'
import { filterComponent, filterValueLabel } from './utils'
import { maxNumberItemsPerPage } from './config'

const List = ({
  loading,
  resourceName,
  sortOptions,
  promotedBulkActions,
  searchPhrase,
  filters,
  sortValue,
  pageNumber,
  items,
  getItems,
  itemComponent,
  selectedItems,
  updateSearchPhrase,
  removeSearchPhrase,
  updateSortValue,
  updateSelectedItems,
  increasePageNumber,
  decreasePageNumber,
  updateFilter,
  removeFilter,
  clearAllFilters,
  deleteItems,
  updateDestroyModalActive,
  destroyModalActive,
}) => (
  <>
    <ResourceList
      resourceName={resourceName}
      items={items}
      renderItem={itemComponent}
      selectedItems={selectedItems}
      onSelectionChange={updateSelectedItems}
      sortValue={sortValue}
      onSortChange={updateSortValue}
      loading={loading}
      filterControl={
        <Filters
          getItems={getItems}
          queryValue={searchPhrase}
          onQueryChange={updateSearchPhrase}
          onQueryClear={removeSearchPhrase}
          filters={filters.map((filter) => ({
            key: filter.name,
            label: filter.name,
            filter: filterComponent(filter, updateFilter),
            shortcut: false,
          }))}
          appliedFilters={filters
            .filter((f) => f.value)
            .map((filter) => ({
              key: filter.name,
              label: `${filter.name}: ${filterValueLabel(filter)}`,
              onRemove: () => removeFilter(filter),
            }))}
          onClearAll={clearAllFilters}
        />
      }
      promotedBulkActions={promotedBulkActions}
      sortOptions={sortOptions}
    />
    {!loading && (
      <ListFooter>
        <Pagination
          label={`Page ${pageNumber}`}
          hasPrevious={pageNumber > 1}
          onPrevious={() => {
            decreasePageNumber()
          }}
          hasNext={items.length >= maxNumberItemsPerPage}
          onNext={() => {
            increasePageNumber()
          }}
        />
      </ListFooter>
    )}
    <DelationConfirmationModal
      master
      modalActive={destroyModalActive}
      updateModalActive={updateDestroyModalActive}
      deleteAction={deleteItems}
      resourceName={resourceName.plural}
    />
  </>
)

export default List
