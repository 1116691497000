import React from 'react'
import { Spinner as PolarisSpinner } from '@shopify/polaris'
import { SpinnerWrapper } from './styles'

const Spinner = () => (
  <SpinnerWrapper>
    <PolarisSpinner size="large" />
  </SpinnerWrapper>
)

export default Spinner
