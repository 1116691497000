import React from 'react'
import { Banner, Layout } from '@shopify/polaris'
import { useStore } from 'utils/store'
import { isLimitOfReplays, isNearLimitOfReplays } from './utils'

const OverlimitBanner = ({ totalCountOfReplays }) => {
  const {
    appStore: { currentUser },
  } = useStore()

  if (!isNearLimitOfReplays(currentUser, totalCountOfReplays)) return null

  let bannerInfo = {
    title: 'The limit of replays almost reached',
    status: 'warning',
    content:
      'You are close to reaching the maximum limit of replays. Do not lose any replay and upgrade your billing plan.',
  }

  if (isLimitOfReplays(currentUser, totalCountOfReplays))
    bannerInfo = {
      title: 'The limit of replays has been reached',
      status: 'critical',
      content:
        'You have reached the limit of replays, but we are still recording. To get access to these replays, please upgrade your billing plan.',
    }

  return (
    <Layout.Section>
      <Banner
        title={bannerInfo.title}
        action={{ content: 'Upgrade your billing plan', url: '/settings/billing' }}
        status={bannerInfo.status}
      >
        {bannerInfo.content}
      </Banner>
    </Layout.Section>
  )
}

export default OverlimitBanner
