let beaconInitialized = false

export const helpscoutIdentify = (currentUser) => {
  if (!window.Beacon) return
  if (!currentUser) return

  const { site, ...user } = currentUser

  if (!beaconInitialized) {
    window.Beacon('init', process.env.HELPSCOUT_TOKEN)
    beaconInitialized = true
  }

  window.Beacon('identify', {
    ...user,
  })
}
