import React from 'react'
import { SearchMinor } from '@shopify/polaris-icons'
import { Icon, TextContainer } from '@shopify/polaris'
import { EmptyStateContainer } from './styles'

const EmptyState = () => (
  <EmptyStateContainer>
    <Icon source={SearchMinor} />
    <TextContainer>Could not find any results</TextContainer>
  </EmptyStateContainer>
)

export default EmptyState
