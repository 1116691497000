import React from 'react'
import NotFound from 'components/NotFound'
import notFoundSVG from './404.svg'

const NotFoundPage = () => (
  <NotFound
    notFoundSVG={notFoundSVG}
    text="Oops - we've looked everywhere and couldn't find this."
    url="/"
    buttonText="Home page"
  />
)

export default NotFoundPage
