import React from 'react'
import { observer } from 'mobx-react-lite'
import { ActionList, Button, Card, Popover } from '@shopify/polaris'
import { useStore } from 'utils/store'
import { analyzedDataTypes } from './config'

const PrimaryChartCardHeader = observer(() => {
  const {
    analyticsStore: {
      dataTypePopoverActive,
      setDataTypePopoverActive,
      setSelectedDataType,
      selectedDataType,
    },
  } = useStore()

  return (
    <Card.Header title="Line chart">
      <Popover
        active={dataTypePopoverActive}
        activator={
          <Button
            monochrome
            removeUnderline
            onClick={() => setDataTypePopoverActive(!dataTypePopoverActive)}
            disclosure
            plain
          >
            Select data
          </Button>
        }
        onClose={() => setDataTypePopoverActive(false)}
      >
        <ActionList
          items={[
            {
              content: analyzedDataTypes.at(0).name,
              active: selectedDataType.field === analyzedDataTypes.at(0).field,
              onAction: () => setSelectedDataType(analyzedDataTypes.at(0)),
            },
            {
              content: analyzedDataTypes.at(1).name,
              active: selectedDataType.field === analyzedDataTypes.at(1).field,
              onAction: () => setSelectedDataType(analyzedDataTypes.at(1)),
            },
            {
              content: analyzedDataTypes.at(2).name,
              active: selectedDataType.field === analyzedDataTypes.at(2).field,
              onAction: () => setSelectedDataType(analyzedDataTypes.at(2)),
            },
            {
              content: analyzedDataTypes.at(3).name,
              active: selectedDataType.field === analyzedDataTypes.at(3).field,
              onAction: () => setSelectedDataType(analyzedDataTypes.at(3)),
            },
          ]}
        />
      </Popover>
    </Card.Header>
  )
})

export default PrimaryChartCardHeader
