import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Card, Layout, Page } from '@shopify/polaris'
import { disconnect } from 'api/websocket'
import PageSpinner from 'components/PageSpinner'
import Icon from 'components/Icon'
import { criticalColor } from 'utils/colors'
import { readCookie } from 'utils/global-functions'
import { useStore } from 'utils/store'
import buildRoute from 'utils/build-route'
import { liveIcon } from 'utils/global-icon-names'
import CurrentUrl from './CurrentUrl'
import ShareableLink from './ShareableLink'
import RestoreSessionBanner from './RestoreSessionBanner'
import CurrentLiveList from './CurrentLiveList'
import CloseButton from './CloseButton'
import MessageModal from './MessageModal'
import { CardWrapper, DetailsContainer, PlayerWrapper, TitleIconWrapper } from './styles'
import LiveStore from './item-store'
import DisabledSessionRecordingBanner from './DisabledSessionRecordingBanner'
import SidePanel from './SidePanel'

const LivePage = observer(() => {
  const {
    appStore: { currentUser },
    detailsReplayStore: {
      loading,
      item: replay,
      liveUniqueId,
      destroySession,
      subscribeSession,
      copyToClipboard,
      messageModalActive,
      updateMessageModalActive,
      sendMessage,
      currentUrlFromReplayer,
      updateFavourite,
      updateWatched,
      destroyPlayer,
      liveSessionRunning,
    },
  } = useStore()

  const lastSessionId = readCookie('lastLiveSession')
  const shareableLink = `${currentUser.siteBaseUrl}?etLiveUuid=${liveUniqueId}`

  useEffect(() => {
    subscribeSession(liveUniqueId)

    return () => {
      disconnect()
      destroySession()
      destroyPlayer()
    }
  }, [])

  if (loading) return <PageSpinner />

  return (
    <Page
      fullWidth
      title="Live screen sharing"
      titleMetadata={
        liveSessionRunning && (
          <TitleIconWrapper color={criticalColor}>
            <Icon name={liveIcon} />
          </TitleIconWrapper>
        )
      }
      breadcrumbs={[{ content: 'Recordings', url: '/' }]}
      primaryAction={<CloseButton />}
    >
      {replay ? (
        <DetailsContainer>
          <CardWrapper>
            <Card sectioned>
              <CurrentUrl
                replay={replay}
                currentUrlFromReplayer={currentUrlFromReplayer}
                updateFavourite={updateFavourite}
                updateWatched={updateWatched}
                copyToClipboard={copyToClipboard}
                actionsActive={false}
              />
              <PlayerWrapper>
                <div id="player-container" />
              </PlayerWrapper>
            </Card>
          </CardWrapper>
          <SidePanel liveMode chatLogDisabled={false} />
        </DetailsContainer>
      ) : (
        <Layout>
          <DisabledSessionRecordingBanner />
          <RestoreSessionBanner lastSessionId={lastSessionId} />
          <ShareableLink shareableLink={shareableLink} copyToClipboard={copyToClipboard} />
          <CurrentLiveList />
        </Layout>
      )}
      <MessageModal
        activeModal={messageModalActive}
        updateActiveModal={updateMessageModalActive}
        sendMessage={sendMessage}
      />
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    detailsReplayStore: new LiveStore(),
  }),
})(LivePage)
