import React from 'react'
import { useLocation } from 'react-router-dom'
import { Navigation } from '@shopify/polaris'
import { HomeMajor, ListMajor, ShareMinor, SettingsMajor } from '@shopify/polaris-icons'

const Sidebar = () => {
  const location = useLocation()

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        fill
        items={[
          {
            label: 'Home',
            icon: HomeMajor,
            url: '/',
            exactMatch: true,
          },
          {
            label: 'Records',
            icon: ListMajor,
            url: '/recordings',
          },
          {
            label: 'Live',
            icon: ShareMinor,
            url: '/live',
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            label: 'Settings',
            icon: SettingsMajor,
            url: '/settings',
          },
        ]}
      />
    </Navigation>
  )
}

export default Sidebar
