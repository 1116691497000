import mixpanel from 'mixpanel-browser'

export const setupMixpanel = () => {
  if (!process.env.MIXPANEL_TOKEN) {
    return
  }

  mixpanel.init(process.env.MIXPANEL_TOKEN)
}

export const mixpanelIdentify = (user) => {
  if (!process.env.MIXPANEL_TOKEN) {
    return
  }

  mixpanel.identify(user.id)
}

export const track = (event, properties = null) => {
  if (!process.env.MIXPANEL_TOKEN) {
    return
  }

  mixpanel.track(event, properties)
}
