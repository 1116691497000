import React from 'react'
import { Stack, Tag } from '@shopify/polaris'
import { SelectedOptionsWrapper } from './styles'

const SelectedOptions = ({ selected, options }) => (
  <SelectedOptionsWrapper>
    <Stack>
      {selected.map((option) => (
        <Tag key={`option-${option}`}>{options.find((x) => x.value === option).label}</Tag>
      ))}
    </Stack>
  </SelectedOptionsWrapper>
)

export default SelectedOptions
