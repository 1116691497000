import React, { useEffect } from 'react'
import { Router } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { AppProvider } from '@shopify/polaris'
import translations from '@shopify/polaris/locales/en.json'
import PolarisLink from 'components/PolarisLink'
import FullscreenSpinner from 'components/FullscreenSpinner'
import { setDefaultCssColors } from 'utils/colors'
import { useStore } from 'utils/store'
import history from 'utils/history'
import GlobalStyles from './global-styles'
import AppFrame from './AppFrame'
import theme from './theme'

const App = observer(() => {
  const {
    appStore: { loading, getCurrentUser },
  } = useStore()

  useEffect(() => {
    getCurrentUser()
    setDefaultCssColors()
  }, [])

  return (
    <Router history={history}>
      <AppProvider theme={theme} i18n={translations} linkComponent={PolarisLink}>
        {loading ? <FullscreenSpinner /> : <AppFrame />}
      </AppProvider>
      <GlobalStyles />
    </Router>
  )
})

export default App
