import React from 'react'
import { Button, EmptySearchResult, Heading, Stack, TextContainer } from '@shopify/polaris'
import { useStore } from 'utils/store'
import Icon from 'components/Icon'
import { EmptyStateIconWrapper } from './styles'

const EmptyState = () => {
  const {
    appStore: {
      currentUser: {
        site: { onboardingStatus, numberOfReplays, url },
      },
    },
  } = useStore()

  if (onboardingStatus === 'finished' && numberOfReplays > 0)
    return <EmptySearchResult title="No replays found" withIllustration />

  return (
    <Stack distribution="center" spacing="tight" alignment="center" vertical>
      <EmptyStateIconWrapper>
        <Icon name="FcClapperboard" />
      </EmptyStateIconWrapper>
      <Heading>Try app in action</Heading>
      <TextContainer>Click at the button below and record a sample.</TextContainer>
      <Button url={url} destructive>
        Record
      </Button>
    </Stack>
  )
}
export default EmptyState
