import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card, Stack, TextField } from '@shopify/polaris'
import StyledButton from 'components/StyledButton'
import { convertSecondsToHHMMSS } from 'utils/global-functions'
import { saveIcon } from 'utils/global-icon-names'
import { useStore } from 'utils/store'

const SendNoteForm = observer(
  ({
    currentReplayerTimestamp,
    createNoteText,
    setCreateNoteText,
    createNote,
    noteAddLoading,
    notesActive,
  }) => {
    const {
      appStore: { currentUser },
    } = useStore()

    return (
      <Card.Section>
        <Stack spacing="tight" vertical wrap={false}>
          <TextField
            label={
              notesActive
                ? `Save note at ${convertSecondsToHHMMSS(currentReplayerTimestamp / 1000)}.`
                : "You aren't able to add notes."
            }
            autoComplete="off"
            disabled={!notesActive}
            value={createNoteText}
            onChange={setCreateNoteText}
          />
          <StyledButton
            loading={noteAddLoading}
            onClick={() => createNote(currentUser)}
            primary
            disabled={!notesActive}
            iconName={saveIcon}
            actionName="Save"
          />
        </Stack>
      </Card.Section>
    )
  },
)

export default SendNoteForm
