export const primaryColor = '#f4455e'
export const primaryColorLight = '#e51735'
export const primaryColorDark = '#9d0b21'
export const primaryWhite = '#fff'
export const secondaryWhite = '#F1F2F3'
export const primaryGrey = '#455a64'
export const secondaryGrey = '#93a5ad'
export const primaryYellow = '#f7b731'
export const secondaryYellow = '#fad17b'
export const primaryViolet = '#5f3dc4'
export const primaryBlack = '#000'
export const primaryGreen = '#4cd137'
export const secondaryGreen = '#009432'

export const shopifySubduedColor = '#6d7175'
export const shopifyCardSubduedColor = '#fafbfb'
export const shopifyBorderColor = '#c9cccf'
export const notificationBadgeColor = '#f02849'
export const criticalColor = '#d72c0d'
export const warningColor = '#ffc453'

export const setDefaultCssColors = () => {
  document.documentElement.style.setProperty('--main-color', primaryColor)
  document.documentElement.style.setProperty('--main-color-subdued', primaryColorLight)
  document.documentElement.style.setProperty('yellow', primaryYellow)
  document.documentElement.style.setProperty('white', primaryWhite)
  document.documentElement.style.setProperty('black', primaryBlack)
}
