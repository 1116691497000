import React from 'react'
import { Page, Layout, Card } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'
import buildRoute from 'utils/build-route'
import ContactStore from 'routes/ContactPage/store'
import ContactForm from 'components/ContactForm'

const ContactPage = observer(() => {
  const {
    contactStore: { errors, message, sendEmail },
    appStore: { currentUser },
  } = useStore()

  return (
    <Page title="Contact">
      <Layout>
        <Layout.AnnotatedSection
          title="Contact us"
          description="Have any questions? Send us a message! We'll respond as soon as possible."
        >
          <Card sectioned>
            <ContactForm
              name={currentUser.name}
              email={currentUser.email}
              message={message}
              errors={errors}
              sendEmail={sendEmail}
            />
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    contactStore: new ContactStore(),
  }),
})(ContactPage)
