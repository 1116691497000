import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ContactPage from './ContactPage'
import PlansPage from './PlansPage'
import AuthorizedRoute from './AuthorizedRoute'
import AccountPage from './SettingsPage/AccountPage'
import SettingsPage from './SettingsPage'
import GeneralPage from './SettingsPage/GeneralPage'
import UsersPage from './SettingsPage/UsersPage'
import EditUserPage from './SettingsPage/UsersPage/EditPage'
import CreateUserPage from './SettingsPage/UsersPage/CreatePage'
import DetailsUserPage from './SettingsPage/UsersPage/DetailsPage'
import InstallPage from './InstallPage'
import LoginPage from './AdminPage/LoginPage'
import OverlimitPage from './AdminPage/OverlimitPage'
import NotFoundPage from './NotFoundPage'
import ChangePlansPage from './SettingsPage/BillingPage'
import ReplaysPage from './ReplaysPage'
import PublicDetailsReplayPage from './ReplaysPage/PublicDetailsPage'
import ReplaysSettingsPage from './SettingsPage/ReplaysSettingsPage'
import ReplayHub from './ReplaysPage/ReplayHub'
import AnalyticsPage from './AnalyticsPage'
import WelcomePage from './WelcomePage'
import ChatSettingsPage from './SettingsPage/ChatSettingsPage'
import NotificationSettingsPage from './SettingsPage/NotificationSettingsPage'

const Routes = () => (
  <Switch>
    <AuthorizedRoute path="/contact" component={ContactPage} />
    <AuthorizedRoute path="/welcome" component={WelcomePage} />
    <AuthorizedRoute path="/settings" exact component={SettingsPage} />
    <AuthorizedRoute path="/settings/account" component={AccountPage} />
    <AuthorizedRoute path="/settings/general" component={GeneralPage} />
    <AuthorizedRoute path="/settings/users" exact component={UsersPage} />
    <AuthorizedRoute path="/settings/users/:userId/edit" component={EditUserPage} />
    <AuthorizedRoute path="/settings/users/create" component={CreateUserPage} />
    <AuthorizedRoute path="/settings/users/:userId/details" component={DetailsUserPage} />
    <AuthorizedRoute path="/settings/billing" component={ChangePlansPage} />
    <AuthorizedRoute path="/settings/replays-settings" component={ReplaysSettingsPage} />
    <AuthorizedRoute path="/settings/chat-settings" component={ChatSettingsPage} />
    <AuthorizedRoute path="/settings/notifications-settings" component={NotificationSettingsPage} />
    <AuthorizedRoute path="/analytics" component={AnalyticsPage} />
    <AuthorizedRoute path="/plans" component={PlansPage} />
    <AuthorizedRoute path="/install" component={InstallPage} />
    <AuthorizedRoute path="/admin/overlimit" component={OverlimitPage} />
    <Route path="/admin/login/:slug" component={LoginPage} />
    <AuthorizedRoute path="/:replayId/details" component={ReplayHub} />
    <Route path="/public/:replayId/details" component={PublicDetailsReplayPage} />
    <AuthorizedRoute path="/" exact component={ReplaysPage} />
    <Route component={NotFoundPage} />
  </Switch>
)

export default Routes
