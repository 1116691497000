import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'
import { convertEmailNotificationsData } from './utils'

class Store {
  constructor() {
    makeAutoObservable(this)
  }

  updateConversationType = (conversationType) => {
    agent.Site.update({ conversation_type: conversationType }).then(
      this.updateRecordingSettingsSuccess,
      this.updateRecordingSettingsError,
    )
  }

  updateEmailNotifications = (state) => {
    agent.Site.update({ email_notifications_enabled: state }).then(
      this.updateRecordingSettingsSuccess,
      this.updateRecordingSettingsError,
    )
  }

  updateEmailNotificationsTriggers = (data) => {
    agent.Site.updateEmailNotifications(convertEmailNotificationsData(data)).then(
      this.updateRecordingSettingsSuccess,
      this.updateRecordingSettingsError,
    )
  }

  updateRecordingSettingsSuccess = () => {
    this.reloadCurrentUser()
    this.showNotification('Settings updated.')
  }

  updateRecordingSettingsError = (error) => {
    this.handleError(error)
  }
}

export default Store
