import AppStore from 'components/App/store'
import NotificationsStore from 'components/Notifications/store'
import TopbarNotificationsStore from 'components/App/Topbar/store'
import extendStores from 'utils/extend-stores'

const notificationsStore = new NotificationsStore()
const topbarNotificationsStore = new TopbarNotificationsStore()
const appStore = new AppStore()

const GlobalStores = {
  notificationsStore,
  topbarNotificationsStore,
  appStore,
}

extendStores({ stores: GlobalStores, globalStores: GlobalStores })

export default GlobalStores
