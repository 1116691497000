import { Layout, Page } from '@shopify/polaris'
import React from 'react'
import buildRoute from 'utils/build-route'
import EmailNotificationsConfig from './EmailNotifcationsConfig'
import EmailNotificationsActivator from './EmailNotificationsActivator'
import NotifcationSettingsPageStore from './store'

const NotifcationSettingsPage = () => (
  <Page title="Notifications settings" breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
    <Layout>
      <EmailNotificationsActivator />
      <EmailNotificationsConfig />
    </Layout>
  </Page>
)

export default buildRoute({
  stores: () => ({
    notifcationSettingsPageStore: new NotifcationSettingsPageStore(),
  }),
})(NotifcationSettingsPage)
