import React from 'react'
import { Modal } from '@shopify/polaris'
import ContactForm from 'components/ContactForm'
import { useStore } from 'utils/store'

const ContactModal = ({ activeModal, updateActiveModal, sendEmail, errors, message }) => {
  const {
    appStore: { currentUser },
  } = useStore()

  return (
    <Modal open={activeModal} onClose={() => updateActiveModal(false)} title="Contact our support">
      <Modal.Section>
        <ContactForm
          name={currentUser.name}
          email={currentUser.email}
          sendEmail={sendEmail}
          message={message}
          errors={errors}
        />
      </Modal.Section>
    </Modal>
  )
}

export default ContactModal
