import React from 'react'
import Icon from 'components/Icon'
import { Item, ItemText, ItemTextContent, ItemTextHeading, StyledLink, IconWrapper } from './styles'

const Option = ({ title, description, icon, url }) => (
  <StyledLink to={url}>
    <Item>
      <IconWrapper>
        <Icon name={icon} />
      </IconWrapper>
      <ItemText>
        <ItemTextHeading>{title}</ItemTextHeading>
        <ItemTextContent>{description}</ItemTextContent>
      </ItemText>
    </Item>
  </StyledLink>
)

export default Option
