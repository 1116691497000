export const showBackButton = (pathname) => {
  if (pathname === '/welcome' || pathname === '/plans') return null

  return {
    url: '/welcome',
    loading: false,
    disabled: false,
    content: 'Back',
  }
}

export const showFinishOnboarding = (pathname, loading, onAction) => {
  if (pathname === '/plans') return null

  return {
    onAction,
    content: 'Finish',
    loading,
  }
}
