import styled from 'styled-components'

export const SearchResultsWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`

export const UserMenuBox = styled.div`
  display: flex;
  gap: 1rem;
  justify-conter: center;
  align-items: center;

  @media screen and (max-width: 490px) {
    gap: 0.3rem;
  }
`
