import { makeAutoObservable } from 'mobx'

class Store {
  toasts = []

  constructor() {
    makeAutoObservable(this)
  }

  display = (message, options = { error: false }) => {
    this.toasts.push({
      id: Date.now(),
      message,
      error: options.error,
    })
  }

  hide = (id) => {
    this.toasts = this.toasts.filter((n) => n.id !== id)
  }
}

export default Store
