import styled from 'styled-components'
import { primaryWhite, shopifyBorderColor } from 'utils/colors'

export const Color = styled.div`
  width: 5.4rem;
  height: 2.2rem;
  border-radius: 0.2rem;
  border: thin solid ${shopifyBorderColor};
  background-color: ${(props) => props.backgroundColor || 'white'};
`

export const Swatch = styled.div`
  padding: 0.6rem;
  display: inline-block;
  cursor: pointer;
`

export const Popover = styled.div`
  position: absolute;
  z-index: 5000;
`

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`

export const Label = styled.span``

export const Field = styled.div`
  margin-top: 0.4rem;
  background: ${primaryWhite};
  border-radius: 0.3rem;
  border: thin solid ${shopifyBorderColor};
  width: fit-content;
  display: flex;
  align-items: center;
`
