export const positionOptions = [
  { label: 'Top right', value: 'topRight' },
  { label: 'Top center', value: 'topCenter' },
  { label: 'Top left', value: 'topLeft' },
  { label: 'Bottom right', value: 'bottomRight' },
  { label: 'Bottom center', value: 'bottomCenter' },
  { label: 'Bottom left', value: 'bottomLeft' },
]

export const transitionInOptions = [
  { label: 'Bounce in left', value: 'bounceInLeft' },
  { label: 'Bounce in right', value: 'bounceInRight' },
  { label: 'Bounce in up', value: 'bounceInUp' },
  { label: 'Bounce in down', value: 'bounceInDown' },
  { label: 'Fade in', value: 'fadeIn' },
  { label: 'Fade in down', value: 'fadeInDown' },
  { label: 'Fade in up', value: 'fadeInUp' },
  { label: 'Fade in left', value: 'fadeInLeft' },
  { label: 'Fade in right', value: 'fadeInRight' },
  { label: 'Flip in x', value: 'flipInX' },
]

export const transitionOutOptions = [
  { label: 'Fade out', value: 'fadeOut' },
  { label: 'Fade out up', value: 'fadeOutUp' },
  { label: 'Fade out down', value: 'fadeOutDown' },
  { label: 'Fade out left', value: 'fadeOutLeft' },
  { label: 'Fade out right', value: 'fadeOutRight' },
  { label: 'Flip out x', value: 'flipOutX' },
]

export const themeOptions = [
  { label: 'Light', value: 'light' },
  { label: 'Dark', value: 'dark' },
]

export const conversationOptions = [
  { label: 'Toast', value: 'toast' },
  { label: 'Tidio', value: 'tidio' },
]

export const activatedDeactivatedOptions = [
  { label: 'Activated', value: true },
  { label: 'Deactivated', value: false },
]

export const setCustomToastStyle = (customStyle) => {
  removeCustomToastStyle()
  const style = document.createElement('style')
  style.type = 'text/css'
  style.id = 'custom-izitoast-style'
  if (style.styleSheet) {
    style.styleSheet.cssText = customStyle
  } else {
    style.appendChild(document.createTextNode(customStyle))
  }
  document.getElementsByTagName('head')[0].appendChild(style)
}

export const removeCustomToastStyle = () => {
  document.getElementById('custom-izitoast-style')?.remove()
}
