import React, { useEffect } from 'react'
import { Card } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useStore } from 'utils/store'
import { initializePlayer, isRecordFinished } from './utils'
import { CardWrapper, PlayerWrapper } from './styles'
import CurrentUrl from './CurrentUrl'

const Player = observer(({ actionsActive = true }) => {
  const {
    detailsReplayStore: {
      loading,
      item: replay,
      updateWatched,
      setCurrentUrlFromReplayer,
      currentUrlFromReplayer,
      updateFavourite,
      copyToClipboard,
      setCurrentReplayerTimestamp,
      setReplayer,
    },
  } = useStore()

  const { replayId } = useParams()

  useEffect(() => {
    if (loading) {
      return
    }

    const repl = initializePlayer(replay, { liveMode: false })
    setReplayer(repl)

    repl.play()
    repl.addEventListener('ui-update-progress', (event) => {
      if (isRecordFinished(event.payload) && !replay.watchedAt) updateWatched(replayId)
      setCurrentUrlFromReplayer()
    })
    repl.addEventListener('ui-update-current-time', (event) => {
      setCurrentReplayerTimestamp(event.payload)
    })
  }, [replay])

  return (
    <CardWrapper>
      <Card sectioned>
        <CurrentUrl
          replay={replay}
          currentUrlFromReplayer={currentUrlFromReplayer}
          updateFavourite={updateFavourite}
          updateWatched={updateWatched}
          copyToClipboard={copyToClipboard}
          actionsActive={actionsActive}
        />
        <PlayerWrapper>
          <div id="player-container" />
        </PlayerWrapper>
      </Card>
    </CardWrapper>
  )
})

export default Player
