import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { secondaryWhite, primaryBlack, primaryColor } from 'utils/colors'

export const Layout = styled.div`
  padding: 2rem 1rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 0.2rem;
  grid-row-gap: 0.2rem;

  @media screen and (max-width: 1150px) {
    grid-template-columns: repeat(2, 50%);
  }

  @media screen and (max-width: 870px) {
    grid-template-columns: 1fr;
  }
`

export const Item = styled.div`
  padding: 1rem;
  height: 100%;
  border-radius: 3px;
  border-color: transparent;
  transistion: all 0.2s;
  display: grid;
  grid-template-columns: 1fr 3fr;

  &:hover {
    background-color: ${secondaryWhite};
    cursor: pointer;
  }

  @media screen and (max-width: 1150px) {
    grid-template-columns: 1fr 4fr;
  }

  @media screen and (max-width: 870px) {
    grid-template-columns: 1fr 6fr;
  }
`

export const IconWrapper = styled.div`
  height: 5.5rem;
  width: 5.5rem;
  color: ${primaryColor};
  font-size: 2.5rem;
  background-color: #fff;
  border: thin solid ${primaryColor};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemText = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
`

export const ItemTextHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
  font-weight: 600;
`

export const ItemTextContent = styled.span``

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${primaryBlack};
  }
`
