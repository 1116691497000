import React from 'react'
import { useStore } from 'utils/store'
import { bugIcon, cartIcon } from 'utils/global-icon-names'
import Informations from 'components/ReplayDetails/Informations'
import Actions from 'components/ReplayDetails/Actions'
import Cart from 'components/ReplayDetails/Cart'
import EmptyState from 'components/ReplayDetails/EmptyState'
import Errors from 'components/ReplayDetails/Errors'
import Notes from 'components/ReplayDetails/Notes'
import { Card, Tabs } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import ChatLog from 'components/ReplayDetails/ChatLog'
import { showChatLog } from './utils'

const SidePanel = observer(
  ({ tagsActive = true, notesActive = true, chatLogDisabled = true, liveMode = false }) => {
    const {
      detailsReplayStore: {
        replayer,
        item: replay,
        selectedTab,
        setSelectedTab,
        destroyNoteModalActive,
        setDestroyNoteModalActive,
        deleteNote,
        setEditNoteState,
        editNoteState,
        deleteNoteAction,
        editNoteText,
        setEditNoteText,
        updateNote,
        targetEditNote,
        notesActionLoading,
        currentReplayerTimestamp,
        createNoteText,
        setCreateNoteText,
        createNote,
        noteAddLoading,
        createTag,
        deleteTag,
        tagsModalActive,
        setTagsModalActive,
        tagsModalInputValue,
        setTagsModalInputValue,
        sendMessage,
      },
      appStore: {
        currentUser: { site },
      },
    } = useStore()

    const tabs = [
      {
        id: 'Info',
        content: 'Info',
        accessibilityLabel: 'Info',
        component: (
          <Informations
            replay={replay}
            createTag={createTag}
            deleteTag={deleteTag}
            tagsModalActive={tagsModalActive}
            setTagsModalActive={setTagsModalActive}
            tagsModalInputValue={tagsModalInputValue}
            setTagsModalInputValue={setTagsModalInputValue}
            tagsActive={tagsActive}
          />
        ),
        panelID: 'Info',
      },
      {
        id: 'Actions',
        content: 'Actions',
        accessibilityLabel: 'Actions',
        component: <Actions replay={replay} replayer={replayer} />,
        panelID: 'Actions',
      },
      {
        id: 'Cart',
        content: 'Cart',
        accessibilityLabel: 'Cart',
        component:
          replay.attrs.cart?.itemCount > 0 ? (
            <Cart replay={replay} />
          ) : (
            <EmptyState icon={cartIcon} text="Cart is empty" />
          ),
        panelID: 'Cart',
      },
    ]

    if (showChatLog(site, replay, liveMode))
      tabs.push({
        id: 'ChatLog',
        content: 'Chat log',
        accessibilityLabel: 'ChatLog',
        component: (
          <ChatLog replay={replay} sendMessage={sendMessage} chatLogDisabled={chatLogDisabled} />
        ),
        panelID: 'ChatLog',
      })

    tabs.concat([
      {
        id: 'Notes',
        content: 'Notes',
        accessibilityLabel: 'Notes',
        component: (
          <Notes
            destroyNoteModalActive={destroyNoteModalActive}
            setDestroyNoteModalActive={setDestroyNoteModalActive}
            deleteNote={deleteNote}
            replay={replay}
            replayer={replayer}
            setEditNoteState={setEditNoteState}
            editNoteState={editNoteState}
            deleteNoteAction={deleteNoteAction}
            editNoteText={editNoteText}
            setEditNoteText={setEditNoteText}
            updateNote={updateNote}
            targetEditNote={targetEditNote}
            notesActionLoading={notesActionLoading}
            currentReplayerTimestamp={currentReplayerTimestamp}
            createNoteText={createNoteText}
            setCreateNoteText={setCreateNoteText}
            createNote={createNote}
            noteAddLoading={noteAddLoading}
            notesActive={notesActive}
          />
        ),
        panelID: 'Notes',
      },
      {
        id: 'Errors',
        content: 'Errors',
        accessibilityLabel: 'Errors',
        component:
          replay.attrs.errors?.length > 0 ? (
            <Errors replay={replay} />
          ) : (
            <EmptyState icon={bugIcon} text="No errors detected" />
          ),
        panelID: 'Errors',
      },
    ])

    return (
      <Card>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab}>
          {tabs.at(selectedTab).component}
        </Tabs>
      </Card>
    )
  },
)

export default SidePanel
