import agent from 'api/agent'
import moment from 'moment'
import history from 'utils/history'
import { initializeStore } from 'utils/initialize-store'

function setLoading(state) {
  this.loading = state
}

function getItems() {
  this.syncStateToUrlParams()
  if (this.beforeGetItems) this.beforeGetItems()
  this.loading = true
  let appliedFilters = this.filters
  if (this.tabFilters) appliedFilters = appliedFilters.concat(this.tabFilters)
  if (this.highlightFilters) appliedFilters = appliedFilters.concat(this.highlightFilters)

  agent[this.agentName]
    .index(
      this.pageNumber,
      this.searchPhrase,
      this.sortValue,
      appliedFilters.filter((f) => f.value),
    )
    .then(this.getItemsSuccess)
}

function getItemsSuccess(items) {
  this.items = items
  this.loading = false
}

function deleteItems() {
  this.loading = true
  agent[this.agentName]
    .delete(this.selectedItems)
    .then(this.deleteItemsSuccess, this.deleteItemsError)
}

function deleteItemsSuccess() {
  this.selectedItems = []
  this.destroyModalActive = false
  this.getItems()
}

function deleteItemsError(error) {
  this.destroyModalActive = false
  this.loading = false
  this.handleError(error)
}

function updateSearchPhrase(value) {
  this.searchPhrase = value
}

function removeSearchPhrase() {
  this.searchPhrase = ''
  this.getItems()
}

function updateSortValue(value) {
  this.sortValue = value
  this.getItems()
}

function updateSelectedItems(value) {
  this.selectedItems = value
}

function increasePageNumber() {
  this.pageNumber += 1
  this.getItems()
  window.scrollTo(0, 0)
}

function decreasePageNumber() {
  this.pageNumber -= 1
  this.getItems()
  window.scrollTo(0, 0)
}

function updateFilter(filter, value) {
  filter.value = value
  this.getItems()
}

function removeFilter(filter) {
  filter.value = null
  this.getItems()
}

function clearAllFilters() {
  this.filters.forEach((filter) => {
    filter.value = null
  })

  this.getItems()
}

function updateDestroyModalActive(state) {
  this.destroyModalActive = state
}

function syncStateToUrlParams() {
  const params = new URLSearchParams()

  if (this.searchPhrase) params.set('search', this.searchPhrase)
  if (this.sortValue && this.sortValue !== 'created_at DESC') params.set('sort', this.sortValue)
  if (this.pageNumber && this.pageNumber !== 1) params.set('page', this.pageNumber)
  if (this.highlightFilters) params.set('highlights', true)

  this.filters.forEach((filter) => {
    if (filter.value) params.set(filter.field, filter.value)
  })

  if (this.tabFilters) {
    this.tabFilters.forEach((tabFilter) => {
      if (tabFilter.value) params.set(tabFilter.field, tabFilter.value)
    })
  }

  const searchParams = params.toString()
  if (searchParams && history.location.search !== `?${searchParams}`) {
    history.push({ search: searchParams })
  }
}

const filterTypesUsingArray = [
  'range',
  'include',
  'jsonb_equal_array',
  'jsonb_include',
  'jsonb_include_array',
]

const filterDateType = ['range-date']

function parseStateFromQueryString() {
  this.searchPhrase = ''
  this.filters.forEach((filter) => {
    filter.value = null
  })
  this.tabFilters?.forEach((filter) => {
    filter.value = null
  })
  this.highlightFilters = null

  const searchParams = new URLSearchParams(history.location.search)

  if (searchParams.has('search')) this.searchPhrase = searchParams.get('search')
  if (searchParams.has('sort')) this.sortValue = searchParams.get('sort')
  if (searchParams.has('page')) this.pageNumber = parseInt(searchParams.get('page'), 10)

  this.filters
    .filter((f) => searchParams.has(f.field))
    .forEach((filter) => {
      const filterValue = searchParams.get(filter.field)
      if (filterDateType.includes(filter.type)) {
        filter.value = filterValue.split(',')
        filter.value = [moment(filter.value[0]), moment(filter.value[1])]
      } else if (filterTypesUsingArray.includes(filter.type)) {
        filter.value = filterValue.split(',')
      } else {
        filter.value = filterValue
      }
    })

  if (this.tabFilters) {
    this.tabFilters
      .filter((f) => searchParams.has(f.field))
      .forEach((tabFilter) => {
        const filterValue = searchParams.get(tabFilter.field)
        if (filterTypesUsingArray.includes(tabFilter.type)) {
          tabFilter.value = filterValue.split(',')
        } else {
          tabFilter.value = filterValue
        }
      })
  }
}

const resourceAttributes = {
  loading: true,
  items: [],
  selectedItems: [],
  pageNumber: 1,
  searchPhrase: '',
  sortValue: '',
  filters: [],
  destroyModalActive: false,
  highlightFilters: null,
  setLoading,
  getItems,
  getItemsSuccess,
  deleteItems,
  deleteItemsSuccess,
  deleteItemsError,
  updateSearchPhrase,
  removeSearchPhrase,
  updateSortValue,
  updateSelectedItems,
  increasePageNumber,
  decreasePageNumber,
  updateFilter,
  removeFilter,
  clearAllFilters,
  updateDestroyModalActive,
  syncStateToUrlParams,
  parseStateFromQueryString,
}

export const initializeResourceListStore = (store) => {
  initializeStore(store, resourceAttributes)
  store.parseStateFromQueryString()
}
