import React from 'react'
import { Button } from '@shopify/polaris'
import Icon from 'components/Icon'
import { fillPlayIcon, linePlayIcon } from 'utils/global-icon-names'
import { shopifySubduedColor } from 'utils/colors'
import { useStore } from 'utils/store'
import { IconWrapper } from './styles'

const DisabledLink = ({ watchedAt }) => {
  const {
    replaysStore: { showDisabledLinkToast },
  } = useStore()

  return (
    <Button monochrome plain onClick={() => showDisabledLinkToast()}>
      <IconWrapper cursor="pointer" size="3.5rem" color={shopifySubduedColor}>
        <Icon name={watchedAt ? linePlayIcon : fillPlayIcon} />
      </IconWrapper>
    </Button>
  )
}

export default DisabledLink
