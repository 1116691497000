import React from 'react'
import { FormLayout, TextField } from '@shopify/polaris'
import { Form, Formik } from 'formik'
import StyledButton from 'components/StyledButton'
import InlineError from 'components/InlineError'
import { sendEmailIcon } from 'utils/global-icon-names'

const ContactForm = ({ name, email, message, sendEmail, errors }) => (
  <Formik
    enableReinitialize
    initialValues={{
      name,
      email,
      message,
    }}
    onSubmit={(v, actions) =>
      sendEmail(v).then(() => actions.resetForm({ values: { message: '' } }))
    }
  >
    {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
      <Form onSubmit={handleSubmit} autoComplete="off">
        <InlineError message={errors.base} />
        <FormLayout>
          <FormLayout.Group>
            <TextField
              name="name"
              label="Name"
              onChange={(v) => setFieldValue('name', v)}
              value={values.name}
              error={errors.name}
            />
            <TextField
              name="email"
              label="Email"
              onChange={(v) => setFieldValue('email', v)}
              type="email"
              value={values.email}
              error={errors.email}
            />
          </FormLayout.Group>
          <TextField
            name="message"
            label="Message"
            multiline={4}
            onChange={(v) => setFieldValue('message', v)}
            value={values.message}
            error={errors.message}
          />
          <StyledButton
            primary
            submit
            loading={isSubmitting}
            actionName="Send"
            iconName={sendEmailIcon}
          />
        </FormLayout>
      </Form>
    )}
  </Formik>
)

export default ContactForm
