import styled from 'styled-components'

export const ErrorField = styled.div`
  margin-top: 0.4rem;
`

export const LabelWrapper = styled.div`
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.4rem;
`

export const Label = styled.label`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: initial;
  letter-spacing: initial;
  display: block;
  flex: 1 1 auto;
  color: currentColor;
  -webkit-tap-highlight-color: transparent;

  @media screen and (min-width: 40em) {
    font-size: 1.4rem;
  }
`

export const ButtonIconWrapper = styled.div`
  font-size: 1.7rem;
`

export const ButtonStack = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: nowrap;
`
