import styled from 'styled-components'
import {
  primaryWhite,
  primaryGreen,
  primaryColor,
  primaryBlack,
  primaryGrey,
  primaryYellow,
  secondaryWhite,
  shopifyBorderColor,
  secondaryYellow,
  secondaryGrey,
} from 'utils/colors'

export const ItemBadge = styled.div`
  width: fit-content;
  font-size: 0.9rem;
  line-height: normal;
  text-transform: uppercase;
  color: ${primaryWhite};
  background-color: ${(props) => userRoleColor(props.name)};
  padding: 0.4rem 0.4rem;
  border-radius: 5rem;

  &::before {
    content: '${(props) => props.name}';
  }
`

export const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

export const SegmentTitle = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 0.3rem;
  font-size: 1.2rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SegmentSubTitle = styled.h4`
  font-size: 1rem;
`

export const IconWrapper = styled.div`
  font-size: ${(props) => props.size || '1.7rem'};
  color: ${(props) => props.color || primaryYellow};

  &:hover {
    cursor: ${(props) => props.cursor || 'default'};
  }
`

export const ItemSegment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 13rem;
`

export const ItemSegmentHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 20rem;
`

export const GridItemSegment = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  width: 17rem;
`

export const Stack = styled.div`
  display: flex;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
`

export const TitleStack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const Title = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
`

export const Date = styled.span`
  color: ${primaryGrey};
  font-size: 1.2rem;
`

export const Link = styled.a`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${primaryBlack};
  }

  &:hover {
    text-decoration: underline;
  }
`

export const TitleIconWrapper = styled.div`
  font-size: 2.3rem;
  color: ${(props) => props.color || primaryBlack};
`

export const LocationTime = styled.div`
  white-space: nowrap;
`

export const DetailsPageLayout = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr;
  grid-template-rows: 1fr;
  gap: 1.6rem;

  @media screen and (max-width: 1350px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr fit-content;
    justify-content: stretch;
    justify-items: stretch;
  }
`

const userRoleColor = (name) => {
  switch (name) {
    case 'collaborator':
      return primaryGreen
    case 'owner':
      return primaryColor
    default:
      return primaryGrey
  }
}

export const ButtonIconWrapper = styled.div`
  font-size: 2rem;
`

export const CurrentUrlInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  border: thin solid ${shopifyBorderColor};
  color: ${primaryGrey};
  border-radius: 0.5rem;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  width: 100%;
  padding: 0.5rem 1.2rem;
`

export const UrlText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 65rem;

  @media screen and (max-width: 1350px) {
    max-width: 60rem;
  }

  @media screen and (max-width: 1300px) {
    max-width: 53rem;
  }

  @media screen and (max-width: 1250px) {
    max-width: 45rem;
  }

  @media screen and (max-width: 1075px) {
    max-width: 40rem;
  }

  @media screen and (max-width: 985px) {
    max-width: 35rem;
  }

  @media screen and (max-width: 915px) {
    max-width: 30rem;
  }

  @media screen and (max-width: 835px) {
    max-width: 28rem;
  }

  @media screen and (max-width: 805px) {
    max-width: 50rem;
  }

  @media screen and (max-width: 765px) {
    max-width: 45rem;
  }

  @media screen and (max-width: 690px) {
    max-width: 40rem;
  }

  @media screen and (max-width: 645px) {
    max-width: 35rem;
  }

  @media screen and (max-width: 595px) {
    max-width: 30rem;
  }

  @media screen and (max-width: 540px) {
    max-width: 25rem;
  }

  @media screen and (max-width: 445px) {
    max-width: 20rem;
  }

  @media screen and (max-width: 400px) {
    max-width: 15rem;
  }

  @media screen and (min-width: 1500px) {
    max-width: 70rem;
  }

  @media screen and (min-width: 1600px) {
    max-width: 75rem;
  }

  @media screen and (min-width: 1700px) {
    max-width: 80rem;
  }

  @media screen and (min-width: 1800px) {
    max-width: 85rem;
  }

  @media screen and (min-width: 1900px) {
    max-width: 90rem;
  }

  @media screen and (min-width: 2000px) {
    max-width: 95rem;
  }
`

export const UrlActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  color: ${secondaryWhite};
  transition: all 0.2s;

  &:hover {
    color: ${primaryGrey};
  }
`

export const LinksItem = styled.div``

export const LinkItemStack = styled.div`
  position: relative;
  display: flex;
  gap: 0.5rem;
`

export const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: min-content max-content;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  gap: 0.6rem;
  justify-items: start;
`

export const ListActionButton = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${secondaryGrey};
  transition: all 0.2s;

  &:hover {
    color: ${primaryGrey};
    display: flex;
  }

  ${LinksContainer}:hover & {
    display: flex;
  }
`

export const ListActionButtonsContainer = styled.div`
  position: absolute;
  right: -4rem;
  display: flex;
  gap: 0.5rem;
`

export const LinkContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  cursor: pointer;
  color: ${(props) => props.color || secondaryYellow};
  transition: all 0.2s;

  &:hover {
    color: ${(props) => props.hoverColor || primaryYellow};
  }
`

export const StyledStack = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 1rem;
`

export const StyledVerticalStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const LinksTitle = styled.div`
  text-transform: uppercase;
  font-size: 1rem;
  color: ${primaryGrey};
`

export const PlayerWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  transform: scale(0.96);
  margin-bottom: -3rem;

  @media screen and (max-width: 1390px) {
    transform: scale(0.93);
    margin-bottom: -4rem;
    margin-top: -1rem;
  }

  @media screen and (max-width: 1340px) {
    transform: scale(0.9);
    margin-bottom: -5rem;
    margin-top: -1rem;
  }

  @media screen and (max-width: 1300px) {
    transform: scale(0.87);
    margin-bottom: -6rem;
    margin-top: -1.5rem;
  }

  @media screen and (max-width: 1260px) {
    transform: scale(0.84);
    margin-bottom: -7rem;
    margin-top: -2rem;
  }

  @media screen and (max-width: 1220px) {
    transform: scale(0.8);
    margin-bottom: -8rem;
    margin-top: -1rem;
  }

  @media screen and (max-width: 1160px) {
    transform: scale(1);
    margin-bottom: 0;
    margin-top: 1.5rem;
  }

  @media screen and (max-width: 1100px) {
    transform: scale(0.95);
    margin-bottom: -0.5rem;
    margin-top: 1rem;
  }

  @media screen and (max-width: 1050px) {
    transform: scale(0.9);
    margin-bottom: -1rem;
    margin-top: 0;
  }

  @media screen and (max-width: 1000px) {
    transform: scale(0.85);
    margin-bottom: -2rem;
    margin-top: -2rem;
  }

  @media screen and (max-width: 950px) {
    transform: scale(0.8);
    margin-bottom: -3.5rem;
    margin-top: -4rem;
  }

  @media screen and (max-width: 900px) {
    transform: scale(0.75);
    margin-bottom: -5rem;
    margin-top: -5rem;
  }

  @media screen and (max-width: 850px) {
    transform: scale(0.7);
    margin-bottom: -7rem;
    margin-top: -7rem;
  }

  @media screen and (max-width: 800px) {
    transform: scale(0.65);
    margin-bottom: -9rem;
    margin-top: -9rem;
  }

  @media screen and (max-width: 720px) {
    transform: scale(0.6);
    margin-bottom: -11rem;
    margin-top: -11rem;
  }

  @media screen and (max-width: 665px) {
    transform: scale(0.55);
    margin-bottom: -13rem;
    margin-top: -13rem;
  }

  @media screen and (max-width: 620px) {
    transform: scale(0.5);
    margin-bottom: -15rem;
    margin-top: -15rem;
  }

  @media screen and (max-width: 570px) {
    transform: scale(0.45);
    margin-bottom: -15rem;
    margin-top: -17rem;
  }

  @media screen and (max-width: 520px) {
    transform: scale(0.4);
    margin-bottom: -15rem;
    margin-top: -15rem;
  }

  @media screen and (min-width: 1480px) {
    transform: scale(1.03);
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 1550px) {
    transform: scale(1.06);
    margin-top: 3rem;
  }

  @media screen and (min-width: 1600px) {
    transform: scale(1.09);
    margin-top: 4rem;
  }

  @media screen and (min-width: 1650px) {
    transform: scale(1.13);
    margin-top: 6rem;
    margin-bottom: 3rem;
  }

  @media screen and (min-width: 1700px) {
    transform: scale(1.16);
    margin-top: 7rem;
    margin-bottom: 4rem;
  }

  @media screen and (min-width: 1750px) {
    transform: scale(1.19);
    margin-top: 8rem;
    margin-bottom: 5rem;
  }

  @media screen and (min-width: 1800px) {
    transform: scale(1.23);
    margin-top: 9rem;
    margin-bottom: 6rem;
  }

  @media screen and (min-width: 1850px) {
    transform: scale(1.26);
    margin-top: 10rem;
    margin-bottom: 7rem;
  }

  @media screen and (min-width: 1900px) {
    transform: scale(1.3);
    margin-top: 11rem;
    margin-bottom: 8rem;
  }

  @media screen and (min-width: 1950px) {
    transform: scale(1.34);
    margin-top: 12rem;
    margin-bottom: 9.5rem;
  }

  @media screen and (min-width: 2000px) {
    transform: scale(1.38);
    margin-top: 14rem;
    margin-bottom: 10.5rem;
  }

  @media screen and (min-width: 2050px) {
    transform: scale(1.42);
    margin-top: 16rem;
    margin-bottom: 12rem;
  }

  @media screen and (min-width: 2100px) {
    transform: scale(1.46);
    margin-top: 18rem;
    margin-bottom: 14rem;
  }

  @media screen and (min-width: 2150px) {
    transform: scale(1.5);
    margin-top: 20rem;
    margin-bottom: 16rem;
  }

  @media screen and (min-width: 2200px) {
    transform: scale(1.54);
    margin-bottom: 18rem;
  }

  @media screen and (min-width: 2250px) {
    transform: scale(1.58);
    margin-top: 21rem;
    margin-bottom: 20rem;
  }

  @media screen and (min-width: 2300px) {
    transform: scale(1.62);
    margin-top: 23rem;
    margin-bottom: 21rem;
  }

  @media screen and (min-width: 2350px) {
    transform: scale(1.66);
    margin-top: 24rem;
    margin-bottom: 21rem;
  }

  @media screen and (min-width: 2400px) {
    transform: scale(1.7);
    margin-top: 25rem;
    margin-bottom: 22rem;
  }

  @media screen and (min-width: 2450px) {
    transform: scale(1.73);
    margin-top: 26rem;
    margin-bottom: 23rem;
  }

  @media screen and (min-width: 2500px) {
    transform: scale(1.76);
    margin-top: 27rem;
    margin-bottom: 24rem;
  }

  @media screen and (min-width: 2550px) {
    transform: scale(1.79);
    margin-top: 28rem;
    margin-bottom: 25rem;
  }
`

export const LinksUrl = styled.div`
  max-width: 27rem;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 950px) {
    max-width: 20rem;
  }

  @media screen and (max-width: 880px) {
    max-width: 15rem;
  }

  @media screen and (max-width: 830px) {
    max-width: 10rem;
  }

  @media screen and (min-width: 1200px) {
    max-width: 35rem;
  }

  @media screen and (min-width: 1300px) {
    max-width: 40rem;
  }

  @media screen and (min-width: 1400px) {
    max-width: 45rem;
  }

  @media screen and (min-width: 1500px) {
    max-width: 50rem;
  }
`

export const DetailsListItemContent = styled.div`
  display: flex;
`

export const ShareLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  border: thin solid ${shopifyBorderColor};
  border-radius: 0.5rem;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: 400;
  width: 100%;
  padding: 0.5rem 1.2rem;
`

export const ShareLinkWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90rem;
`

export const CopyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  color: ${secondaryWhite};
  transition: all 0.2s;

  &:hover {
    color: ${primaryGrey};
  }
`

export const ReplayerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.6rem;
`

export const PlayIconWrapper = styled.div`
  font-size: ${(props) => props.size || '1.7rem'};
  color: ${(props) => props.color || primaryGreen};

  &:hover {
    cursor: pointer;
  }
`

export const Spacer = styled.div`
  display: inline-block;
  width: 10px;
`

export const EmptyStateIconWrapper = styled.div`
  font-size: 8rem;
`

export const DetailsContainer = styled.div`
  display: grid;
  gap: 1.6rem;
  grid-template-columns: 75% calc(25% - 1.6rem);
  grid-template-rows: min-content;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1160px) {
    grid-template-columns: 100%;
    grid-template-rows: min-content max-content;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
