import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import { Layout, Page, Select, Stack, TextContainer } from '@shopify/polaris'
import buildRoute from 'utils/build-route'
import { useStore } from 'utils/store'
import Counters from 'components/Counters'
import PageSpinner from 'components/PageSpinner'
import AnalyticsStore from './store'
import { selectPeriodOptions } from './config'
import Charts from './Charts'

const AnalyticsPage = observer(() => {
  const {
    analyticsStore: {
      timePeriod,
      setTimePeriod,
      dates,
      setFirstDate,
      setSecondDate,
      focus,
      setFocus,
      resources,
      getAnalytics,
      loading,
      selectedDataType,
    },
  } = useStore()

  useEffect(() => {
    getAnalytics()
  }, [timePeriod, selectedDataType, dates[0], dates[1]])

  if (loading) return <PageSpinner />

  return (
    <Page
      title="Analytics"
      primaryAction={
        <Stack alignment="center">
          <TextContainer>Select time period</TextContainer>
          <Select options={selectPeriodOptions} onChange={setTimePeriod} value={timePeriod} />
          {timePeriod === 'custom' && (
            <DateRangePicker
              startDateId="starDateId"
              endDateId="endDateId"
              startDate={dates[0]}
              endDate={dates[1]}
              onDatesChange={({ startDate, endDate }) => {
                setFirstDate(startDate)
                setSecondDate(endDate)
              }}
              focusedInput={focus}
              onFocusChange={(focusedInput) => {
                setFocus(focusedInput)
              }}
              isOutsideRange={() => false}
              showDefaultInputIcon
              small
              keepOpenOnDateSelect
              minimumNights={1}
              hideKeyboardShortcutsPanel
            />
          )}
        </Stack>
      }
      fullWidth
    >
      <Layout>
        <Layout.Section>
          <Counters resources={resources} />
        </Layout.Section>
        <Layout.Section>
          <Charts />
        </Layout.Section>
      </Layout>
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    analyticsStore: new AnalyticsStore(),
  }),
})(AnalyticsPage)
