import React from 'react'
import { observer } from 'mobx-react-lite'
import { Banner, Layout } from '@shopify/polaris'
import { useStore } from 'utils/store'
import { showSessionBanner } from './utils'

const RestoreSessionBanner = observer(({ lastSessionId }) => {
  const {
    appStore: {
      currentUser: {
        site: { recordingEnabled },
      },
    },
    detailsReplayStore: {
      liveUniqueId,
      setLiveUniqueId,
      activeSessionBanner,
      setActiveSessionBanner,
      restoreSession,
    },
  } = useStore()

  if (!recordingEnabled) return null

  return (
    showSessionBanner(lastSessionId, liveUniqueId, activeSessionBanner) && (
      <Layout.Section>
        <Banner
          title="Do you want to restore your previous live session?"
          action={{
            content: 'Restore',
            onAction: () => {
              setLiveUniqueId(lastSessionId)
              restoreSession(lastSessionId)
            },
          }}
          status="warning"
          onDismiss={() => setActiveSessionBanner(false)}
        >
          <p>Just click on the button below and restore your connection.</p>
        </Banner>
      </Layout.Section>
    )
  )
})

export default RestoreSessionBanner
