import React from 'react'
import { Banner, Page } from '@shopify/polaris'

const OverlimitPage = () => (
  <Page>
    <Banner title="Overlimit users" status="warning">
      <p>
        The maximum number of users has been reached. In order to increase this limit, change
        billing plan.
      </p>
    </Banner>
  </Page>
)

export default OverlimitPage
