export const rollbarIdentify = (currentUser) => {
  if (!window.Rollbar) return

  window.Rollbar.configure({
    payload: {
      person: {
        username: currentUser.name,
        ...currentUser,
      },
    },
  })
}
