import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, TextContainer } from '@shopify/polaris'
import Icon from 'components/Icon'
import { ButtonIconWrapper, ButtonStack } from 'utils/global-styles'

const StyledButton = observer((props) => (
  <Button {...props}>
    <ButtonStack>
      {!props.loading && (
        <ButtonIconWrapper>
          <Icon name={props.iconName} />
        </ButtonIconWrapper>
      )}
      <TextContainer>{props.actionName}</TextContainer>
    </ButtonStack>
  </Button>
))

export default StyledButton
