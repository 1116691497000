export const initializeStore = (store, attributes) => {
  Object.keys(attributes).forEach((key) => {
    if (!store[key]) {
      const attr = attributes[key]
      if (typeof attr === 'function') {
        store[key] = attr.bind(store)
      } else {
        store[key] = attr
      }
    }
  })
}
