import { makeAutoObservable } from 'mobx'
import { initializeResourceListStore } from 'utils/resource-list-store'

class ListStore {
  agentName = 'Users'
  sortValue = 'email ASC'
  filters = [
    { name: 'First name', field: 'first_name', type: 'contain', value: null },
    { name: 'Last name', field: 'last_name', type: 'contain', value: null },
    { name: 'Sign in count', field: 'sign_in_count', type: 'range', value: null },
    { name: 'Api key', field: 'api_key', type: 'equal', value: null },
    {
      name: 'Role',
      field: 'role',
      type: 'include',
      value: null,
      choices: [
        {
          label: 'Owner',
          value: 'owner',
        },
        {
          label: 'Collaborator',
          value: 'collaborator',
        },
      ],
    },
  ]

  constructor() {
    initializeResourceListStore(this)
    makeAutoObservable(this)
  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    this.showNotification('Coppied.')
  }
}

export default ListStore
