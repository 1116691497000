import React, { useCallback } from 'react'
import lodash from 'lodash'
import { Formik } from 'formik'
import { TopBar } from '@shopify/polaris'

const SearchField = ({ onChange, onFocus }) => {
  const fetchSearch = useCallback(
    lodash.debounce((search) => {
      onChange(search)
    }, 500),
    [],
  )

  return (
    <Formik initialValues={{ searchValue: '' }}>
      {({ values, setFieldValue }) => (
        <TopBar.SearchField
          onChange={(v) => {
            setFieldValue('searchValue', v)
            fetchSearch(v)
          }}
          value={values.searchValue}
          placeholder="Search"
          onFocus={onFocus}
        />
      )}
    </Formik>
  )
}

export default SearchField
