import React from 'react'
import { Layout, Page } from '@shopify/polaris'
import buildRoute from 'utils/build-route'
import GeneralStore from './store'
import EditSiteForm from './EditSiteForm'

const GeneralPage = () => (
  <Page title="General settings" breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
    <Layout>
      <EditSiteForm />
    </Layout>
  </Page>
)

export default buildRoute({
  stores: () => ({
    generalStore: new GeneralStore(),
  }),
})(GeneralPage)
