import React from 'react'
import moment from 'moment'
import DOMPurify from 'dompurify'
import { Modal } from '@shopify/polaris'
import { defaultTextValidation } from 'utils/dompurify-validation-schema'
import { useStore } from 'utils/store'
import { NotificationBody, NotificationDate, NotificationHeader, NotificationTag } from './styles'

const NotificationModal = ({ id, title, body, createdAt, type }) => {
  const {
    topbarNotificationsStore: { setNotificationAsRead },
  } = useStore()

  return (
    <Modal
      open
      title={title}
      onClose={() => setNotificationAsRead(id)}
      primaryAction={{
        content: 'Close',
        onAction: () => setNotificationAsRead(id),
      }}
    >
      <Modal.Section>
        <NotificationHeader>
          <NotificationTag type={type} />
          <NotificationDate>{moment(createdAt).startOf('hour').fromNow()}</NotificationDate>
        </NotificationHeader>
        <NotificationBody
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body, defaultTextValidation) }}
        />
      </Modal.Section>
    </Modal>
  )
}

export default NotificationModal
