import styled from 'styled-components'
import {
  notificationBadgeColor,
  primaryBlack,
  primaryColor,
  primaryGrey,
  primaryWhite,
} from 'utils/colors'
import { tagColor, tagName } from './utils'

export const NotificationIcon = styled.div`
  font-size: 22px;
  color: ${primaryGrey};
  transition: all 0.2s;

  &:hover {
    color: ${primaryBlack};
  }
`

export const NotificationBox = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;
  }
`

export const NotificationBadge = styled.div`
  position: absolute;
  padding: 0.6rem;
  top: -0.1rem;
  right: -0.1rem;
  color: ${primaryWhite};
  background-color: ${notificationBadgeColor};
  font-size: 0.9rem;
  height: 1rem;
  width: 1rem;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NotificationContainer = styled.div``

export const NotificationTitle = styled.span`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
`

export const NotificationBody = styled.div``

export const NotificationDate = styled.span`
  color: ${primaryGrey};
  font-size: 1rem;
`

export const NotificationContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const UnreadNotificationIcon = styled.div`
  position: absolute;
  top: -0.7rem;
  right: -1.2rem;
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 100px;
  background-color: ${primaryColor};
  display: ${(props) => (props.display ? 'block' : 'none')};
`

export const NotificationHeader = styled.div`
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 190px) {
    flex-direction: column;
  }
`

export const NotificationTag = styled.div`
  color: ${primaryWhite};
  background-color: ${(props) => tagColor(props.type)};
  padding: 0.5rem 0.5rem;
  width: fit-content;
  font-size: 0.8rem;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border-radius: 2rem;

  &::before {
    content: '${(props) => tagName(props.type)}';
  }
`
