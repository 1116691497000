import React from 'react'
import buildRoute from 'utils/build-route'
import DiscountCodeInput from './DiscountCodeInput'
import FreeTrial from './FreeTrial'
import FreeTrialModal from './FreeTrialModal'
import Plans from './Plans'
import { PageLayout } from './styles'
import PlansPageStore from './store'
import DowngradeConfirmationModal from './DowngradeConfirmationModal'

const PlansComponents = () => (
  <>
    <PageLayout>
      <FreeTrial />
      <Plans />
    </PageLayout>
    <DiscountCodeInput />
    <FreeTrialModal />
    <DowngradeConfirmationModal />
  </>
)

export default buildRoute({
  stores: () => ({
    plansPageStore: new PlansPageStore(),
  }),
})(PlansComponents)
