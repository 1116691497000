export const defaultTextValidation = {
  ALLOWED_TAGS: [
    'ul',
    'u',
    'svg',
    'small',
    's',
    'q',
    'pre',
    'li',
    'img',
    'font',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'i',
    'strong',
    'br',
    'center',
  ],
  ALLOWED_ATTR: ['style'],
}
