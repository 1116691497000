import React from 'react'
import { observer } from 'mobx-react-lite'
import { Layout, Card, FormLayout, TextField } from '@shopify/polaris'
import { Formik, Form } from 'formik'
import { useStore } from 'utils/store'
import { saveIcon } from 'utils/global-icon-names'
import TimezoneSelector from 'components/Selector/TimezoneSelector'
import ColorPicker from 'components/ColorPicker'
import FileUploader from 'components/FileUploader'
import InlineError from 'components/InlineError'
import StyledButton from 'components/StyledButton'

const EditSiteForm = observer(() => {
  const {
    appStore: { currentUser },
    generalStore: { updateSite, errors },
  } = useStore()

  return (
    <Layout.AnnotatedSection title="Store settings" description="Update your store settings.">
      <Card sectioned>
        <Formik
          enableReinitialize
          initialValues={{
            ...currentUser.site,
          }}
          onSubmit={(v) => updateSite(v)}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <InlineError message={errors.base} />
              <FormLayout>
                <TextField
                  name="name"
                  label="Name"
                  value={values.name}
                  onChange={(v) => setFieldValue('name', v)}
                  error={errors.name}
                />
                <TimezoneSelector
                  selected={values.timezone}
                  onChange={(v) => setFieldValue('timezone', v)}
                  error={errors.timezone}
                />
                <ColorPicker
                  label="Main color"
                  color={values.mainColor}
                  onChange={(v) => setFieldValue('mainColor', v)}
                  error={errors.mainColor}
                />
                <FileUploader
                  label="Logo"
                  file={values.logo}
                  onDrop={(v) => setFieldValue('logo', v)}
                  error={errors.logo}
                />
                <StyledButton
                  primary
                  submit
                  loading={isSubmitting}
                  iconName={saveIcon}
                  actionName="Save"
                />
              </FormLayout>
            </Form>
          )}
        </Formik>
      </Card>
    </Layout.AnnotatedSection>
  )
})

export default EditSiteForm
