export const HIDDEN_SIDEBAR_LOCATIONS = [
  '/plans',
  '/install',
  '/admin/overlimit',
  '/admin/login.+',
  '/public.+',
  '/recordings/.+',
  '/+',
]

export const HIDDEN_TOPBAR_LOCATIONS = ['/admin/login.+', '/public.+']

export const PUBLIC_PAGE_LOCATIONS = ['/admin/login.+', '/public.+']

export const showSidebar = (location) =>
  !HIDDEN_SIDEBAR_LOCATIONS.some((l) => new RegExp(l).test(location.pathname))

export const showTopbar = (location) =>
  !HIDDEN_TOPBAR_LOCATIONS.some((l) => new RegExp(l).test(location.pathname))

export const isPublicPage = (location) =>
  PUBLIC_PAGE_LOCATIONS.some((l) => new RegExp(l).test(location.pathname))
