import React from 'react'
import { Banner, Modal, TextContainer } from '@shopify/polaris'

const DelationConfirmationModal = ({
  modalActive,
  updateModalActive,
  deleteAction,
  resourceName,
}) => (
  <Modal
    open={modalActive}
    onClose={() => updateModalActive(false)}
    title={`Are you sure to delete ${resourceName}?`}
    secondaryActions={[
      {
        content: 'Delete',
        destructive: true,
        onAction: () => deleteAction(),
      },
      {
        content: 'Cancel',
        onAction: () => updateModalActive(false),
      },
    ]}
  >
    <Modal.Section>
      <TextContainer>
        <Banner title="Some data could be lost" status="critical">
          <p>
            Deletion is <strong>irreversible</strong> operation.
          </p>
        </Banner>
        <p>Are you sure you want to delete {resourceName} permanently?</p>
      </TextContainer>
    </Modal.Section>
  </Modal>
)

export default DelationConfirmationModal
