import React from 'react'
import { Redirect } from 'react-router-dom'
import { CalloutCard, Layout, Page } from '@shopify/polaris'
import {
  analyticsIcon,
  chatIcon,
  liveIcon,
  recordingIcon,
  sendEmailIcon,
  usersIcon,
} from 'utils/global-icon-names'
import { isOnboardingFinished } from 'utils/global-functions'
import { useStore } from 'utils/store'
import {
  criticalColor,
  primaryColor,
  primaryGreen,
  primaryViolet,
  primaryYellow,
} from 'utils/colors'
import Logo from 'images/logo.png'
import FeatureCard from './FeatureCard'

const WelcomePage = () => {
  const {
    appStore: { currentUser, setOnboardingFinished, loading },
  } = useStore()

  if (isOnboardingFinished(currentUser.site.onboardingStatus)) return <Redirect to="/" />

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Layout>
            <Layout.Section>
              <CalloutCard
                illustration={Logo}
                title="Welcome to ElevateToolbox: Replays!"
                primaryAction={{
                  content: 'Proceed to application',
                  loading,
                  onAction: () => setOnboardingFinished(),
                }}
              >
                Explore our features like live sessions, email notifications etc.
              </CalloutCard>
            </Layout.Section>
          </Layout>
        </Layout.Section>
        <Layout.Section>
          <Layout>
            <Layout.Section oneHalf>
              <FeatureCard
                iconName={recordingIcon}
                iconColor={primaryGreen}
                actionName="Manage"
                actionUrl="/"
                heading="Recordings"
                subHeading="Segregate recordings as you need"
                description="Take advantage of our impressive list of filters. Save your combination of filters as segments."
              />
            </Layout.Section>
            <Layout.Section oneHalf>
              <FeatureCard
                iconName={analyticsIcon}
                iconColor={primaryViolet}
                actionName="Analyze"
                actionUrl="/analytics"
                heading="Analytics"
                subHeading="Analyze sessions data"
                description="Compare recordings based on the relation between added-to-cart events and finalized orders."
              />
            </Layout.Section>
            <Layout.Section oneHalf>
              <FeatureCard
                iconName={liveIcon}
                iconColor={criticalColor}
                actionName="Check it out"
                actionUrl="/live/details"
                heading="Live session"
                subHeading="Establish a live session with your customers"
                description="You are able to connect with every online customer. All you need to do is to choose a session from the 
                  list or you can send a unique link and establish a connection with your customer immediately."
              />
            </Layout.Section>
            <Layout.Section oneHalf>
              <FeatureCard
                iconName={chatIcon}
                actionName="Setup conversation"
                actionUrl="/settings/replays-settings"
                heading="Live conversation"
                subHeading="Contact with customers during a live session"
                description="During watching a live session, you can contact your customer in real-time. Currently, we support only two ways of conversation, 
                  by our toast and by external app - Tidio."
              />
            </Layout.Section>
            <Layout.Section oneHalf>
              <FeatureCard
                iconName={usersIcon}
                iconColor={primaryYellow}
                actionName="Create users"
                actionUrl="/settings/users/create"
                heading="Users"
                subHeading="Authorize anyone"
                description="Give access to your resources to anyone you want. Just create a user and share the login link."
              />
            </Layout.Section>
            <Layout.Section oneHalf>
              <FeatureCard
                iconName={sendEmailIcon}
                iconColor={primaryColor}
                actionName="Turn on"
                actionUrl="/settings/replays-settings"
                heading="Email notifications"
                subHeading="Get notification about vital sessions"
                description="Don't miss any essential sessions - turn on email notifications. You can fully customize event triggers."
              />
            </Layout.Section>
          </Layout>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default WelcomePage
