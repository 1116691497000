import React from 'react'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import { ChoiceList, RangeSlider, TextField } from '@shopify/polaris'
import DebouncedField from 'components/DebouncedField'
import CountrySelector from 'components/CountrySelector'

export const filterComponent = (filter, updateFilter) => {
  const transformedFilterTypeName = filter.type
    .replace('jsonb_', '')
    .replace('_array', '')
    .replace('_nested', '')

  if (transformedFilterTypeName === 'equal-country')
    return (
      <CountrySelector
        selectedOptions={filter.value}
        onSelect={(value) => updateFilter(filter, value)}
      />
    )
  if (transformedFilterTypeName === 'range-date')
    return (
      <DateRangePicker
        startDateId="startDateId"
        endDateId="endDateId"
        startDate={filter.value ? filter.value[0] : filter.value}
        endDate={filter.value ? filter.value[1] : filter.value}
        onDatesChange={({ startDate, endDate }) => {
          updateFilter(filter, [
            startDate ? startDate.set({ h: 0, m: 0, s: 0 }) : startDate,
            endDate ? endDate.set({ h: 23, m: 59, s: 59 }) : endDate,
          ])
        }}
        focusedInput={filter.focused}
        onFocusChange={(focusedInput) => {
          filter.onFocusedChange(focusedInput)
        }}
        isOutsideRange={() => false}
        showDefaultInputIcon
        keepOpenOnDateSelect
        numberOfMonths={1}
        anchorDirection="left"
        minimumNights={1}
        small
        hideKeyboardShortcutsPanel
      />
    )

  if (transformedFilterTypeName === 'range')
    return (
      <DebouncedField onChange={(v) => updateFilter(filter, v)} value={filter.value}>
        {(val, onChange) => (
          <RangeSlider
            output
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            value={val || [0, 100]}
          />
        )}
      </DebouncedField>
    )

  if (
    transformedFilterTypeName === 'include' ||
    transformedFilterTypeName === 'not_exists' ||
    transformedFilterTypeName === 'exists'
  )
    return (
      <ChoiceList
        allowMultiple
        choices={filter.choices}
        onChange={(v) => updateFilter(filter, v)}
        selected={filter.value || []}
      />
    )

  return (
    <DebouncedField onChange={(v) => updateFilter(filter, v)} value={filter.value}>
      {(val, onChange) => (
        <TextField label={transformedFilterTypeName} onChange={onChange} value={val || ''} />
      )}
    </DebouncedField>
  )
}

export const filterValueLabel = (filter) => {
  if (filter.type === 'range') return `${filter.value[0]} between ${filter.value[1]}`

  if (filter.type === 'range-date')
    return `${moment(filter.value[0]).format('L')} between ${moment(filter.value[1]).format('L')}`

  return filter.value
}

export const getSelectedItemsCount = (allResourcesSelected, selectedItems) => {
  if (!selectedItems) return null

  return allResourcesSelected ? 'All' : selectedItems.length
}
