import React from 'react'
import { observer } from 'mobx-react-lite'
import { Banner, Card, Layout, TextStyle } from '@shopify/polaris'
import { copyIcon } from 'utils/global-icon-names'
import { useStore } from 'utils/store'
import Icon from 'components/Icon'
import { CopyButton, ShareLinkContainer, StyledVerticalStack } from './styles'

const ShareableLink = observer(({ shareableLink, copyToClipboard }) => {
  const {
    detailsReplayStore: { liveSessionRunning },
    appStore: { currentUser },
  } = useStore()

  if (liveSessionRunning) return null

  return (
    <Layout.AnnotatedSection
      title="Share the link"
      description="You can easily established a live session with your customer. Just copy
                the link and share it."
    >
      {currentUser.site.numberOfOverlimitReplays ? (
        <Banner
          title="Limit of replays has been reached"
          action={{ content: 'Upgrade your billing plan', url: '/settings/billing' }}
          status="critical"
        >
          You have reached the limit of replays. To get access to live sessions, please upgrade your
          billing plan.
        </Banner>
      ) : (
        <Card sectioned>
          <StyledVerticalStack>
            <TextStyle>Shareable live session link:</TextStyle>
            <ShareLinkContainer>
              {shareableLink}
              <CopyButton
                role="button"
                tabIndex={0}
                onClick={() => copyToClipboard(shareableLink)}
                onKeyDown={() => copyToClipboard(shareableLink)}
              >
                <Icon name={copyIcon} />
              </CopyButton>
            </ShareLinkContainer>
          </StyledVerticalStack>
        </Card>
      )}
    </Layout.AnnotatedSection>
  )
})

export default ShareableLink
