import React from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import { Avatar, Card, Stack, TextContainer, Tooltip } from '@shopify/polaris'

const ChatList = observer(({ replay }) =>
  replay.chatLog
    .slice()
    .reverse()
    .map((log) => (
      <Card.Section key={log.id} subdued title={moment(log.createdAt).startOf('second').fromNow()}>
        <Stack alignment="center">
          <Tooltip content={log.userName}>
            <Avatar
              size="small"
              name={log.userName}
              initials={log.userName?.at(0)}
              customer={log.userName === 'Customer'}
            />
          </Tooltip>
          <TextContainer>{log.content}</TextContainer>
        </Stack>
      </Card.Section>
    )),
)

export default ChatList
