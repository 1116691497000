import styled from 'styled-components'
import { primaryWhite, secondaryWhite, shopifyBorderColor } from 'utils/colors'

export const Layout = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
`

export const Header = styled.h1`
  font-size: 5rem;
  line-height: 1;
`

export const Body = styled.p`
  font-size: 2rem;
  max-width: 100rem;
  line-height: 2.5rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3.5rem;
`

export const Button = styled.button`
  all: unset;
  background-color: ${primaryWhite};
  padding: 0.5rem 1rem;
  border: thin solid ${shopifyBorderColor};
  border-radius: 0.4rem;
  transition: 0.2s all;

  &:hover {
    cursor: pointer;
    background-color: ${secondaryWhite};
  }
`
