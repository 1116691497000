import React from 'react'
import { Tooltip, Tag, Stack, TextContainer } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import ReactCountryFlag from 'react-country-flag'
import Icon from 'components/Icon'
import StyledButton from 'components/StyledButton'
import BrowserIcon from 'components/BrandIcons/BrowserIcon'
import SystemIcon from 'components/BrandIcons/SystemIcon'
import { isUndefinedObject, transformDateToDefaultFormat } from 'utils/global-functions'
import {
  bugIcon,
  calendarIcon,
  cartIcon,
  desktopIcon,
  stackIcon,
  userTagIcon,
  flagIcon,
  editIcon,
} from 'utils/global-icon-names'
import {
  DetailsList,
  DetailsListItem,
  LocalizationWrapper,
  TagsContainer,
  TagsWrapper,
  TitleIconWrapper,
  ItemListWrapper,
  DeviceTypeWrapper,
  DetailsBox,
} from './styles'
import TagsModal from './TagsModal'

const Informations = observer(
  ({
    replay: { attrs, createdAt, actions, localization, tags, totalCountOfCustomerRecordings },
    createTag,
    deleteTag,
    tagsModalActive,
    setTagsModalActive,
    tagsModalInputValue,
    setTagsModalInputValue,
    tagsActive = true,
  }) => (
    <DetailsBox padding="2rem">
      <Stack vertical>
        <DetailsList>
          <DetailsListItem>
            <TitleIconWrapper>
              <Icon name={userTagIcon} />
            </TitleIconWrapper>
            <Tooltip content={attrs?.userUuid}>
              <ItemListWrapper>{attrs?.userUuid || 'No data'}</ItemListWrapper>
            </Tooltip>
            <Tooltip content="User recordings">
              <TextContainer>
                {totalCountOfCustomerRecordings > 1 && ` (${totalCountOfCustomerRecordings})`}
              </TextContainer>
            </Tooltip>
          </DetailsListItem>
          <DetailsListItem>
            {localization ? (
              <>
                <Tooltip content={localization.country}>
                  <TitleIconWrapper>
                    {localization.countryCode ? (
                      <ReactCountryFlag
                        className="flag"
                        countryCode={localization.countryCode}
                        aria-label={localization.country}
                      />
                    ) : (
                      <Icon name={flagIcon} />
                    )}
                  </TitleIconWrapper>
                </Tooltip>
                <Tooltip content="City, Region">
                  <LocalizationWrapper>
                    {localization.city || localization.region
                      ? `${localization.city || 'No data'}, ${localization.region || 'No data'}`
                      : 'No data'}
                  </LocalizationWrapper>
                </Tooltip>
              </>
            ) : (
              <>
                <TitleIconWrapper>
                  <Icon name={flagIcon} />
                </TitleIconWrapper>
                No data
              </>
            )}
          </DetailsListItem>
          <DetailsListItem>
            <TitleIconWrapper>
              <Icon name={calendarIcon} />
            </TitleIconWrapper>
            {createdAt ? transformDateToDefaultFormat(createdAt) : 'No data'}
          </DetailsListItem>
          <DetailsListItem>
            <TitleIconWrapper>
              <Icon name={desktopIcon} />
            </TitleIconWrapper>
            {attrs?.deviceType || (attrs?.width && attrs?.height) ? (
              <DeviceTypeWrapper>
                {attrs.deviceType || ''}
                {attrs.width && attrs.height && ` (${attrs.width} x ${attrs.height})`}
              </DeviceTypeWrapper>
            ) : (
              'No data'
            )}
          </DetailsListItem>
          <DetailsListItem>
            {attrs ? (
              <BrowserIcon name={attrs.browser} width="2.2rem" height="2.2rem" />
            ) : (
              'No data'
            )}
            {attrs ? isUndefinedObject(attrs.browser) : 'No data'}
          </DetailsListItem>
          <DetailsListItem>
            {attrs ? <SystemIcon name={attrs.system} width="2.2rem" height="2.2rem" /> : 'No data'}
            {attrs ? isUndefinedObject(attrs.system) : 'No data'}
          </DetailsListItem>
          <DetailsListItem>
            <TitleIconWrapper>
              <Icon name={stackIcon} />
            </TitleIconWrapper>
            {actions ? `${actions.length} action(s)` : 'No data'}
          </DetailsListItem>
          <DetailsListItem>
            <TitleIconWrapper>
              <Icon name={cartIcon} />
            </TitleIconWrapper>
            {attrs
              ? `${
                  attrs.cart?.itemCount > 0 ? attrs.cart.itemsSubtotalPrice / 100 : 'Empty cart'
                } ${attrs.cart ? attrs.cart.currency : ''}`
              : 'No data'}
          </DetailsListItem>
          <DetailsListItem>
            <TitleIconWrapper>
              <Icon name={bugIcon} />
            </TitleIconWrapper>
            {attrs
              ? `${
                  attrs.errors?.length > 0
                    ? `${attrs.errors.length} error(s)`
                    : 'No errors detected'
                }`
              : 'No data'}
          </DetailsListItem>
        </DetailsList>
        {tagsActive && (
          <TagsContainer>
            <Stack vertical spacing="tight">
              <TagsWrapper>
                {!!tags?.length && (
                  <Stack spacing="extraTight">
                    {tags.map((tag) => (
                      <Tag>{tag.content}</Tag>
                    ))}
                  </Stack>
                )}
              </TagsWrapper>
              <StyledButton
                monochrome
                removeUnderline
                onClick={() => setTagsModalActive(true)}
                iconName={editIcon}
                actionName="Manage tags"
              />
            </Stack>
          </TagsContainer>
        )}
      </Stack>
      {tagsActive && (
        <TagsModal
          tags={tags}
          createTag={createTag}
          deleteTag={deleteTag}
          tagsModalActive={tagsModalActive}
          setTagsModalActive={setTagsModalActive}
          tagsModalInputValue={tagsModalInputValue}
          setTagsModalInputValue={setTagsModalInputValue}
        />
      )}
    </DetailsBox>
  ),
)

export default Informations
