import React from 'react'
import Icon from 'components/Icon'
import { notificationIcon } from 'utils/global-icon-names'
import { NotificationBox, NotificationBadge, NotificationIcon } from './styles'

const NotificationsIcon = ({ onClick, count }) => (
  <NotificationBox role="button" tabIndex={0} onClick={onClick}>
    <NotificationIcon>
      <Icon name={notificationIcon} />
    </NotificationIcon>
    {count > 0 && <NotificationBadge>{count}</NotificationBadge>}
  </NotificationBox>
)

export default NotificationsIcon
