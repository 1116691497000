import React from 'react'
import styled from 'styled-components'
import { InlineError as PolarisInlineError } from '@shopify/polaris'

const InlineError = ({ message }) => (
  <>
    {message && (
      <ErrorWrapper>
        <PolarisInlineError message={message} />
      </ErrorWrapper>
    )}
  </>
)

const ErrorWrapper = styled.div`
  margin-bottom: 10px;
`

export default InlineError
