import React from 'react'
import { Page } from '@shopify/polaris'
import { useStore } from 'utils/store'
import PlansComponents from 'components/Plans'
import { Title } from './styles'
import { getPageTitle } from './utils'

const PlansPage = () => {
  const {
    appStore: { currentUser },
  } = useStore()

  return (
    <Page>
      <Title>{getPageTitle(currentUser)}</Title>
      <PlansComponents />
    </Page>
  )
}

export default PlansPage
