import axios from 'axios'
import { makeAutoObservable } from 'mobx'
import { v4 as uuidv4 } from 'uuid'
import agent from 'api/agent'
import moment from 'moment'
import { subscribe } from 'api/websocket'
import history from 'utils/history'
import { initializeResourceStore } from 'utils/resource-store'
import { setPlayerSpeedButtonsStyle, writeCookie } from 'utils/global-functions'
import { initializePlayer, skipToLive, getCurrentUrlFromReplayer, hidePlayerButtons } from './utils'

class ItemStore {
  replayer = null
  resourceName = 'Replay'
  agentName = 'Replays'
  frontendUrl = '/'
  currentUrlFromReplayer = ''
  selectedTab = 0
  editNoteState = false
  destroyNoteModalActive = false
  currentReplayerTimestamp = 0
  createNoteText = ''
  editNoteText = ''
  targetEditNote = null
  targetDeleteNote = null
  noteAddLoading = false
  noteEditLoading = false
  liveSessionRunning = false
  liveUniqueId = uuidv4()
  activeSessionBanner = true
  currentLiveSessions = []
  messageModalActive = false
  currentUrlFromReplayer = ''
  tagsModalActive = false
  tagsModalInputValue = ''
  activeMoreActions = false

  constructor() {
    initializeResourceStore(this)
    makeAutoObservable(this)
  }

  fetch = (id) => {
    this.loading = true

    const axiosJsonInstance = axios.create({
      baseURL: process.env.BACKEND_URL,
      transformResponse: [
        (data) => {
          let resp

          try {
            resp = JSON.parse(data)
          } catch (error) {
            throw Error(`Error parsing JSON data - ${JSON.stringify(error)}`)
          }

          return resp
        },
      ],
    })

    Promise.all([
      agent[this.agentName].show(id),
      axiosJsonInstance.get(`api/replays/${id}/show_events`),
    ])
      .then((values) => {
        const [replay, replayEvents] = values

        const events = []
        replayEvents.data.forEach((eventsArray) => {
          const parsedArray = JSON.parse(eventsArray)
          events.push(...parsedArray)
        })

        return this.fetchSuccess({ ...replay, events })
      })
      .catch((error) => this.fetchError(error))
  }

  setReplayer = (replayer) => {
    this.replayer = replayer
    setPlayerSpeedButtonsStyle()
  }

  redirectToList = () => {
    history.push('/')
    this.showNotification('Upgrade your plan to see this replay.', { error: true })
  }

  updateFavourite = () => {
    const body = { state: !this.item.isFavourite }
    agent[this.agentName].updateFavourite(this.item.id, body).then(this.successUpdateFavourite)
  }

  successUpdateFavourite = (response) => {
    this.item.isFavourite = response
    this.showNotification(response ? 'Added to favourites.' : 'Removed from favourites.')
  }

  updateWatched = (id) => {
    const body = { date: this.item.watchedAt ? null : new Date().toUTCString() }

    agent[this.agentName].updateWatched(id, body).then(this.successUpdateWatched)
  }

  successUpdateWatched = (response) => {
    this.item.watchedAt = response
    this.showNotification(response ? 'Marked as watched.' : 'Unmarked as watched.')
  }

  fetchPublic = (id) => {
    this.loading = true
    agent[this.agentName].showPublic(id).then(this.fetchPublicSuccess, this.fetchPublicError)
  }

  fetchPublicSuccess = (item) => {
    this.item = item
    this.currentUrlFromReplayer = item.landingPage
    this.loading = false
  }

  fetchPublicError = (error) => {
    history.push(this.frontendUrl)
    this.handleError(error)
    this.loading = false
  }

  setCurrentUrlFromReplayer = () => {
    const currentUrl = getCurrentUrlFromReplayer(this.item, this.replayer)
    this.currentUrlFromReplayer = currentUrl || this.item.landingPage
  }

  setSelectedTab = (selectedTab) => {
    this.selectedTab = selectedTab
  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    this.showNotification('Coppied.')
  }

  setEditNoteState = (state, note) => {
    this.editNoteState = state
    this.targetEditNote = note.id
    this.editNoteText = note.content
  }

  setDestroyNoteModalActive = (state) => {
    this.destroyNoteModalActive = state
  }

  setCurrentReplayerTimestamp = (timestamp) => {
    this.currentReplayerTimestamp = timestamp
  }

  setCreateNoteText = (text) => {
    this.createNoteText = text
  }

  setEditNoteText = (text) => {
    this.editNoteText = text
  }

  createNote = (currentUser) => {
    this.noteAddLoading = true
    const body = {
      content: this.createNoteText,
      timestamp: this.currentReplayerTimestamp,
      user_name: currentUser.name,
    }

    agent[this.agentName]
      .createNote(this.item.id, currentUser.id, body)
      .then(this.successCreateNote, this.failedCreateNote)
  }

  successCreateNote = (response) => {
    this.item.notes.push(response)
    this.noteAddLoading = false
    this.createNoteText = ''
    this.showNotification('Note has been added.')
  }

  failedCreateNote = (error) => {
    this.noteAddLoading = false
    this.handleError(error)
  }

  updateNote = (note) => {
    this.noteEditLoading = true
    const body = {
      note_id: note.id,
      content: this.editNoteText,
    }

    agent[this.agentName]
      .updateNote(this.item.id, body)
      .then(this.successUpdateNote, this.failedUpdateNote)
  }

  successUpdateNote = (response) => {
    Object.assign(
      this.item.notes.find((note) => note.id === response.id),
      response,
    )
    this.noteEditLoading = false
    this.editNoteState = false
    this.showNotification('Note has been editted.')
  }

  failedUpdateNote = (error) => {
    this.noteEditLoading = false
    this.handleError(error)
  }

  deleteNoteAction = (note) => {
    this.targetDeleteNote = note.id
    this.destroyNoteModalActive = true
  }

  deleteNote = () => {
    agent[this.agentName]
      .deleteNote(this.item.id, this.targetDeleteNote)
      .then(() => this.successDeleteNote(this.targetDeleteNote), this.failedDeleteNote)
  }

  successDeleteNote = (noteId) => {
    this.item.notes = this.item.notes.filter((note) => note.id !== noteId)
    this.destroyNoteModalActive = false
    this.showNotification('Note has been deleted.')
  }

  failedDeleteNote = (error) => {
    this.destroyNoteModalActive = false
    this.handleError(error)
  }

  setLiveSessionRunning = (state) => {
    this.liveSessionRunning = state
  }

  setLiveUniqueId = (id) => {
    this.liveUniqueId = id
  }

  setActiveSessionBanner = (state) => {
    this.activeSessionBanner = state
  }

  subscribeSession = (sessionId) => {
    this.loading = false
    subscribe('ReplayChannel', { id: sessionId }, (replay) => {
      this.item = replay
      this.item.id = sessionId
      if (
        !window.RRWEB_PLAYER ||
        (window.RRWEB_PLAYER.$$ && window.RRWEB_PLAYER.$$.on_destroy === null)
      ) {
        window.RRWEB_PLAYER = initializePlayer(replay)
        this.replayer = window.RRWEB_PLAYER
        hidePlayerButtons()
        this.currentUrlFromReplayer = this.item.exitPage

        writeCookie('lastLiveSession', sessionId, 120)

        this.liveSessionRunning = true
        this.activeSessionBanner = false
      } else {
        this.startLiveSession(replay, window.RRWEB_PLAYER)
      }
    })
  }

  restoreSession = (sessionId) => {
    const axiosJsonInstance = axios.create({
      baseURL: process.env.BACKEND_URL,
      transformResponse: [
        (data) => {
          let resp

          try {
            resp = JSON.parse(data)
          } catch (error) {
            throw Error(`Error parsing JSON data - ${JSON.stringify(error)}`)
          }

          return resp
        },
      ],
    })

    Promise.all([
      agent[this.agentName].show(sessionId),
      axiosJsonInstance.get(`api/replays/${sessionId}/show_events`),
    ]).then((values) => {
      const [replay, replayEvents] = values

      const events = []
      replayEvents.forEach((eventsArray) => {
        const parsedArray = JSON.parse(eventsArray)
        events.push(...parsedArray)
      })

      const restoredReplay = { ...replay, events }

      this.item = restoredReplay
      window.RRWEB_PLAYER = initializePlayer(restoredReplay)
      this.replayer = window.RRWEB_PLAYER
      hidePlayerButtons()
      skipToLive(restoredReplay, window.RRWEB_PLAYER)
      this.currentUrlFromReplayer = this.item.exitPage

      writeCookie('lastLiveSession', sessionId, 120)

      subscribe('ReplayChannel', { id: sessionId }, (r) => {
        this.startLiveSession(r, window.RRWEB_PLAYER)
      })
    })

    this.liveSessionRunning = true
    this.activeSessionBanner = false
    this.liveUniqueId = sessionId
    this.loading = false
  }

  startLiveSession = (replay, replayer) => {
    this.replayer = replayer
    replay.events.forEach((e) => window.RRWEB_PLAYER.addEvent(e))
    replayer.getReplayer().startLive(replay.events[0].timestamp)
    this.updateReplayAttrs(replay)
  }

  updateReplayAttrs = (replay) => {
    this.item.attrs = replay.attrs
    this.item.actions = replay.actions
    this.item.chatLog = replay.chatLog
    this.currentUrlFromReplayer = replay.actions?.filter((action) => !!action.href)?.at(-1).href
  }

  destroyPlayer = () => {
    this.replayer?.$destroy()
  }

  destroySession = () => {
    window.location.reload()
  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    this.showNotification('Coppied.')
  }

  showCurrentLiveSessions = () => {
    agent.Replays.showCurrentLiveSessions().then(
      this.showCurrentLiveSessionsSuccess,
      this.showCurrentLiveSessionsError,
    )
  }

  showCurrentLiveSessionsSuccess = (response) => {
    this.currentLiveSessions = response
  }

  showCurrentLiveSessionsError = (error) => {
    this.handleError(error)
  }

  handleOverlimitLiveSession = () => {
    this.showNotification('Upgrade your plan to see this session.', { error: true })
  }

  updateMessageModalActive = (state) => {
    this.messageModalActive = state
  }

  sendMessage = (body) => {
    this.item.chatLog.push({
      id: uuidv4(),
      content: body.content,
      userId: this.currentUser.id,
      userName: this.currentUser.name,
      createdAt: moment(),
    })

    return agent.Replays.sendMessage(this.liveUniqueId, body).then(
      this.sendMessageSuccess,
      this.sendMessageError,
    )
  }

  sendMessageSuccess = () => {
    this.messageModalActive = false
  }

  sendMessageError = (error) => {
    this.messageModalActive = false
    this.handleError(error)
  }

  createTag = () => {
    const body = { content: this.tagsModalInputValue }
    agent.Replays.createTag(this.item.id, body).then(this.successCreateTag, this.failedCreateTag)
  }

  successCreateTag = (response) => {
    this.tagsModalInputValue = ''
    this.item.tags.push(response)
  }

  failedCreateTag = (error) => {
    this.handleError(error)
  }

  deleteTag = (tagId) => {
    agent.Replays.deleteTag(this.item.id, tagId).then(
      () => this.successDeleteTag(tagId),
      this.failedDeleteTag,
    )
  }

  successDeleteTag = (tagId) => {
    this.item.tags = this.item.tags.filter((tag) => tag.id !== tagId)
    this.showNotification('Tag has been deleted.')
  }

  failedDeleteTag = (error) => {
    this.handleError(error)
  }

  setTagsModalActive = (state) => {
    this.tagsModalActive = state
  }

  setTagsModalInputValue = (text) => {
    this.tagsModalInputValue = text
  }

  setActiveMoreActions = (state) => {
    this.activeMoreActions = state
  }
}

export default ItemStore
