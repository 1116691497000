import React from 'react'
import moment from 'moment'
import DOMPurify from 'dompurify'
import { ResourceList } from '@shopify/polaris'
import { defaultTextValidation } from 'utils/dompurify-validation-schema'
import { useStore } from 'utils/store'
import {
  NotificationContent,
  NotificationBody,
  NotificationTitle,
  NotificationDate,
  UnreadNotificationIcon,
  NotificationTag,
  NotificationHeader,
} from './styles'

const Notification = ({ id, params, type, readAt, createdAt }) => {
  const {
    topbarNotificationsStore: { setNotificationAsRead },
  } = useStore()

  return (
    <ResourceList.Item id={id} onClick={() => !readAt && setNotificationAsRead(id)}>
      <NotificationContent>
        <UnreadNotificationIcon display={!readAt} />
        <NotificationHeader>
          <NotificationTag type={type} />
          <NotificationDate>{moment(createdAt).startOf('hour').fromNow()}</NotificationDate>
        </NotificationHeader>
        <NotificationTitle>{params.title}</NotificationTitle>
        <NotificationBody
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(params.body, defaultTextValidation),
          }}
        />
      </NotificationContent>
    </ResourceList.Item>
  )
}

export default Notification
