import { useState, useEffect, useCallback } from 'react'
import lodash from 'lodash'

const DebouncedField = ({ value, onChange, children }) => {
  const [internalValue, setInternalValue] = useState(value)

  const onChangeDebounced = useCallback(
    lodash.debounce((v) => {
      onChange(v)
    }, 500),
    [],
  )

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value)
    }
  }, [value])

  return children(internalValue, (v) => {
    setInternalValue(v)
    onChangeDebounced(v)
  })
}

export default DebouncedField
