import React, { useEffect } from 'react'
import { Card, Page } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import buildRoute from 'utils/build-route'
import { addUserIcon } from 'utils/global-icon-names'
import { useStore } from 'utils/store'
import List from 'components/List'
import StyledButton from 'components/StyledButton'
import UsersStore from './list-store'
import Item from './Item'
import Footer from './Footer'

const UsersPage = observer(() => {
  const {
    usersStore: {
      loading,
      getItems,
      searchPhrase,
      filters,
      sortValue,
      pageNumber,
      items,
      selectedItems,
      updateSearchPhrase,
      removeSearchPhrase,
      updateSortValue,
      updateSelectedItems,
      increasePageNumber,
      decreasePageNumber,
      updateFilter,
      removeFilter,
      clearAllFilters,
      deleteItems,
      destroyModalActive,
      updateDestroyModalActive,
    },
  } = useStore()

  useEffect(() => {
    getItems()
  }, [])

  return (
    <Page
      title="Users"
      breadcrumbs={[{ content: 'Settings', url: '/settings' }]}
      primaryAction={
        <StyledButton url="users/create" primary iconName={addUserIcon} actionName="Create user" />
      }
    >
      <Card>
        <List
          itemComponent={Item}
          resourceName={{ singular: 'user', plural: 'users' }}
          sortOptions={[
            { label: 'Email A-Z', value: 'email ASC' },
            { label: 'Email Z-A', value: 'email DESC' },
            { label: 'Newest', value: 'created_at DESC' },
            { label: 'Oldest', value: 'created_at ASC' },
          ]}
          promotedBulkActions={[
            {
              content: 'Delete users',
              onAction: () => updateDestroyModalActive(true),
            },
          ]}
          loading={loading}
          items={items}
          getItems={getItems}
          searchPhrase={searchPhrase}
          updateSearchPhrase={updateSearchPhrase}
          removeSearchPhrase={removeSearchPhrase}
          filters={filters}
          updateFilter={updateFilter}
          removeFilter={removeFilter}
          clearAllFilters={clearAllFilters}
          sortValue={sortValue}
          updateSortValue={updateSortValue}
          pageNumber={pageNumber}
          increasePageNumber={increasePageNumber}
          decreasePageNumber={decreasePageNumber}
          selectedItems={selectedItems}
          updateSelectedItems={updateSelectedItems}
          deleteItems={deleteItems}
          destroyModalActive={destroyModalActive}
          updateDestroyModalActive={updateDestroyModalActive}
        />
      </Card>
      <Footer />
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    usersStore: new UsersStore(),
  }),
})(UsersPage)
