import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'
import history from 'utils/history'
import { mixpanelIdentify } from 'utils/mixpanel'
import { googleAnalyticsIdentify } from 'utils/gtag'
import { rollbarIdentify } from 'utils/rollbar'
import { helpscoutIdentify } from 'utils/helpscout'
import { isPublicPage } from './utils'

class Store {
  loading = true
  currentUser = {}

  constructor() {
    makeAutoObservable(this)
  }

  getCurrentUser = () => {
    this.loading = true
    return agent.Account.current().then(this.getCurrentUserSuccess, this.getCurrentUserError)
  }

  getCurrentUserSuccess = (user) => {
    this.loading = false
    this.currentUser = user

    mixpanelIdentify(user)
    googleAnalyticsIdentify(user)
    rollbarIdentify(user)
    helpscoutIdentify(user)
  }

  getCurrentUserError = () => {
    if (process.env.LANDING_PAGE && !isPublicPage(history.location)) {
      window.location = process.env.LANDING_PAGE
    }
    this.loading = false
  }

  logout = () => {
    this.loading = true
    agent.Account.logout().then(this.logoutSuccess)
  }

  logoutSuccess = () => {
    window.location = process.env.LANDING_PAGE
  }

  setOnboardingFinished = () => {
    this.loading = true
    agent.Site.update({ onboarding_status: 'finished' }).then(
      this.setOnboardingFinishedSuccess,
      this.setOnboardingFinishedFailed,
    )
  }

  setOnboardingFinishedSuccess = () => {
    this.loading = false
    this.reloadCurrentUser().then(() => {
      history.push('/')
    })
  }

  setOnboardingFinishedFailed = (error) => {
    this.loading = false
    this.handleError(error)
  }
}

export default Store
