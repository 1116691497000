import React from 'react'
import { TextContainer, Modal, Banner } from '@shopify/polaris'
import { useStore } from 'utils/store'
import { observer } from 'mobx-react-lite'

const DowngradeConfirmationModal = observer(() => {
  const {
    plansPageStore: {
      createPlan,
      changeModalPlanLoading,
      downgradeConfirmationModalActive,
      hideDownagradeConfirmationModal,
      selectedPlan,
    },
  } = useStore()

  return (
    <Modal
      open={downgradeConfirmationModalActive}
      onClose={() => hideDownagradeConfirmationModal()}
      title="Some data could be lost"
      primaryAction={{ content: 'Cancel', onAction: () => hideDownagradeConfirmationModal() }}
      secondaryActions={{
        content: 'Downgrade',
        onAction: () => createPlan(selectedPlan),
        loading: changeModalPlanLoading,
        destructive: true,
      }}
    >
      <Modal.Section>
        <TextContainer>
          <p>Are you sure you want to downgrade your billing plan?</p>
          <Banner status="critical">
            <p>
              Every overlimit recording will be <strong>permanently removed</strong>!
            </p>
          </Banner>
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
})

export default DowngradeConfirmationModal
