import React from 'react'
import { observer } from 'mobx-react-lite'
import { IndexTable } from '@shopify/polaris'
import moment from 'moment'
import Icon from 'components/Icon'
import { fillPlayIcon } from 'utils/global-icon-names'
import { useStore } from 'utils/store'
import { shopifySubduedColor } from 'utils/colors'
import { PlayIconWrapper } from './styles'

const CurrentLiveListRows = observer(() => {
  const {
    appStore: { currentUser },
    detailsReplayStore: { currentLiveSessions, restoreSession, handleOverlimitLiveSession },
  } = useStore()

  return currentLiveSessions.map(
    ({ id, actions, createdAt, lastEventAt, exitPage, attrs, overlimit }, index) => (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>
          {overlimit ? (
            <PlayIconWrapper
              role="button"
              tabIndex={0}
              onClick={() => handleOverlimitLiveSession()}
              size="2.5rem"
              color={shopifySubduedColor}
            >
              <Icon name={fillPlayIcon} />
            </PlayIconWrapper>
          ) : (
            <PlayIconWrapper
              role="button"
              tabIndex={0}
              onClick={() => restoreSession(id)}
              size="2.5rem"
            >
              <Icon name={fillPlayIcon} />
            </PlayIconWrapper>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>{actions?.length ?? 'No data'}</IndexTable.Cell>
        <IndexTable.Cell>
          {exitPage?.replace(currentUser.siteBaseUrl, '') ?? 'No data'}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {attrs?.cart ? `${attrs.cart.totalPrice / 100} ${attrs.cart.currency}` : 'Cart is empty'}
        </IndexTable.Cell>
        <IndexTable.Cell>{moment(createdAt).startOf('minutes').fromNow()}</IndexTable.Cell>
        <IndexTable.Cell>{moment(lastEventAt).startOf('minutes').fromNow()}</IndexTable.Cell>
      </IndexTable.Row>
    ),
  )
})

export default CurrentLiveListRows
