import React, { useEffect } from 'react'
import { Card, Stack } from '@shopify/polaris'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'
import buildRoute from 'utils/build-route'
import logo from 'images/logo.png'
import LoginForm from './LoginForm'
import LoginPageStore from './store'
import { Page } from './styles'

const LoginPage = observer(() => {
  const {
    loginPageStore: { errors, loginUser, setSlug },
  } = useStore()
  const { slug } = useParams()

  useEffect(() => {
    setSlug(slug)
  }, [])

  return (
    <Page>
      <Card>
        <Card.Section>
          <Stack vertical spacing="tight">
            <img src={logo} height={75} width={300} alt={process.env.APP_NAME} />
          </Stack>
        </Card.Section>
        <Card.Section>
          <LoginForm errors={errors} onSubmit={loginUser} />
        </Card.Section>
      </Card>
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    loginPageStore: new LoginPageStore(),
  }),
})(LoginPage)
