import React from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar, Button, Card, Stack, TextContainer, TextField, Tooltip } from '@shopify/polaris'
import StyledButton from 'components/StyledButton'
import { convertSecondsToHHMMSS } from 'utils/global-functions'
import { saveIcon } from 'utils/global-icon-names'

const NotesList = observer(
  ({
    replay,
    replayer,
    setEditNoteState,
    editNoteState,
    deleteNoteAction,
    editNoteText,
    setEditNoteText,
    updateNote,
    targetEditNote,
    notesActionLoading,
    notesActive,
  }) =>
    replay.notes.map((note) => (
      <Card.Section
        key={note.id}
        subdued
        title={
          <Button monochrome removeUnderline onClick={() => replayer.goto(note.timestamp)} plain>
            {convertSecondsToHHMMSS(note.timestamp / 1000)}
          </Button>
        }
        actions={
          notesActive && [
            {
              content: 'Delete',
              destructive: true,
              onAction: () => deleteNoteAction(note),
              disabled: editNoteState,
            },
            {
              content: editNoteState && targetEditNote === note.id ? 'Cancel' : 'Edit',
              onAction: () => setEditNoteState(!editNoteState, note),
              disabled: editNoteState && targetEditNote !== note.id,
            },
          ]
        }
      >
        {editNoteState && targetEditNote === note.id ? (
          <Stack vertical wrap={false}>
            <TextField
              label="Edit note"
              autoComplete="off"
              value={editNoteText}
              onChange={setEditNoteText}
            />
            <StyledButton
              loading={notesActionLoading && targetEditNote === note.id}
              onClick={() => updateNote(note)}
              primary
              iconName={saveIcon}
              actionName="Save"
            />
          </Stack>
        ) : (
          <Stack alignment="center">
            <Tooltip content={note.userName}>
              <Avatar
                size="small"
                name={note.userName}
                initials={note.userName[0]}
                customer={false}
              />
            </Tooltip>
            <TextContainer>{note.content}</TextContainer>
          </Stack>
        )}
      </Card.Section>
    )),
)

export default NotesList
