import React from 'react'
import { observer } from 'mobx-react-lite'
import { v4 as uuidv4 } from 'uuid'
import { Card, Tooltip } from '@shopify/polaris'
import { DetailsContainer, ErrorsContainer, DetailsListHeading, ListItem } from './styles'

const Errors = observer(({ replay: { attrs } }) => (
  <DetailsContainer>
    <ErrorsContainer>
      <DetailsListHeading>Message</DetailsListHeading>
      <Tooltip content="Column number/Line number">
        <DetailsListHeading>CN/LN</DetailsListHeading>
      </Tooltip>
      <DetailsListHeading>File name</DetailsListHeading>
      {attrs.errors.map((error) => (
        <React.Fragment key={uuidv4()}>
          <Tooltip content={error.message}>
            <ListItem>{error.message}</ListItem>
          </Tooltip>
          <Tooltip content={`${error.colno}/${error.lineno}`}>
            <ListItem>{`${error.colno}/${error.lineno}`}</ListItem>
          </Tooltip>
          <Tooltip content={error.filename}>
            <ListItem>{error.filename}</ListItem>
          </Tooltip>
        </React.Fragment>
      ))}
      <Card.Section />
    </ErrorsContainer>
  </DetailsContainer>
))

export default Errors
