import { createContext, useContext } from 'react'
import extendStores from 'utils/extend-stores'
import GlobalStores from './global-stores'

let StoreContext = createContext(GlobalStores)

export const rebuildStore = (dynamicStores = {}) => {
  extendStores({ stores: dynamicStores, globalStores: GlobalStores })
  StoreContext = createContext({ ...GlobalStores, ...dynamicStores })
}

export const useStore = () => useContext(StoreContext)
