import iziToast from 'izitoast'

export const showToast = ({
  titleColor,
  titleSize,
  title,
  messageColor,
  messageSize,
  position,
  theme,
  zindex,
  timeout,
  transitionIn,
  transitionOut,
  transitionInMobile,
  transitionOutMobile,
}) =>
  iziToast.show({
    title: title || '',
    message: 'How can I help you?',
    balloon: true,
    drag: false,
    titleColor,
    titleSize,
    messageColor,
    messageSize,
    position,
    theme,
    zindex,
    timeout,
    transitionIn,
    transitionOut,
    transitionInMobile,
    transitionOutMobile,
    inputs: [['<input type="text">']],
  })
