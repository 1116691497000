import React from 'react'
import { observer } from 'mobx-react-lite'
import { TextContainer, Modal, Banner } from '@shopify/polaris'
import { useStore } from 'utils/store'
import { FREE_PLAN, PAID_PLAN } from './store'

const FreeTrialModal = observer(() => {
  if (!FREE_PLAN || !PAID_PLAN) return null

  const {
    plansPageStore: {
      freeTrialModalActive,
      hideFreeTrialModal,
      changeModalPlanLoading,
      createPlan,
      selectedPlanName,
    },
    appStore: { currentUser },
  } = useStore()

  const primaryAction =
    currentUser.site.planPrice > 0
      ? { content: 'Cancel', onAction: () => hideFreeTrialModal() }
      : {
          content: 'Start free trial',
          onAction: () => createPlan(PAID_PLAN),
          loading: changeModalPlanLoading && selectedPlanName === PAID_PLAN.name,
          disabled: changeModalPlanLoading,
        }

  return (
    <Modal
      open={freeTrialModalActive}
      onClose={() => hideFreeTrialModal()}
      title="Free trial available"
      primaryAction={primaryAction}
      secondaryActions={{
        content: 'Start free plan',
        onAction: () => createPlan(FREE_PLAN),
        loading: changeModalPlanLoading && selectedPlanName === FREE_PLAN.name,
        disabled: changeModalPlanLoading,
      }}
    >
      <Modal.Section>
        <TextContainer>
          {currentUser.site.installationStatus === 'done' && (
            <Banner title="Some data could be lost" status="critical">
              <p>
                Every overlimit recording will be <strong>permanently removed</strong>!
              </p>
            </Banner>
          )}
          <p>
            Are you sure you want to start the free plan with limited functionality, you have{' '}
            {currentUser.site.trialDaysRemaining} days free trial of any paid plan.
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  )
})

export default FreeTrialModal
