import React from 'react'
import { Link } from '@shopify/polaris'
import { fillPlayIcon, linePlayIcon } from 'utils/global-icon-names'
import Icon from 'components/Icon'
import { primaryGreen } from 'utils/colors'
import { IconWrapper } from './styles'

const ActiveLink = ({ id, watchedAt }) => (
  <Link url={`/${id}/details`}>
    <IconWrapper cursor="pointer" size="3.5rem" color={primaryGreen}>
      <Icon name={watchedAt ? linePlayIcon : fillPlayIcon} />
    </IconWrapper>
  </Link>
)

export default ActiveLink
