import React, { useCallback, useState } from 'react'
import { Autocomplete } from '@shopify/polaris'
import { countryOptions, extractLabelFromCountryOptions } from './config'

const CountrySelector = ({ selectedOptions, onSelect, placeholder = 'Search' }) => {
  const [inputValue, setInputValue] = useState(
    extractLabelFromCountryOptions(selectedOptions) || '',
  )
  const [options, setOptions] = useState(countryOptions)

  const updateText = useCallback(
    (value) => {
      setInputValue(value)

      if (value === '') {
        setOptions(countryOptions)
        return
      }

      const filterRegex = new RegExp(value, 'i')
      const resultOptions = []

      countryOptions.forEach((opt) => {
        if (opt.label.match(filterRegex)) resultOptions.push(opt)
      })

      setOptions(resultOptions)
    },
    [countryOptions],
  )

  const updateSelection = useCallback(
    (selected) => {
      const label = extractLabelFromCountryOptions(selected.at(0))
      onSelect(selected)
      setInputValue(String(label) || '')
    },
    [options],
  )

  const textField = (
    <Autocomplete.TextField onChange={updateText} value={inputValue} placeholder={placeholder} />
  )

  return (
    <Autocomplete
      textField={textField}
      selected={selectedOptions || []}
      options={options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))}
      onSelect={updateSelection}
    />
  )
}

export default CountrySelector
