import agent from 'api/agent'
import { makeAutoObservable } from 'mobx'
import { initializeResourceListStore } from 'utils/resource-list-store'
import history from 'utils/history'

class ListStore {
  agentName = 'Replays'
  sortValue = 'created_at DESC'
  filters = [
    { name: 'Landing page', field: 'landing_page', type: 'contain', value: null },
    { name: 'Exit page', field: 'exit_page', type: 'contain', value: null },
    {
      name: 'Country',
      field: 'localization',
      second_field: 'country_code',
      type: 'jsonb_equal-country',
      value: null,
    },
    {
      name: 'Visited page',
      field: 'actions',
      second_field: 'href',
      type: 'jsonb_contain_array',
      value: null,
    },
    {
      name: 'User uuid',
      field: 'attrs-user',
      second_field: 'user_uuid',
      type: 'jsonb_equal',
      value: null,
    },
    {
      name: 'Created at',
      field: 'created_at',
      type: 'range-date',
      value: null,
      focused: null,
      onFocusedChange(state) {
        this.focused = state
      },
    },
    {
      name: 'Browser',
      field: 'attrs-browser',
      second_field: 'browser',
      type: 'jsonb_include_array',
      value: null,
      choices: [
        {
          label: 'Android',
          value: 'Android',
        },
        {
          label: 'Chrome',
          value: 'Chrome',
        },
        {
          label: 'Edge',
          value: 'Edge',
        },
        {
          label: 'Firefox',
          value: 'Firefox',
        },
        {
          label: 'Opera',
          value: 'Opera',
        },
        {
          label: 'Safari',
          value: 'Safari',
        },
      ],
    },
    {
      name: 'Operating system',
      field: 'attrs-system',
      second_field: 'system',
      type: 'jsonb_include_array',
      value: null,
      choices: [
        {
          label: 'Windows',
          value: 'Windows',
        },
        {
          label: 'Linux',
          value: 'Linux',
        },
        {
          label: 'MacOS',
          value: 'MacOS',
        },
      ],
    },
    {
      name: 'Device',
      field: 'attrs-device',
      second_field: 'deviceType',
      type: 'jsonb_include_array',
      value: null,
      choices: [
        {
          label: 'Desktop',
          value: 'Desktop',
        },
        {
          label: 'Mobile',
          value: 'Mobile',
        },
        {
          label: 'Tablet',
          value: 'Tablet',
        },
      ],
    },
    {
      name: 'Events',
      field: 'attrs-events',
      type: 'jsonb_include',
      value: null,
      choices: [
        {
          label: 'Order completed',
          value: 'checkout',
        },
        {
          label: 'Has items in cart',
          value: 'cart',
        },
        {
          label: 'Logged customer',
          value: 'customerId',
        },
        {
          label: 'Error appeared',
          value: 'errors',
        },
      ],
    },
    {
      name: 'Order id',
      field: 'attrs-orderid',
      second_field: 'checkout',
      third_field: 'order_id',
      type: 'jsonb_contain_nested',
      value: null,
    },
    {
      name: 'UTM source',
      field: 'attrs-utmsource',
      second_field: 'utm_source',
      type: 'jsonb_contain',
      value: null,
    },
    {
      name: 'UTM medium',
      field: 'attrs-utmmedium',
      second_field: 'utm_medium',
      type: 'jsonb_contain',
      value: null,
    },
    {
      name: 'UTM name',
      field: 'attrs-utmname',
      second_field: 'utm_name',
      type: 'jsonb_contain',
      value: null,
    },
    {
      name: 'UTM term',
      field: 'attrs-utmterm',
      second_field: 'utm_term',
      type: 'jsonb_contain',
      value: null,
    },
    {
      name: 'UTM content',
      field: 'attrs-utmcontent',
      second_field: 'utm_content',
      type: 'jsonb_contain',
      value: null,
    },
  ]
  tabFilters = [
    {
      name: 'Favourite',
      field: 'is_favourite',
      type: 'equal',
      value: false,
    },
    {
      name: 'Not watched',
      field: 'watched_at',
      type: 'not_exists',
      value: null,
    },
    { name: 'Highlights', field: 'highlights', type: 'equal', value: false },
    { name: 'Overlimit', field: 'overlimit', type: 'equal', value: null },
  ]
  selectedTab = 0
  totalCountOfReplays = 0
  tabs = [
    {
      id: 'all',
      content: 'All',
      predefined: true,
    },
    {
      id: 'favourite',
      content: 'Favourites',
      filters: '?is_favourite=true',
      predefined: true,
    },
    {
      id: 'not-watched',
      content: 'Not watched',
      filters: '?watched_at=true',
      predefined: true,
    },
    {
      id: 'highlights',
      content: 'Highlights',
      filters: '?highlights=true',
      predefined: true,
    },
  ]
  segmentsPopoverActive = false
  saveSegmentModalActive = false
  newSegmentName = ''
  editSegmentModalActive = false
  editSegmentName = this.tabs[this.selectedTab].content
  errorsSegmentForm = {}
  location = history.location

  constructor() {
    initializeResourceListStore(this)
    makeAutoObservable(this)

    history.listen((location) => {
      this.location = location
    })
  }

  showDisabledLinkToast = () => {
    if (this.currentUser.site.isLiveOnly) {
      this.showNotification(
        'Your plan is support only live previews, please upgrade your plan to see this replay. Go to the live page to see current live sessions.',
        {
          error: true,
        },
      )
    } else {
      this.showNotification('Overlimit record, please upgrade your plan to see this replay.', {
        error: true,
      })
    }
  }

  setSelectedTab = (selectedTab) => {
    if (this.loading) return
    this.selectedTab = selectedTab
    this.handleTabSelection()
  }

  handleTabSelection = () => {
    this.editSegmentName = this.tabs[this.selectedTab].content
    history.push({ search: this.tabs[this.selectedTab].filters })
    this.parseStateFromQueryString()
    return this.getItems()
  }

  beforeGetItems = () => {
    this.selectedTab = this.syncUrlSearchParamToSelectedTab()
    if (this.tabs[this.selectedTab].id === 'highlights') this.handleHighlights()
  }

  handleHighlights = () => {
    this.highlightFilters = [
      {
        name: 'Number of actions',
        field: 'actions',
        type: 'jsonb_length_equal_or_higher',
        value: this.currentUser.site.highlightFilters?.numberOfActions?.enabled
          ? this.currentUser.site.highlightFilters?.numberOfActions?.value
          : null,
      },
      {
        name: 'Number of products',
        field: 'attrs',
        second_field: 'cart',
        third_field: 'item_count',
        type: 'jsonb_equal_or_higher_nested',
        value: this.currentUser.site.highlightFilters?.numberOfProductsInCart?.enabled
          ? this.currentUser.site.highlightFilters?.numberOfProductsInCart?.value
          : null,
      },
    ]
  }

  syncUrlSearchParamToSelectedTab = () => {
    const searchParams = new URLSearchParams(history.location.search)
    let tabId = 0

    const tab = this.tabs.find((t) => t.filters === history.location.search)

    if (tab) return this.tabs.indexOf(tab)

    this.tabFilters.forEach((tabFilter, index) => {
      if (searchParams.has(tabFilter.field)) tabId = index + 1
    })

    return tabId
  }

  getTotalCountOfReplays = () => {
    agent[this.agentName].getTotalCountOfReplays().then(this.successGetTotalCountOfReplays)
  }

  successGetTotalCountOfReplays = (response) => {
    this.totalCountOfReplays = response
    if (this.currentUser.site.numberOfOverlimitReplays)
      this.tabs.push({
        id: 'overlimit',
        content: 'Overlimit',
        filters: '?overlimit=true',
        predefined: true,
      })
  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    this.showNotification('Coppied.')
  }

  setSegmentsPopoverActive = (state) => {
    this.segmentsPopoverActive = state
  }

  getSegments = () => agent.Segments.index().then(this.successGetSegments)

  successGetSegments = (response) => {
    response.forEach((tab) => {
      this.tabs.push({
        id: tab.id,
        content: tab.name,
        filters: tab.content,
      })
    })
    this.selectedTab = this.syncUrlSearchParamToSelectedTab()
    this.editSegmentName = this.tabs[this.selectedTab].content
  }

  createSegment = () => {
    const body = {
      name: this.newSegmentName,
      content: history.location.search,
    }

    agent.Segments.create(body).then(this.successCreateSegment, this.failedCreateSegment)
  }

  successCreateSegment = (response) => {
    this.errorsSegmentForm = {}
    this.segmentsPopoverActive = false
    this.tabs.push({
      id: response.id,
      content: response.name,
      filters: response.content,
    })
    this.selectedTab = this.tabs.indexOf(this.tabs.at(-1))
    this.saveSegmentModalActive = false
    this.newSegmentName = ''
    this.showNotification('Segment has been created.')
  }

  failedCreateSegment = (error) => {
    this.errorsSegmentForm = error.response.data
    this.handleError(error)
  }

  updateSegment = () => {
    const body = { name: this.editSegmentName, content: history.location.search }

    agent.Segments.update(this.tabs[this.selectedTab].id, body).then(
      this.successUpdateSegment,
      this.failedUpdateSegment,
    )
  }

  successUpdateSegment = (response) => {
    this.errorsSegmentForm = {}
    Object.assign(
      this.tabs.find((tab) => tab.id === response.id),
      {
        id: response.id,
        content: response.name,
        filters: response.content,
      },
    )
    this.segmentsPopoverActive = false
    this.editSegmentModalActive = false
    this.showNotification('Segment has been edited.')
  }

  failedUpdateSegment = (error) => {
    this.errorsSegmentForm = error.response.data
    this.handleError(error)
  }

  deleteSegment = () => {
    agent.Segments.delete(this.tabs.at(this.selectedTab).id).then(
      () => this.successDeleteSegment(this.tabs.at(this.selectedTab).id),
      this.failedDeleteSegment,
    )
  }

  successDeleteSegment = (tabId) => {
    this.segmentsPopoverActive = false
    if (this.tabs.at(this.selectedTab).id === tabId) this.selectedTab = 0
    this.tabs = this.tabs.filter((tab) => tab.id !== tabId)
    this.showNotification('Segment has been deleted.')
  }

  failedDeleteSegment = (error) => {
    this.handleError(error)
  }

  setSaveSegmentModalActive = (state) => {
    this.saveSegmentModalActive = state
  }

  setNewSegmentName = (text) => {
    this.newSegmentName = text
  }

  setEditSegmentModalActive = (state) => {
    this.editSegmentModalActive = state
  }

  setEditSegmentName = (text) => {
    this.editSegmentName = text
  }

  setErrorsSegmentFormEmpty = () => {
    this.errorsSegmentForm = {}
  }

  updateFavourite = (itemId, isFavourite) => {
    const body = { state: !isFavourite }
    agent[this.agentName]
      .updateFavourite(itemId, body)
      .then((response) => this.successUpdateFavourite(response, itemId))
  }

  successUpdateFavourite = (response, itemId) => {
    this.items.find((item) => item.id === itemId).isFavourite = response
    this.showNotification(response ? 'Added to favourites.' : 'Removed from favourites.')
  }
}

export default ListStore
