import React, { useEffect } from 'react'
import { Page, Stack, Popover, ActionList, Button } from '@shopify/polaris'
import { CustomersMajor, BehaviorMajor, CategoriesMajor } from '@shopify/polaris-icons'
import { useParams } from 'react-router'
import { observer } from 'mobx-react-lite'
import history from 'utils/history'
import buildRoute from 'utils/build-route'
import { useStore } from 'utils/store'
import { deleteIcon } from 'utils/global-icon-names'
import PageSpinner from 'components/PageSpinner'
import DelationConfirmationModal from 'components/DelationConfirmationModal'
import StyledButton from 'components/StyledButton'
import DetailsReplayStore from './item-store'
import { DetailsContainer } from './styles'
import Player from './Player'
import SidePanel from './SidePanel'

const DetailsReplayPage = observer(() => {
  const {
    detailsReplayStore: {
      fetch,
      loading,
      item: replay,
      redirectToList,
      destroyModalActive,
      updateDestroyModalActive,
      destroy,
      copyToClipboard,
      destroyPlayer,
      activeMoreActions,
      setActiveMoreActions,
    },
  } = useStore()

  const { replayId } = useParams()

  useEffect(() => {
    fetch(replayId)

    return () => {
      destroyPlayer()
    }
  }, [])

  if (loading) return <PageSpinner />

  if (replay.overlimit) return redirectToList()

  const activator = (
    <Button
      icon={BehaviorMajor}
      onClick={() => setActiveMoreActions(!activeMoreActions)}
      disclosure
    >
      More Actions
    </Button>
  )

  return (
    <Page
      title="Replay"
      breadcrumbs={[{ content: 'Recordings', url: '/' }]}
      primaryAction={
        <Stack spacing="tight">
          <Popover
            active={activeMoreActions}
            activator={activator}
            autofocusTarget="first-node"
            onClose={() => setActiveMoreActions(false)}
          >
            <ActionList
              actionRole="menuitem"
              items={[
                {
                  content: 'Public link',
                  icon: CustomersMajor,
                  onAction: () =>
                    copyToClipboard(`${process.env.FRONTEND_URL}/public/${replayId}/details`),
                },
                {
                  content: "All user's recordings",
                  icon: CategoriesMajor,
                  disabled: replay.totalCountOfCustomerRecordings < 2,
                  onAction: () => history.push(`/?attrs-user=${replay.attrs.userUuid}`),
                },
              ]}
            />
          </Popover>
          <StyledButton
            destructive
            onClick={() => updateDestroyModalActive(true)}
            actionName="Delete"
            iconName={deleteIcon}
          />
        </Stack>
      }
      fullWidth
    >
      <DetailsContainer>
        <Player />
        <SidePanel />
      </DetailsContainer>
      <DelationConfirmationModal
        modalActive={destroyModalActive}
        updateModalActive={updateDestroyModalActive}
        deleteAction={destroy}
        resourceName="replay"
      />
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    detailsReplayStore: new DetailsReplayStore(),
  }),
})(DetailsReplayPage)
