import React from 'react'
import { Card, Heading, Stack } from '@shopify/polaris'
import Icon from 'components/Icon'
import { IconHeadingWrapper } from './styles'

const FeatureCard = ({
  iconName,
  iconColor,
  heading,
  subHeading,
  description,
  actionName,
  actionUrl,
}) => (
  <Card
    sectioned
    title={
      <Stack alignment="center">
        <IconHeadingWrapper color={iconColor}>
          <Icon name={iconName} />
        </IconHeadingWrapper>
        <Heading>{heading}</Heading>
      </Stack>
    }
    primaryFooterAction={{ content: actionName, url: actionUrl }}
  >
    <Heading element="h6">{subHeading}</Heading>
    {description}
  </Card>
)

export default FeatureCard
