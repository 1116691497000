import React from 'react'
import { Button, Heading, Modal, Stack, Tag, TextField } from '@shopify/polaris'

const TagsModal = ({
  tags,
  createTag,
  deleteTag,
  tagsModalActive,
  setTagsModalActive,
  tagsModalInputValue,
  setTagsModalInputValue,
}) => (
  <Modal
    open={tagsModalActive}
    onClose={() => setTagsModalActive(false)}
    title="Custom tags"
    secondaryActions={{
      content: 'Close',
      onAction: () => setTagsModalActive(false),
    }}
  >
    {tags.length !== 0 && (
      <Modal.Section>
        <Stack vertical>
          <Heading>Tags </Heading>
          <Stack spacing="extraTight">
            {tags.map((tag) => (
              <Tag key={tag.id} onRemove={() => deleteTag(tag.id)}>
                {tag.content}
              </Tag>
            ))}
          </Stack>
        </Stack>
      </Modal.Section>
    )}

    <Modal.Section subdued>
      <Stack vertical>
        <TextField
          value={tagsModalInputValue}
          onChange={setTagsModalInputValue}
          label="Create tag"
        />
        <Button primary onClick={() => createTag()}>
          Create
        </Button>
      </Stack>
    </Modal.Section>
  </Modal>
)

export default TagsModal
