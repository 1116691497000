import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { Page, ButtonGroup, Layout, Card, Avatar, DescriptionList } from '@shopify/polaris'
import { deleteIcon, editIcon } from 'utils/global-icon-names'
import buildRoute from 'utils/build-route'
import { useStore } from 'utils/store'
import PageSpinner from 'components/PageSpinner'
import StyledButton from 'components/StyledButton'
import DelationConfirmationModal from 'components/DelationConfirmationModal'
import DetailsUserStore from './item-store'
import { TitleStack, Group, Link, Title, Date } from './styles'

const DetailsUserPage = observer(() => {
  const {
    detailsUserStore: {
      fetch,
      item: user,
      destroy,
      loading,
      destroyModalActive,
      updateDestroyModalActive,
    },
  } = useStore()

  const { userId } = useParams()

  useEffect(() => {
    fetch(userId)
  }, [userId])

  if (loading) return <PageSpinner />

  return (
    <Page
      title="Detailed view"
      breadcrumbs={[{ content: 'Users', url: '/settings/users' }]}
      primaryAction={
        <ButtonGroup>
          <StyledButton
            destructive
            onClick={() => updateDestroyModalActive(true)}
            iconName={deleteIcon}
            actionName="Delete"
          />
          <StyledButton
            primary
            url={`/settings/users/${user.id}/edit`}
            iconName={editIcon}
            actionName="Edit"
          />
        </ButtonGroup>
      }
    >
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section>
              <TitleStack>
                <Group>
                  <Avatar initials={user.name[0]} size="medium" name={user.name} />
                  <Title>{user.name}</Title>
                </Group>
              </TitleStack>
            </Card.Section>
            <Card.Section>
              <DescriptionList
                items={[
                  {
                    term: 'First name',
                    description: user.firstName,
                  },
                  {
                    term: 'Last name',
                    description: user.lastName,
                  },
                  {
                    term: 'Role',
                    description: user.role,
                  },
                  {
                    term: 'Provider',
                    description: user.provider,
                  },
                ]}
              />
            </Card.Section>
            <Card.Section subdued>
              <Date>Created at {moment(user.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Date>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card title="Contact" sectioned>
            <Link href={`mailto:${user.email}`}>{user.email}</Link>
          </Card>
        </Layout.Section>
      </Layout>

      <DelationConfirmationModal
        modalActive={destroyModalActive}
        updateModalActive={updateDestroyModalActive}
        deleteAction={destroy}
        resourceName="user"
      />
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    detailsUserStore: new DetailsUserStore(),
  }),
})(DetailsUserPage)
