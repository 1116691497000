import styled from 'styled-components'
import { primaryWhite, primaryGreen, primaryColor, primaryBlack, primaryGrey } from 'utils/colors'

export const ItemBadge = styled.div`
  width: fit-content;
  font-size: 0.9rem;
  line-height: normal;
  text-transform: uppercase;
  color: ${primaryWhite};
  background-color: ${(props) => userRoleColor(props.name)};
  padding: 0.4rem 0.4rem;
  border-radius: 5rem;

  &::before {
    content: '${(props) => props.name}';
  }
`

export const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ItemTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
`

export const ItemSubTitle = styled.h4`
  font-size: 1.1rem;
`

export const ItemHeading = styled.div`
  display: flex;
  flex-direction: column;
`

export const Stack = styled.div`
  display: flex;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
`

export const TitleStack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const Title = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
`

export const Date = styled.span`
  color: ${primaryGrey};
  font-size: 1.2rem;
`

export const Link = styled.a`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${primaryBlack};
  }

  &:hover {
    text-decoration: underline;
  }
`

const userRoleColor = (name) => {
  switch (name) {
    case 'collaborator':
      return primaryGreen
    case 'owner':
      return primaryColor
    default:
      return primaryGrey
  }
}
