import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Card, EmptySearchResult, Layout } from '@shopify/polaris'
import DataList from 'components/DataList'
import { useStore } from 'utils/store'
import { liveListHeadings } from './config'
import CurrentLiveListRows from './CurrentLiveListRows'

const CurrentLiveList = observer(() => {
  const {
    appStore: {
      currentUser: {
        site: { recordingEnabled },
      },
    },
    detailsReplayStore: { currentLiveSessions, liveSessionRunning, showCurrentLiveSessions },
  } = useStore()

  useEffect(() => {
    showCurrentLiveSessions()
    const interval = setInterval(() => showCurrentLiveSessions(), 5000)

    return () => clearInterval(interval)
  }, [])

  if (liveSessionRunning) return null

  if (!recordingEnabled) return null

  return (
    <Layout.AnnotatedSection
      title="Current sessions"
      description="Take a look at your customers in real time. Troubleshoot theirs problems, give an advice in choosing. "
    >
      <Card sectioned>
        <DataList
          headings={liveListHeadings}
          rowMarkup={<CurrentLiveListRows />}
          resourceName={{ singular: 'session', plural: 'sessions' }}
          items={currentLiveSessions}
          emptyState={<EmptySearchResult title="No current session available." withIllustration />}
        />
      </Card>
    </Layout.AnnotatedSection>
  )
})

export default CurrentLiveList
