import React from 'react'
import { Card, FormLayout, Layout, Select, TextField } from '@shopify/polaris'
import { Form, Formik } from 'formik'
import StyledButton from 'components/StyledButton'
import { saveIcon } from 'utils/global-icon-names'
import { useStore } from 'utils/store'
import { activatedDeactivatedOptions } from './config'

const HighlightsConfig = () => {
  const {
    appStore: {
      currentUser: {
        site: { highlightFilters },
      },
    },
    replaysSettingsStore: { updateHighlightFilters },
  } = useStore()

  return (
    <Layout.AnnotatedSection
      id="highlightsConfigId"
      title="Highlights config"
      description="Determine condition for highlight sessions."
    >
      <Card sectioned>
        <Formik
          enableReinitialize
          initialValues={{
            numberOfActions: {
              value: highlightFilters?.numberOfActions?.value
                ? highlightFilters.numberOfActions.value
                : '10',
              enabled: highlightFilters?.numberOfActions?.enabled ?? false,
            },
            numberOfProductsInCart: {
              value: highlightFilters?.numberOfProductsInCart?.value ?? '10',
              enabled: highlightFilters?.numberOfProductsInCart?.enabled ?? false,
            },
          }}
          onSubmit={(v) => updateHighlightFilters(v)}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <FormLayout>
                <TextField
                  type="number"
                  autoComplete="off"
                  label="Minimal number of actions"
                  helpText="Set a minimum number of session actions to get a notification."
                  value={String(values.numberOfActions.value)}
                  onChange={(v) => setFieldValue('numberOfActions.value', v)}
                  disabled={!values.numberOfActions.enabled}
                  connectedRight={
                    <Select
                      labelHidden
                      label="Session length notification: "
                      options={activatedDeactivatedOptions}
                      onChange={() =>
                        setFieldValue('numberOfActions.enabled', !values.numberOfActions.enabled)
                      }
                      value={values.numberOfActions.enabled}
                    />
                  }
                />
                <TextField
                  type="number"
                  autoComplete="off"
                  label="Number of products in cart"
                  helpText="Set a minimum number of session products in a cart to get a notification."
                  value={String(values.numberOfProductsInCart.value)}
                  onChange={(v) => setFieldValue('numberOfProductsInCart.value', v)}
                  disabled={!values.numberOfProductsInCart.enabled}
                  connectedRight={
                    <Select
                      labelHidden
                      label="Cart value notification: "
                      options={activatedDeactivatedOptions}
                      onChange={() =>
                        setFieldValue(
                          'numberOfProductsInCart.enabled',
                          !values.numberOfProductsInCart.enabled,
                        )
                      }
                      value={values.numberOfProductsInCart.enabled}
                    />
                  }
                />
                <StyledButton
                  primary
                  submit
                  loading={isSubmitting}
                  actionName="Save"
                  iconName={saveIcon}
                />
              </FormLayout>
            </Form>
          )}
        </Formik>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default HighlightsConfig
