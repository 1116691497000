import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  ActionList,
  Button,
  IndexTable,
  Pagination,
  Popover,
  Select,
  useIndexResourceState,
} from '@shopify/polaris'
import { MobileHorizontalDotsMajor } from '@shopify/polaris-icons'
import { ListFooter } from 'components/List/styles'
import Filters from 'components/Filters'
import { filterComponent, filterValueLabel, getSelectedItemsCount } from './utils'
import { FiltersContainer, ListFiltersContainer, Box, SelectContainer } from './styles'
import { maxNumberItemsPerPage } from './config'

const DataList = observer(
  ({
    loading,
    resourceName,
    sortOptions,
    bulkActions,
    promotedBulkActions,
    searchPhrase,
    filters,
    sortValue,
    pageNumber,
    items,
    getItems,
    updateSearchPhrase,
    removeSearchPhrase,
    updateSortValue,
    increasePageNumber,
    decreasePageNumber,
    updateFilter,
    removeFilter,
    clearAllFilters,
    rowMarkup,
    headings,
    selectable = false,
    emptyState,
    customSegments = true,
    popoverActive,
    setPopoverActive,
    popoverItems,
  }) => {
    const { selectedResources, allResourcesSelected, handleSelectionChange } =
      useIndexResourceState(items)

    return (
      <>
        {filters || sortOptions ? (
          <ListFiltersContainer>
            {filters && (
              <FiltersContainer>
                <Filters
                  getItems={getItems}
                  queryValue={searchPhrase}
                  onQueryChange={updateSearchPhrase}
                  onQueryClear={removeSearchPhrase}
                  filters={filters.map((filter) => ({
                    key: filter.field,
                    label: filter.name,
                    filter: filterComponent(filter, updateFilter),
                    shortcut: false,
                  }))}
                  appliedFilters={filters
                    .filter((f) => f.value)
                    .map((filter) => ({
                      key: filter.field,
                      label: `${filter.name}: ${filterValueLabel(filter)}`,
                      onRemove: () => removeFilter(filter),
                    }))}
                  onClearAll={clearAllFilters}
                />
              </FiltersContainer>
            )}
            <Box>
              {sortOptions && (
                <SelectContainer>
                  <Select
                    labelInline
                    label="Sort by"
                    options={sortOptions}
                    value={sortValue}
                    onChange={updateSortValue}
                  />
                </SelectContainer>
              )}
              {customSegments && (
                <Popover
                  active={popoverActive}
                  activator={
                    <Button
                      icon={MobileHorizontalDotsMajor}
                      onClick={() => setPopoverActive(true)}
                    />
                  }
                  onClose={() => setPopoverActive(false)}
                >
                  <ActionList actionRole="menuitem" items={popoverItems} />
                </Popover>
              )}
            </Box>
          </ListFiltersContainer>
        ) : null}
        <IndexTable
          resourceName={resourceName}
          loading={loading}
          itemCount={items.length}
          selectedItemsCount={getSelectedItemsCount(allResourcesSelected, selectedResources)}
          onSelectionChange={handleSelectionChange}
          headings={headings}
          bulkActions={bulkActions}
          promotedBulkActions={promotedBulkActions}
          selectable={selectable}
          emptyState={emptyState}
        >
          {rowMarkup}
        </IndexTable>
        {pageNumber && (
          <ListFooter>
            <Pagination
              label={`Page ${pageNumber}`}
              hasPrevious={pageNumber > 1 && !loading}
              onPrevious={() => {
                decreasePageNumber()
              }}
              hasNext={items.length >= maxNumberItemsPerPage && !loading}
              onNext={() => {
                increasePageNumber()
              }}
            />
          </ListFooter>
        )}
      </>
    )
  },
)

export default DataList
