import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'

countries.registerLocale(enLocale)

const countryObject = countries.getNames('en', { select: 'official' })

export const countryOptions = Object.entries(countryObject).map(([countryCode, countryName]) => ({
  value: countryCode,
  label: countryName,
}))

export const extractLabelFromCountryOptions = (selected) => {
  const obj = countryOptions.find((countryOption) => countryOption.value === selected)

  return obj?.label
}
