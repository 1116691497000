import React from 'react'
import { Card, Layout, Select } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'
import { conversationOptions } from '../ReplaysSettingsPage/config'

const ConversationConfig = observer(() => {
  const {
    appStore: {
      currentUser: {
        site: { conversationType },
      },
    },
    chatSettingsPageStore: { updateConversationType },
  } = useStore()

  return (
    <Layout.AnnotatedSection
      id="conversation"
      title="Conversation type"
      description="Choose the way of conversation with your customer on the live page."
    >
      <Card sectioned>
        <Select
          label="Conversation type"
          helpText="Choose the way of conversation during a live session."
          onChange={updateConversationType}
          options={conversationOptions}
          value={conversationType}
        />
      </Card>
    </Layout.AnnotatedSection>
  )
})

export default ConversationConfig
