export const setupErrorHandler = () => {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  ;['error'].forEach((e) =>
    window.addEventListener(e, (error) => {
      if (error.filename && !error.filename.includes(process.env.PUBLIC_URL)) {
        return
      }

      setTimeout(() => {
        window.location = `${process.env.ROUTING_BASENAME}/error`
      }, 2000)
    }),
  )
}

export const isErrorPage = () =>
  window.location.pathname === `${process.env.ROUTING_BASENAME}/error`
