import React from 'react'
import { Card, FormLayout, Layout, Select, Stack, TextField } from '@shopify/polaris'
import 'izitoast/dist/css/iziToast.css'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'
import ColorPicker from 'components/ColorPicker'
import StyledButton from 'components/StyledButton'
import { saveIcon, toastIcon } from 'utils/global-icon-names'
import {
  positionOptions,
  themeOptions,
  transitionInOptions,
  transitionOutOptions,
} from '../ReplaysSettingsPage/config'
import { showToast } from './utils'

const ToastConfig = observer(() => {
  const {
    appStore: {
      currentUser: {
        site: { conversationType },
      },
    },
    chatSettingsPageStore: {
      updateToastConfig,
      toastConfig: {
        theme,
        position,
        titleColor,
        titleSize,
        messageColor,
        messageSize,
        titleContent,
        zIndex,
        timeout,
        transitionIn,
        transitionOut,
        transitionInMobile,
        transitionOutMobile,
      },
      toastConfigErorrs,
    },
  } = useStore()

  if (conversationType !== 'toast') return null

  return (
    <Layout.AnnotatedSection
      id="toast"
      title="Toast settings"
      description="Personalize toast on your shop site."
    >
      <Card sectioned>
        <Formik
          enableReinitialize
          initialValues={{
            titleContent,
            theme,
            position,
            titleColor,
            titleSize: String(titleSize),
            messageColor,
            messageSize: String(messageSize),
            zIndex: String(zIndex),
            timeout: String(timeout),
            transitionIn,
            transitionOut,
            transitionInMobile,
            transitionOutMobile,
          }}
          onSubmit={(v) => updateToastConfig(v)}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <FormLayout>
                <Select
                  label="Theme"
                  helpText="Choose a theme for the toast."
                  options={themeOptions}
                  onChange={(v) => setFieldValue('theme', v)}
                  value={values.theme}
                  error={toastConfigErorrs.theme}
                />
                <Select
                  label="Position"
                  helpText="Select position on the screen of the toast."
                  options={positionOptions}
                  onChange={(v) => setFieldValue('position', v)}
                  value={values.position}
                  error={toastConfigErorrs.position}
                />
                <FormLayout.Group>
                  <TextField
                    label="Title content"
                    type="text"
                    helpText="Default popup title, can be empty"
                    onChange={(v) => setFieldValue('titleContent', v)}
                    value={values.titleContent}
                    error={toastConfigErorrs.titleContent}
                  />
                  <TextField
                    label="Title font size"
                    type="number"
                    autoComplete="off"
                    helpText="Set font size and color of the title."
                    onChange={(v) => setFieldValue('titleSize', v)}
                    value={values.titleSize}
                    error={toastConfigErorrs.titleSize}
                  />
                  <ColorPicker
                    label="Title color"
                    onChange={(v) => setFieldValue('titleColor', v)}
                    color={values.titleColor}
                    error={toastConfigErorrs.titleColor}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    label="Message font size"
                    type="number"
                    autoComplete="off"
                    helpText="Set font size and color of the message."
                    onChange={(v) => setFieldValue('messageSize', v)}
                    value={values.messageSize}
                    error={toastConfigErorrs.messageSize}
                  />
                  <ColorPicker
                    label="Message color"
                    onChange={(v) => setFieldValue('messageColor', v)}
                    color={values.messageColor}
                    error={toastConfigErorrs.messageColor}
                  />
                </FormLayout.Group>
                <TextField
                  label="Z-index"
                  type="number"
                  autoComplete="off"
                  helpText="Set the index layer of the toast."
                  onChange={(v) => setFieldValue('zIndex', v)}
                  value={values.zIndex}
                  error={toastConfigErorrs.zIndex}
                />
                <TextField
                  label="Timeout"
                  type="number"
                  autoComplete="off"
                  helpText="Set time of displaying toast."
                  onChange={(v) => setFieldValue('timeout', v)}
                  value={values.timeout}
                  error={toastConfigErorrs.timeout}
                />
                <FormLayout.Group>
                  <Select
                    label="Transition in"
                    helpText="Choose an appearing animation of the toast."
                    onChange={(v) => setFieldValue('transitionIn', v)}
                    options={transitionInOptions}
                    value={values.transitionIn}
                  />
                  <Select
                    label="Transition out"
                    helpText="Choose a hiding animation of the toast."
                    onChange={(v) => setFieldValue('transitionOut', v)}
                    options={transitionOutOptions}
                    value={values.transitionOut}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    label="Transistion in mobile"
                    helpText="Choose an appearing animation of the toast on mobile."
                    onChange={(v) => setFieldValue('transitionInMobile', v)}
                    options={transitionInOptions}
                    value={values.transitionInMobile}
                  />
                  <Select
                    label="Transistion out mobile"
                    helpText="Choose a hiding animation of the toast on mobile."
                    onChange={(v) => setFieldValue('transitionOutMobile', v)}
                    options={transitionOutOptions}
                    value={values.transitionOutMobile}
                  />
                </FormLayout.Group>
                <Stack spacing="baseTight">
                  <StyledButton
                    primary
                    submit
                    loading={isSubmitting}
                    actionName="Save"
                    iconName={saveIcon}
                  />
                  <StyledButton
                    actionName="Preview"
                    iconName={toastIcon}
                    onClick={() =>
                      showToast({
                        title: values.titleContent,
                        titleColor: values.titleColor,
                        titleSize: values.titleSize,
                        messageColor: values.messageColor,
                        messageSize: values.messageSize,
                        position: values.position,
                        theme: values.theme,
                        zindex: values.zIndex,
                        timeout: values.timeout,
                        transitionIn: values.transitionIn,
                        transitionOut: values.transitionOut,
                        transitionInMobile: values.transitionInMobile,
                        transitionOutMobile: values.transitionOutMobile,
                        inputs: [
                          [
                            '<input type="text" placeholder="Write the answer here...">',
                            'keyup',
                            function (instance, toast, input, e) {},
                          ],
                        ],
                      })
                    }
                  />
                </Stack>
              </FormLayout>
            </Form>
          )}
        </Formik>
      </Card>
    </Layout.AnnotatedSection>
  )
})

export default ToastConfig
