import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'

class Store {
  toastConfig = {}
  toastConfigErorrs = {}
  loadingSettings = false

  constructor() {
    makeAutoObservable(this)
  }

  updateConversationType = (conversationType) => {
    agent.Site.update({ conversation_type: conversationType }).then(
      this.updateRecordingSettingsSuccess,
      this.updateRecordingSettingsError,
    )
  }

  updateRecordingSettingsSuccess = () => {
    this.reloadCurrentUser()
    this.showNotification('Settings updated.')
  }

  updateRecordingSettingsError = (error) => {
    this.handleError(error)
  }

  getToastConfig = () => {
    this.loadingSettings = true
    agent.ToastConfig.show().then(this.getToastConfigSuccess, this.getToastConfigError)
  }

  getToastConfigSuccess = (response) => {
    this.toastConfig = response
    this.loadingSettings = false
  }

  getToastConfigError = () => {
    this.loadingSettings = false
  }

  updateToastConfig = (body) =>
    agent.ToastConfig.update(body).then(this.updateToastConfigSuccess, this.updateToastConfigError)

  updateToastConfigSuccess = () => {
    this.toastConfigErorrs = {}
    this.showNotification('Settings updated.')
  }

  updateToastConfigError = (error) => {
    this.toastConfigErorrs = error.response.data
    this.handleError(error)
  }
}

export default Store
