import moment from 'moment'

export const writeCookie = (name, value, expiresMinutes) => {
  const d = new Date()
  d.setTime(d.getTime() + expiresMinutes * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${name}=${value};${expires};path=/`
}

export const readCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
  return null
}

export const openInNewTab = (url) => {
  window.open(url, '_blank')
}

export const convertSecondsToHHMMSS = (seconds) => {
  const secNum = parseInt(seconds, 10)
  const h = Math.floor(secNum / 3600)
  const m = Math.floor((secNum - h * 3600) / 60)
  const s = secNum - h * 3600 - m * 60

  return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`
}

export const transformTimestampToDefaultFormat = (timestamp) =>
  new Date(timestamp).toISOString().substr(11, 8)

export const transformDateToDefaultFormat = (date) => moment(date).format('MMM Do, h:mm a')

export const isUndefinedObject = (object) => {
  if (!object) return 'No data'

  return object
}

export const isOnboardingFinished = (onboardingStatus) => onboardingStatus === 'finished'

function insertAfter(newNode, existingNode) {
  existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling)
}

const speedButtonsContentMap = new Map([
  [0, '1'],
  [1, '2'],
  [2, '4'],
  [3, '8'],
])

export const setPlayerSpeedButtonsStyle = () => {
  const playerPanel = document.querySelector('.rr-controller__btns')

  const [, , ...rest] = Array.from(playerPanel.childNodes)
  const speedButtons = rest.splice(0, 4)

  const speedButtonsWrapper = document.createElement('div')
  speedButtonsWrapper.classList = 'player-speed-buttons-wrapper'

  insertAfter(speedButtonsWrapper, playerPanel.firstChild)

  speedButtons.forEach((speedButton, index) => {
    speedButton.textContent = speedButtonsContentMap.get(index)
    speedButtonsWrapper.appendChild(speedButton)
  })
}
