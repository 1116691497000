import React from 'react'
import { useParams } from 'react-router'
import DetailsPage from './DetailsPage'
import LivePage from './LivePage'

const ReplayHub = () => {
  const { replayId } = useParams()

  return replayId === 'live' ? <LivePage /> : <DetailsPage />
}

export default ReplayHub
