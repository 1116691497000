import styled from 'styled-components'

export const SpinnerWraper = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 35%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Layout = styled.div`
  padding: 1rem 0;
  display: grid;
  grid-template-rows: min-content max-content;
`

export const Heading = styled.h2`
  font-size: 3.5rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 4rem;
`

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`
