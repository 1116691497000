import axios from 'axios'

axios.defaults.baseURL = process.env.BACKEND_URL
axios.defaults.withCredentials = true

const buildFormData = (object) => {
  const formData = new FormData()
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key])
  })
  return formData
}

const responseBody = (response) => response.data

const requests = {
  get: (url) => axios.get(url).then(responseBody),
  post: (url, body, options = { formData: false }) =>
    axios.post(url, options.formData ? buildFormData(body) : body).then(responseBody),
  put: (url, body, options = { formData: false }) =>
    axios.put(url, options.formData ? buildFormData(body) : body).then(responseBody),
  del: (url) => axios.delete(url).then(responseBody),
}

const Site = {
  update: (body) => requests.put('/api/sites/current', body, { formData: true }),
  install: (body) => requests.put('/api/sites/install', body),
  updateEmailNotifications: (body) => requests.put('api/sites/update_email_notifications', body),
  updateHighlightFilters: (body) => requests.put('api/sites/update_highlight_filters', body),
}

const Account = {
  current: () => requests.get('/api/users/current'),
  logout: () => requests.put('/auth/logout'),
  update: (body) => requests.put('/api/users/current', body),
}

const Contact = {
  create: (body) => requests.post('/api/contact', body),
}

const Plan = {
  create: (body) => requests.post('/api/plans', body),
  applyDiscount: (body) => requests.put('/api/sites/update_discount_code', body),
}

const Notifications = {
  index: () => requests.get('/api/notifications'),
  markAsRead: (body) => requests.put('/api/notifications/mark_as_read', body),
}

const Users = {
  index: (pageNumber, searchPhrase, sort, filters) =>
    requests.get(
      encodeURI(
        `/api/users?page=${pageNumber}&search=${searchPhrase}&sort=${sort}&filters=${JSON.stringify(
          filters,
        )}`,
      ),
    ),
  show: (userId) => requests.get(`api/users/${userId}`),
  create: (body) => requests.post('/api/users', body),
  update: (userId, body) => requests.put(`/api/users/${userId}`, body),
  delete: (usersId) => requests.del(encodeURI(`api/users/${usersId}`)),
  login: (body) => requests.post(`/api/users/login`, body),
}

const Replays = {
  index: (pageNumber, searchPhrase, sort, filters) =>
    requests.get(
      encodeURI(
        `/api/replays?page=${pageNumber}&search=${searchPhrase}&sort=${sort}&filters=${JSON.stringify(
          filters,
        )}`,
      ),
    ),
  show: (replayId) => requests.get(`api/replays/${replayId}`),
  showPublic: (replayId) => requests.get(`api/replays/${replayId}/show_public`),
  showEvents: (replayId) => requests.get(`api/replays/${replayId}/show_events`),
  updateFavourite: (replayId, body) =>
    requests.put(`api/replays/${replayId}/update_favourite`, body),
  updateWatched: (replayId, body) => requests.put(`/api/replays/${replayId}/update_watched`, body),
  getTotalCountOfReplays: () => requests.get('/api/replays/total_count_of_replays'),
  delete: (replayId) => requests.del(`api/replays/${replayId}`),
  showCurrentLiveSessions: () => requests.get('api/replays/current_sessions'),
  createNote: (replayId, userId, body) =>
    requests.post(`api/replays/${replayId}/create_note/${userId}`, body),
  updateNote: (replayId, body) => requests.put(`api/replays/${replayId}/update_note`, body),
  deleteNote: (replayId, noteId) => requests.del(`api/replays/${replayId}/destroy_note/${noteId}`),
  sendMessage: (replayId, body) => requests.post(`api/replays/${replayId}/send_message`, body),
  analytics: (dates, field) => requests.get(`api/replays/analytics?dates=${dates}&field=${field}`),
  createTag: (replayId, body) => requests.post(`api/replays/${replayId}/create_tag`, body),
  deleteTag: (replayId, tagId) => requests.del(`api/replays/${replayId}/destroy_tag/${tagId}`),
}

const Segments = {
  index: () => requests.get('api/segments'),
  create: (body) => requests.post('api/segments', body),
  update: (segmentId, body) => requests.put(`api/segments/${segmentId}`, body),
  delete: (segmentId) => requests.del(`api/segments/${segmentId}`),
}

const ToastConfig = {
  show: () => requests.get('api/toast_config'),
  update: (body) => requests.put('api/toast_config', body),
}

const agent = {
  Contact,
  Account,
  Site,
  Notifications,
  Plan,
  Users,
  Replays,
  Segments,
  ToastConfig,
}

export default agent
