export const headings = [
  { title: '' },
  { title: 'Favourite' },
  { title: 'Actions' },
  { title: 'Session length' },
  { title: 'Landed / Exited' },
  { title: 'Date' },
]

export const liveListHeadings = [
  { title: '' },
  { title: 'Actions' },
  { title: 'Current page' },
  { title: 'Cart value' },
  { title: 'Started' },
  { title: 'Updated' },
]

export const replaysListSortOptions = [
  { label: 'Newest', value: 'created_at DESC' },
  { label: 'Oldest', value: 'created_at ASC' },
  { label: '↑ Actions ', value: 'actions ASC' },
  { label: '↓ Actions', value: 'actions DESC' },
  { label: '↑ Session length', value: 'session_length ASC' },
  { label: '↓ Session length', value: 'session_length DESC' },
]

export const resourceName = { singular: 'replay', plural: 'replays' }
