import React from 'react'
import * as RemixIcon from 'react-icons/ri'
import * as FlatColor from 'react-icons/fc'
import * as FontAwesome from 'react-icons/fa'
import * as BoxIcons from 'react-icons/bi'
import * as Ionicons5 from 'react-icons/io5'

const Icon = ({ name }) => {
  switch (name.substring(0, 2)) {
    case 'Ri':
      return React.createElement(RemixIcon[name])
    case 'Fc':
      return React.createElement(FlatColor[name])
    case 'Fa':
      return React.createElement(FontAwesome[name])
    case 'Bi':
      return React.createElement(BoxIcons[name])
    case 'Io':
      return React.createElement(Ionicons5[name])
    default:
      return null
  }
}

export default Icon
