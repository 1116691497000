import React from 'react'
import Icon from 'components/Icon'
import { DetailsBox, EmptyStateContainer, EmptyStateIconWrapper, EmptyStateText } from './styles'

const EmptyState = ({ icon, text }) => (
  <DetailsBox>
    <EmptyStateContainer>
      <EmptyStateIconWrapper>
        <Icon name={icon} />
      </EmptyStateIconWrapper>
      <EmptyStateText>{text}</EmptyStateText>
    </EmptyStateContainer>
  </DetailsBox>
)

export default EmptyState
