import { META_EVENT_TYPE } from 'utils/replayer-events'
import { plans } from 'utils/plans-config.json'
import { criticalColor, primaryGreen, primaryViolet, primaryYellow } from 'utils/colors'

export const isRecordFinished = (currentProgress) => currentProgress === 1

export const getCurrentUrlFromReplayer = (replay, replayer) => {
  const lastInitEvent = replay.events
    .filter(
      (e) => e.timestamp <= getLastPlayedEvent(replayer)?.timestamp && e.type === META_EVENT_TYPE,
    )
    .reverse()[0]

  return lastInitEvent?.data?.href ?? null
}

const getLastPlayedEvent = (replayer) =>
  replayer.getReplayer().service.state.context.lastPlayedEvent

export const isLimitOfReplays = (currentUser, totalCountOfReplays) =>
  totalCountOfReplays >= plans.find((p) => p.name === currentUser.sitePlanName).replaysLimit

export const isNearLimitOfReplays = (currentUser, totalCountOfReplays) =>
  totalCountOfReplays >= plans.find((p) => p.name === currentUser.sitePlanName).replaysLimit * 0.8

export const getPlansLimitOfReplays = (currentUser) =>
  plans.find((p) => p.name === currentUser.sitePlanName).replaysLimit

export const showSessionBanner = (lastSessionId, currentSessionId, activeSessionBanner) =>
  lastSessionId && lastSessionId !== currentSessionId && activeSessionBanner

export const initializePlayer = (replay, options = { liveMode: true }) =>
  new window.rrwebPlayer({
    props: {
      liveMode: options.liveMode,
      events: replay.events,
      tags: {
        'Cart updated': primaryYellow,
        'Script error': criticalColor,
        'Order completed': primaryGreen,
        'Customer login': primaryViolet,
      },
    },
    target: document.getElementById('player-container'),
  })

export const startLiveSession = (replay, replayer) => {
  replay.events.forEach((e) => window.RRWEB_PLAYER.addEvent(e))
  replayer.getReplayer().startLive(replay.events[0].timestamp)
}

export const transformTimestampToDefaultFormat = (timestamp) =>
  new Date(timestamp).toISOString().substr(11, 8)

export const skipToLive = (replay, replayer) => {
  replayer.getReplayer().play(replayer.getReplayer().getMetaData().totalTime)
}

export const hidePlayerButtons = () => {
  const playerController = document.querySelector('.rr-controller')

  if (playerController) playerController.style.display = 'none'
}

export const showDisabledSessionBanner = (site) => {
  if (site.onboardingStatus === 'not-finished')
    return !site.adminRecordingEnabled || !site.recordingEnabled

  return !site.recordingEnabled
}

export const showChatLog = (site, replay, liveMode) =>
  (liveMode && site.conversationType === 'toast') || replay.chatLog?.length > 0
