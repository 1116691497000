import React, { useEffect } from 'react'
import { Card, Layout, Page, Tabs } from '@shopify/polaris'
import buildRoute from 'utils/build-route'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'
import { liveIcon } from 'utils/global-icon-names'
import StyledButton from 'components/StyledButton'
import DataList from 'components/DataList'
import ReplaysStore from './list-store'
import RowMarkup from './RowMarkup'
import { headings, replaysListSortOptions, resourceName } from './config'
import OverlimitBanner from './OverlimitBanner'
import { getPlansLimitOfReplays } from './utils'
import InputSegmentModal from './InputSegmentModal'
import EmptyState from './EmptyState'
import DisabledSessionRecordingBanner from './DisabledSessionRecordingBanner'

const ReplaysPage = observer(() => {
  const {
    replaysStore: {
      tabs,
      loading,
      getItems,
      searchPhrase,
      filters,
      sortValue,
      pageNumber,
      items,
      updateSearchPhrase,
      removeSearchPhrase,
      updateSortValue,
      increasePageNumber,
      decreasePageNumber,
      updateFilter,
      removeFilter,
      clearAllFilters,
      deleteItems,
      destroyModalActive,
      updateDestroyModalActive,
      selectedTab,
      setSelectedTab,
      getTotalCountOfReplays,
      totalCountOfReplays,
      segmentsPopoverActive,
      setSegmentsPopoverActive,
      getSegments,
      createSegment,
      updateSegment,
      saveSegmentModalActive,
      setSaveSegmentModalActive,
      newSegmentName,
      setNewSegmentName,
      setEditSegmentModalActive,
      editSegmentModalActive,
      editSegmentName,
      setEditSegmentName,
      deleteSegment,
      location,
      parseStateFromQueryString,
    },
    appStore: { currentUser },
  } = useStore()

  useEffect(() => {
    getTotalCountOfReplays()
    getSegments()
  }, [])

  useEffect(() => {
    parseStateFromQueryString()
    getItems()
  }, [location.search])

  return (
    <Page
      fullWidth
      title="Recordings"
      subtitle={`${totalCountOfReplays}/${getPlansLimitOfReplays(currentUser)} replays`}
      primaryAction={
        <StyledButton url="/live/details" destructive iconName={liveIcon} actionName="Live" />
      }
    >
      <Layout>
        <DisabledSessionRecordingBanner />
        <OverlimitBanner totalCountOfReplays={totalCountOfReplays} />
        <Layout.Section>
          <Card>
            <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} />
            <DataList
              headings={headings}
              rowMarkup={<RowMarkup items={items} />}
              resourceName={resourceName}
              sortOptions={replaysListSortOptions}
              loading={loading}
              items={items}
              getItems={getItems}
              searchPhrase={searchPhrase}
              updateSearchPhrase={updateSearchPhrase}
              removeSearchPhrase={removeSearchPhrase}
              filters={filters}
              updateFilter={updateFilter}
              removeFilter={removeFilter}
              clearAllFilters={clearAllFilters}
              sortValue={sortValue}
              updateSortValue={updateSortValue}
              pageNumber={pageNumber}
              increasePageNumber={increasePageNumber}
              decreasePageNumber={decreasePageNumber}
              deleteItems={deleteItems}
              destroyModalActive={destroyModalActive}
              updateDestroyModalActive={updateDestroyModalActive}
              popoverActive={segmentsPopoverActive}
              setPopoverActive={setSegmentsPopoverActive}
              emptyState={<EmptyState />}
              popoverItems={[
                {
                  content: 'Save view as',
                  onAction: () => setSaveSegmentModalActive(true),
                },
                {
                  content: 'Edit view',
                  onAction: () => setEditSegmentModalActive(true),
                  disabled: tabs.at(selectedTab)?.predefined,
                },
                {
                  content: 'Delete view',
                  onAction: () => deleteSegment(),
                  disabled: tabs.at(selectedTab)?.predefined,
                  destructive: true,
                },
              ]}
            />
          </Card>
        </Layout.Section>
      </Layout>
      <InputSegmentModal
        titleName="Save current view"
        modalActive={saveSegmentModalActive}
        setModalActive={setSaveSegmentModalActive}
        textFieldValue={newSegmentName}
        onChangeTextFieldValue={setNewSegmentName}
        onSaveAction={createSegment}
      />
      <InputSegmentModal
        titleName="Edit current view"
        modalActive={editSegmentModalActive}
        setModalActive={setEditSegmentModalActive}
        textFieldValue={editSegmentName}
        onChangeTextFieldValue={setEditSegmentName}
        onSaveAction={updateSegment}
        textFieldHelpText="Leave a name unchanged, if you want only overwrite filters."
      />
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    replaysStore: new ReplaysStore(),
  }),
})(ReplaysPage)
