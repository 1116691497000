import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import { InlineError } from '@shopify/polaris'
import { ErrorField } from 'utils/global-styles'
import { Color, Swatch, Popover, Cover, Label, Field } from './styles'

const ColorPicker = ({ label, color, onChange, error }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const toggleDisplayColorPicker = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  return (
    <>
      <Label>{label}</Label>
      <Field>
        <Swatch
          disableAlpha
          role="button"
          tabIndex={0}
          onClick={toggleDisplayColorPicker}
          onKeyDown={toggleDisplayColorPicker}
        >
          <Color backgroundColor={color || '#fff'} />
        </Swatch>
        {displayColorPicker ? (
          <Popover>
            <Cover
              role="button"
              tabIndex={0}
              aria-label="Close"
              onClick={toggleDisplayColorPicker}
              onKeyDown={toggleDisplayColorPicker}
            />
            <SketchPicker color={color || '#fff'} onChange={(v) => onChange(v.hex)} />
          </Popover>
        ) : null}
      </Field>
      <ErrorField>
        <InlineError message={error} />
      </ErrorField>
    </>
  )
}

export default ColorPicker
