import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect } from 'react-router-dom'
import { Banner, Spinner, Page, CalloutCard } from '@shopify/polaris'
import { useStore } from 'utils/store'
import buildRoute from 'utils/build-route'
import InstallStore from './store'
import { Layout, SpinnerWraper, Heading, CardsWrapper } from './styles'
import ContactModal from './ContactModal'

const InstallPage = observer(() => {
  const {
    installStore: {
      installSite,
      installationError,
      supportModalActive,
      updateSupportModalActive,
      sendEmail,
      errors,
      message,
      loading,
    },
    appStore: { currentUser },
  } = useStore()
  const [installationType, setInstallationType] = useState('auto')
  const [installing, setInstalling] = useState(false)

  const readyToInstall = currentUser.site.installationStatus === 'ready'

  if (!readyToInstall) {
    return <Redirect to="/" />
  }

  return (
    <Page>
      {installing && <Heading>Installing {process.env.APP_NAME}...</Heading>}
      <Layout>
        {installationError && (
          <Banner
            title="Installation failed"
            status="critical"
            action={{ content: 'Try again', onClick: () => installSite({ installationType }) }}
            secondaryAction={{
              content: 'Contact with support',
              onAction: () => updateSupportModalActive(true),
            }}
          >
            We are sorry, but we cannot install the app in your store. The problem may be caused by
            Shopify server&apos;s downtime or other time-related issues. You can try again in a few
            minutes or if it doesn&apos;t help, please contact our support team for more
            information.
          </Banner>
        )}

        {loading && installing && (
          <>
            <Banner title="Installation" status="info">
              We are installing the app into the store, it can take up a while, please don&apos;t
              refresh the page during the installation.
            </Banner>
            <SpinnerWraper>
              <Spinner size="large" />
            </SpinnerWraper>
          </>
        )}

        {!installing && (
          <>
            <Heading>Choose the way of installation</Heading>
            <CardsWrapper>
              <CalloutCard
                title="Automatic installation"
                illustration="https://raw.githubusercontent.com/icons8/flat-color-icons/master/svg/services.svg"
                primaryAction={{
                  content: 'Auto',
                  onAction: () => {
                    setInstallationType('auto')
                    setInstalling(true)
                    installSite({ installationType: 'auto' })
                  },
                }}
              >
                <p>
                  We can install our app automatically! Just click a button to continue the
                  installation. This installation method uses standard Shopify API used by almost
                  every Shopify app. The JavaScript script that records your viewers will be
                  automatically removed on app uninstall. We will not leave any mess in your code
                  and assets!
                </p>
              </CalloutCard>
              <div />
              <CalloutCard
                title="Manual installation for Online 2.0 themes"
                illustration="https://raw.githubusercontent.com/icons8/flat-color-icons/master/svg/businessman.svg"
                primaryAction={{
                  content: 'Manual',
                  disabled: installationType !== 'manual',
                  onAction: () => {
                    setInstalling(true)
                    installSite({ installationType: 'manual' })
                  },
                }}
                secondaryAction={{
                  content: 'Open manual activation page',
                  url: `${currentUser.siteBaseUrl}/admin/themes/current/editor?context=apps&activateAppId=593554aa-bd4a-4b47-a308-a55d2b35340f/app-block`,
                  external: true,
                  onAction: () => {
                    setInstallationType('manual')
                  },
                }}
              >
                <p>
                  Pick this installation method if you are using a new Shopify Theme that supports
                  Online 2.0. In order to use this method you have to click the activation link.
                  Next, confirm the script is activated by pressing the button. Please be aware that
                  the script has to be enabled before clicking the &apos;Manual&apos; button. If
                  this is not clear to you please go with an automatic installation.
                </p>
              </CalloutCard>
            </CardsWrapper>
          </>
        )}
        <ContactModal
          activeModal={supportModalActive}
          updateActiveModal={updateSupportModalActive}
          sendEmail={sendEmail}
          errors={errors}
          message={message}
        />
      </Layout>
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    installStore: new InstallStore(),
  }),
})(InstallPage)
