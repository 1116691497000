import React, { useState } from 'react'
import { TopBar } from '@shopify/polaris'
import {
  LogOutMinor,
  EmailMajor,
  SettingsMajor,
  PriceLookupMinor,
  AnalyticsMajor,
  DigitalMediaReceiverMajor,
} from '@shopify/polaris-icons'
import { useStore } from 'utils/store'
import { observer } from 'mobx-react-lite'
import Notifications from './Notifications'
import { UserMenuBox } from './styles'

export default observer(() => {
  const {
    appStore: { currentUser, logout },
  } = useStore()

  const [active, setActive] = useState(false)

  return (
    <UserMenuBox>
      {false /* temp hide */ && <Notifications />}
      <TopBar.UserMenu
        name={currentUser.name}
        initials={currentUser.name[0]}
        actions={[
          {
            items: [
              { content: 'Replays', icon: PriceLookupMinor, url: '/' },
              { content: 'Live', icon: DigitalMediaReceiverMajor, url: '/live/details' },
              { content: 'Analytics', icon: AnalyticsMajor, url: '/analytics' },
              { content: 'Settings', icon: SettingsMajor, url: '/settings' },
              { content: 'Contact', icon: EmailMajor, url: '/contact' },
              { content: 'Logout', icon: LogOutMinor, onAction: () => logout() },
            ],
          },
        ]}
        open={active}
        onToggle={() => setActive(!active)}
      />
    </UserMenuBox>
  )
})
