import React from 'react'
import Icon from 'components/Icon'
import { getBrowserIcon } from 'react-browser-icons'
import { browserIcon } from 'utils/global-icon-names'
import { IconWrapper } from './styles'

const BrowserIcon = ({ name, width = '2rem', height = '2rem' }) => {
  const icon = getBrowserIcon({
    browser: name || 'unknow',
    className: 'browserIcon',
    style: { width, height },
  })

  if (!icon) {
    return (
      <IconWrapper size={height}>
        <Icon name={browserIcon} />
      </IconWrapper>
    )
  }

  return icon
}

export default BrowserIcon
