import React, { useEffect } from 'react'
import { Page } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import buildRoute from 'utils/build-route'
import { useStore } from 'utils/store'
import PageSpinner from 'components/PageSpinner'
import DelationConfirmationModal from 'components/DelationConfirmationModal'
import DetailsReplayStore from './item-store'
import { DetailsContainer } from './styles'
import Player from './Player'
import SidePanel from './SidePanel'

const PublicDetailsReplayPage = observer(() => {
  const {
    detailsReplayStore: {
      fetchPublic,
      loading,
      item: replay,
      destroyModalActive,
      updateDestroyModalActive,
      destroy,
      destroyPlayer,
    },
  } = useStore()

  const { replayId } = useParams()

  useEffect(() => {
    fetchPublic(replayId)

    return () => {
      destroyPlayer()
    }
  }, [])

  if (loading) return <PageSpinner />

  if (replay.overlimit) return window.location.replace(process.env.LANDING_PAGE)

  return (
    <Page title="Replay" fullWidth>
      <DetailsContainer>
        <Player actionsActive={false} />
        <SidePanel tagsActive={false} notesActive={false} />
      </DetailsContainer>
      <DelationConfirmationModal
        modalActive={destroyModalActive}
        updateModalActive={updateDestroyModalActive}
        deleteAction={destroy}
        resourceName="replay"
      />
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    detailsReplayStore: new DetailsReplayStore(),
  }),
})(PublicDetailsReplayPage)
