import React from 'react'
import { Avatar, ResourceList } from '@shopify/polaris'
import { ItemBadge, ItemContent, ItemHeading, ItemSubTitle, ItemTitle } from './styles'

const UsersItem = ({ id, name, email, role }) => (
  <ResourceList.Item
    id={id}
    media={<Avatar initials={name[0]} size="medium" name={name} />}
    url={`users/${id}/details`}
  >
    <ItemContent>
      <ItemHeading>
        <ItemTitle>{name}</ItemTitle>
        <ItemSubTitle>{email}</ItemSubTitle>
      </ItemHeading>
      <ItemBadge name={role} />
    </ItemContent>
  </ResourceList.Item>
)

export default UsersItem
