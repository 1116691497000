import React from 'react'
import { observer } from 'mobx-react-lite'
import { Card, Stack, TextField } from '@shopify/polaris'
import StyledButton from 'components/StyledButton'
import { sendEmailIcon } from 'utils/global-icon-names'
import { Form, Formik } from 'formik'

const SendMessageForm = observer(({ sendMessage, chatLogDisabled }) => (
  <Card.Section>
    <Formik
      initialValues={{
        content: '',
        title: '',
      }}
      enableReinitialize
      onSubmit={(v, actions) =>
        sendMessage(v).then(() => actions.resetForm({ values: { content: '' } }))
      }
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Stack spacing="tight" vertical wrap={false}>
            <TextField
              disabled={chatLogDisabled}
              label={chatLogDisabled ? '' : 'Send message to customer.'}
              onChange={(v) => setFieldValue('content', v)}
              value={values.content}
            />
            <StyledButton
              submit
              primary
              disabled={chatLogDisabled}
              loading={isSubmitting}
              iconName={sendEmailIcon}
              actionName="Send"
            />
          </Stack>
        </Form>
      )}
    </Formik>
  </Card.Section>
))

export default SendMessageForm
