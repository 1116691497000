import React from 'react'
import { DropZone, InlineError } from '@shopify/polaris'
import { ErrorField } from 'utils/global-styles'
import { Image, Container, Label, TextButton } from './styles'

const FileUploader = ({ file, label, onDrop, error, image = true }) => {
  const fileUpload = !file && <DropZone.FileUpload />
  const uploadedFile = file && <Image src={file.url || window.URL.createObjectURL(file)} />

  return (
    <>
      <Container>
        <Label>{label}</Label>
        {file && (
          <TextButton
            role="button"
            tabIndex={0}
            onClick={() => onDrop(null)}
            onKeyDown={() => onDrop(null)}
          >
            Remove
          </TextButton>
        )}
      </Container>
      <DropZone
        accept={image ? 'image/*' : null}
        allowMultiple={false}
        onDrop={(files) => onDrop(files[0])}
        variableHeight
      >
        {uploadedFile}
        {fileUpload}
      </DropZone>
      <ErrorField>
        <InlineError message={error} />
      </ErrorField>
    </>
  )
}

export default FileUploader
