import React, { useCallback } from 'react'
import lodash from 'lodash'
import { Filters as PolarisFilters } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'

const Filters = observer((props) => {
  const { queryValue, onQueryChange, onQueryClear, getItems, ...rest } = props

  const getItemsDebounced = useCallback(
    lodash.debounce(() => {
      getItems()
    }, 500),
    [],
  )

  return (
    <PolarisFilters
      queryValue={queryValue}
      onQueryChange={(value) => {
        onQueryChange(value)
        getItemsDebounced()
      }}
      onQueryClear={() => {
        onQueryClear()
      }}
      {...rest}
    />
  )
})

export default Filters
