import { makeAutoObservable } from 'mobx'
import agent from 'api/agent'
import history from 'utils/history'

class Store {
  loading = true
  installationError = false
  supportModalActive = false
  message = ''
  errors = {}

  constructor() {
    makeAutoObservable(this)
  }

  installSite = (body) => {
    this.loading = true
    this.installationError = false
    agent.Site.install(body).then(this.installSiteSuccess, this.installSiteError)
  }

  installSiteSuccess = () => {
    this.reloadCurrentUser().then(() => {
      history.push('/welcome')
      this.showNotification('App installed.')
    })
  }

  installSiteError = () => {
    this.installationError = true
    this.loading = false
  }

  updateSupportModalActive = (state) => {
    this.supportModalActive = state
  }

  sendEmail = (attributes) =>
    agent.Contact.create(attributes).then(this.sendEmailSuccess, this.sendEmailError)

  sendEmailSuccess = () => {
    this.message = ''
    this.errors = {}
    this.updateSupportModalActive(false)
    this.showNotification('Message sent.')
  }

  sendEmailError = (error) => {
    this.handleError(error)
  }
}

export default Store
