import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'utils/store'
import buildRoute from 'utils/build-route'
import { Card, Layout, Page } from '@shopify/polaris'
import CreateUserStore from './item-store'
import UserForm from './Form'

const CreatePage = observer(() => {
  const {
    userStore: { errors, create },
  } = useStore()

  return (
    <Page title="Create user" breadcrumbs={[{ content: 'Users', url: '/settings/users' }]}>
      <Layout>
        <Layout.AnnotatedSection
          title="User creation"
          description="Create a collaborator account. Link to login page is at the bottom of users list."
        >
          <Card sectioned>
            <UserForm errors={errors} onSubmit={create} />
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  )
})

export default buildRoute({
  stores: () => ({
    userStore: new CreateUserStore(),
  }),
})(CreatePage)
