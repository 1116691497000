export const selectPeriodOptions = [
  { label: 'Last 7 days', value: 'last7days' },
  { label: 'Last 30 days', value: 'last30days' },
  { label: 'Last 6 months', value: 'last6months' },
  { label: 'Custom', value: 'custom' },
]

export const namesOfAnalyzedDataTypes = [
  'Recordings',
  'Added to cart',
  'Completed orders',
  'Errors',
]

export const analyzedDataTypes = [
  { name: 'Recordings', field: null },
  {
    name: 'Added to cart',
    field: 'cart',
  },
  {
    name: 'Completed orders',
    field: 'checkout',
  },
  {
    name: 'Errors',
    field: 'errors',
  },
]

export const lineChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
    },
  },
}
