import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Card, Tooltip } from '@shopify/polaris'
import { useStore } from 'utils/store'
import { convertSecondsToHHMMSS } from 'utils/global-functions'
import { DetailsContainer, ActionWrapper, ActionHref, ActionTime } from './styles'

const Actions = observer(({ replay: { actions }, replayer, live = false }) => {
  const {
    appStore: { currentUser },
  } = useStore()

  return (
    <>
      <DetailsContainer>
        {actions.map((action) => (
          <ActionWrapper key={action.timestamp}>
            <Tooltip content={action.href || action.tag}>
              <ActionHref>
                {action.href ? action.href.replace(currentUser.siteBaseUrl, '') : action.tag}
              </ActionHref>
            </Tooltip>
            <ActionTime>
              {live ? (
                convertSecondsToHHMMSS(action.timestamp / 1000)
              ) : (
                <Button monochrome onClick={() => replayer.goto(action.timestamp)} plain>
                  {convertSecondsToHHMMSS(action.timestamp / 1000)}
                </Button>
              )}
            </ActionTime>
          </ActionWrapper>
        ))}
      </DetailsContainer>
      <Card.Section />
    </>
  )
})

export default Actions
