import React from 'react'
import { Card, FormLayout, Layout, Select, TextField } from '@shopify/polaris'
import { Form, Formik } from 'formik'
import CreatableOptionsInput from 'components/CreatableOptionsInput'
import StyledButton from 'components/StyledButton'
import { saveIcon } from 'utils/global-icon-names'
import { useStore } from 'utils/store'
import { activatedDeactivatedOptions } from '../ReplaysSettingsPage/config'

const EmailNotificationsConfig = () => {
  const {
    appStore: {
      currentUser: {
        site: { emailNotificationsTriggers, emailsList, emailNotificationsEnabled },
      },
    },
    notifcationSettingsPageStore: { updateEmailNotificationsTriggers },
  } = useStore()

  if (!emailNotificationsEnabled) return null

  return (
    <Layout.AnnotatedSection
      id="emailNotificationConfig"
      title="Email notifications settings"
      description="Get notification about crucial live sessions. Determine activation triggers on which notification will be sent. Also, don't forget to provide a list of email addresses."
    >
      <Card sectioned>
        <Formik
          enableReinitialize
          initialValues={{
            sessionLengthTrigger: {
              value: emailNotificationsTriggers?.sessionLengthTrigger?.value ?? '600',
              enabled: emailNotificationsTriggers?.sessionLengthTrigger?.enabled ?? false,
            },
            cartValueTrigger: {
              value: emailNotificationsTriggers?.cartValueTrigger?.value ?? '100',
              enabled: emailNotificationsTriggers?.cartValueTrigger?.enabled ?? false,
            },
            emailsList: emailsList ?? [],
          }}
          onSubmit={(v) => updateEmailNotificationsTriggers(v)}
        >
          {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <FormLayout>
                <CreatableOptionsInput
                  placeholder="Emails"
                  value={values.emailsList}
                  helpText="Provide a list of email addresses."
                  onChange={(v) =>
                    setFieldValue(
                      'emailsList',
                      v.map((element) => element.value),
                    )
                  }
                />
                <TextField
                  type="number"
                  autoComplete="off"
                  label="Session length trigger"
                  helpText="Determine session length in seconds."
                  value={String(values.sessionLengthTrigger.value)}
                  onChange={(v) => setFieldValue('sessionLengthTrigger.value', v)}
                  disabled={!values.sessionLengthTrigger.enabled}
                  connectedRight={
                    <Select
                      labelHidden
                      label="Session length notification: "
                      options={activatedDeactivatedOptions}
                      onChange={() =>
                        setFieldValue(
                          'sessionLengthTrigger.enabled',
                          !values.sessionLengthTrigger.enabled,
                        )
                      }
                      value={values.sessionLengthTrigger.enabled}
                    />
                  }
                />
                <TextField
                  type="number"
                  autoComplete="off"
                  label="Cart value trigger"
                  helpText="Determine cart value."
                  value={String(values.cartValueTrigger.value)}
                  onChange={(v) => setFieldValue('cartValueTrigger.value', v)}
                  disabled={!values.cartValueTrigger.enabled}
                  connectedRight={
                    <Select
                      labelHidden
                      label="Cart value notification: "
                      options={activatedDeactivatedOptions}
                      onChange={() =>
                        setFieldValue('cartValueTrigger.enabled', !values.cartValueTrigger.enabled)
                      }
                      value={values.cartValueTrigger.enabled}
                    />
                  }
                />
                <StyledButton
                  primary
                  submit
                  loading={isSubmitting}
                  actionName="Save"
                  iconName={saveIcon}
                />
              </FormLayout>
            </Form>
          )}
        </Formik>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default EmailNotificationsConfig
