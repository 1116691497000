import React from 'react'
import { Button, FooterHelp } from '@shopify/polaris'
import { useStore } from 'utils/store'
import { observer } from 'mobx-react-lite'

const Footer = observer(() => {
  const {
    appStore: { currentUser },
    usersStore: { copyToClipboard },
  } = useStore()

  return (
    <FooterHelp>
      <Button
        onClick={() =>
          copyToClipboard(`${process.env.FRONTEND_URL}/admin/login/${currentUser.site.slug}`)
        }
        plain
        monochrome
        removeUnderline
      >
        Copy a login link for external users.
      </Button>
    </FooterHelp>
  )
})

export default Footer
