import React from 'react'
import { Card } from '@shopify/polaris'
import { observer } from 'mobx-react-lite'
import DelationConfirmationModal from 'components/DelationConfirmationModal'
import NotesList from './NotesList'
import SendNoteForm from './SendNoteForm'
import { DetailsBox, NotesListContainer } from './styles'

const Notes = observer(
  ({
    destroyNoteModalActive,
    setDestroyNoteModalActive,
    deleteNote,
    replay,
    replayer,
    setEditNoteState,
    editNoteState,
    deleteNoteAction,
    editNoteText,
    setEditNoteText,
    updateNote,
    targetEditNote,
    notesActionLoading,
    currentReplayerTimestamp,
    createNoteText,
    setCreateNoteText,
    createNote,
    noteAddLoading,
    notesActive,
  }) => (
    <DetailsBox overflow="initial">
      <>
        <SendNoteForm
          currentReplayerTimestamp={currentReplayerTimestamp}
          createNoteText={createNoteText}
          setCreateNoteText={setCreateNoteText}
          createNote={createNote}
          noteAddLoading={noteAddLoading}
          notesActive={notesActive}
        />
        <NotesListContainer>
          <NotesList
            replay={replay}
            replayer={replayer}
            setEditNoteState={setEditNoteState}
            editNoteState={editNoteState}
            deleteNoteAction={deleteNoteAction}
            editNoteText={editNoteText}
            setEditNoteText={setEditNoteText}
            updateNote={updateNote}
            targetEditNote={targetEditNote}
            notesActionLoading={notesActionLoading}
            notesActive={notesActive}
          />
        </NotesListContainer>
        <Card.Section />
      </>
      <DelationConfirmationModal
        master
        modalActive={destroyNoteModalActive}
        updateModalActive={setDestroyNoteModalActive}
        deleteAction={deleteNote}
        resourceName="note"
      />
    </DetailsBox>
  ),
)

export default Notes
