import React from 'react'
import { observer } from 'mobx-react-lite'
import { Line } from 'react-chartjs-2'
import { Card } from '@shopify/polaris'
import { useStore } from 'utils/store'
import { LineChartWrapper, StyledCard } from './styles'
import PrimaryChartCardHeader from './PrimaryChartCardHeader'
import { lineChartOptions } from './config'

const Charts = observer(() => {
  const {
    analyticsStore: { state },
  } = useStore()

  return (
    <StyledCard>
      <PrimaryChartCardHeader />
      <Card.Section>
        <LineChartWrapper>
          <Line options={lineChartOptions} data={state} />
        </LineChartWrapper>
      </Card.Section>
    </StyledCard>
  )
})

export default Charts
